import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '@icp/settings';
import { contextInitialState } from '../initialState';

export const selectUserPermissionMap = (state) => state.context.userPermissionMap;
export const selectIsAdminUser = (state) => state.context.userProfile?.isAdmin === 1;
export const selectSchemaMode = (state) => state.context.schemaMode;
export const selectContext = (state) => state.context;
export const selectEnableAutoLock = (state) => state.context.enableAutoLock;
export const selectFormEntity = (state) => state.context.formEntity;
export const selectFormEntityCache = (state) => state.context.formEntityCache;

export const getFormEntityByToken = createAsyncThunk(
  'context/getFormEntityByToken',
  async (payload, { getState }) => {
    const { pbcToken, formEntityToken } = payload;
    if (!pbcToken || !formEntityToken) return null;

    const cache = selectFormEntityCache(getState());
    if (cache?.[pbcToken]?.[formEntityToken]) {
      return cache[pbcToken][formEntityToken];
    }

    const formEntity = await restApi
      .getStatic(`/form/api/v2/form-entity/${pbcToken}/${formEntityToken}`, {
        params: { needLayouts: false },
      })
      .catch(() => null);

    return formEntity;
  },
);

const slice = createSlice({
  name: 'context',
  initialState: { ...contextInitialState },
  reducers: {
    setContext: (state, action) => {
      if (!action.payload) {
        return;
      }
      for (const key of Object.keys(action.payload)) {
        state[key] = action.payload[key];
      }
    },
    setContextDataSource: (state, action) => {
      const { alias, data } = action.payload;
      state.dataSource[alias] = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFormEntityByToken.fulfilled, (state, action) => {
      const { pbcToken, formEntityToken } = action.meta.arg;
      const formEntity = action.payload;

      if (!state.formEntityCache) {
        state.formEntityCache = {};
      }
      if (!state.formEntityCache[pbcToken]) {
        state.formEntityCache[pbcToken] = {};
      }
      state.formEntityCache[pbcToken][formEntityToken] = formEntity;
    });
  },
});

export const { setContext, setContextDataSource } = slice.actions;

export default slice.reducer;
