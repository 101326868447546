import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { composeEventAsync } from '@icp/utils';
import InputClearButton from './InputClearButton';

const NoI18nInput = forwardRef(function NoI18nInput(props, ref) {
  const {
    value: valueProp,
    onChange,
    onBlur,
    readonly,
    allowClear,
    onClear,
    ...other
    //
  } = props;

  const inputRef = useRef();
  const [value, setValue] = useState(valueProp);

  useImperativeHandle(
    ref,
    () => {
      if (inputRef.current) {
        inputRef.current[Symbol.for('icp_setValue')] = setValue;
      }
      return inputRef.current;
    },
    [],
  );

  return (
    <TextField
      {...other}
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      onBlur={composeEventAsync(() => onChange(value), onBlur)}
      InputProps={{
        readOnly: readonly,
        endAdornment:
          !readonly && value && allowClear ? <InputClearButton onClear={onClear} /> : null,
      }}
      ref={inputRef}
    />
  );
});

NoI18nInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readonly: PropTypes.bool,
  allowClear: PropTypes.bool,
  onClear: PropTypes.func,
};

export default NoI18nInput;
