import { formatDate, formatNumber, getTFunc } from '@icp/i18n';
import dayjs from 'dayjs';
import { AG_FILTER_TYPES_UNARY } from '../../AgTable/constants';
import { getSetFilterOptions } from './utils';

function getUnaryTypeText(model) {
  const { type } = model;
  const t = getTFunc('icp-vendor-aggrid');
  return t(type);
}

function getTextTypeText(model) {
  const { type, filter } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (!filter) {
    return '';
  }
  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{filter}</span>
    </>
  );
}

function getNumberTypeText(model) {
  const { type, filter, filterTo } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (type === 'inRange') {
    if (!filter || !filterTo) {
      return '';
    }
    return (
      <span className="icp-filter-value">
        {formatNumber(filter)} → {formatNumber(filterTo)}
      </span>
    );
  }

  if (!filter) {
    return '';
  }

  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{formatNumber(filter)}</span>
    </>
  );
}

function getDateTypeText(model) {
  const { type, dateFrom, dateTo } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (type === 'inRange') {
    if (!dateFrom || !dateTo) {
      return '';
    }
    return (
      <span className="icp-filter-value">
        {formatDate(dayjs(dateFrom).toDate())} → {formatDate(dayjs(dateTo).toDate())}
      </span>
    );
  }

  if (!dateFrom) {
    return '';
  }

  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{formatDate(dayjs(dateFrom).toDate())}</span>
    </>
  );
}

function getAsyncSetTypeText(model, context, colDef) {
  const { values } = model;

  if (!(Array.isArray(values) && values.length)) {
    return '';
  }

  const result = getSetFilterOptions(context, colDef);

  const format = (options) => {
    return (
      <div className="icp-filter-value">
        {values
          .map((v) => (options || []).find((op) => op.value === v.value)?.label || v.value)
          .filter(Boolean)
          .join(', ')}
      </div>
    );
  };

  if (result instanceof Promise) {
    return result.then(format);
  }

  return format(result);
}

function getSyncSetTypeText(model) {
  const { values } = model;
  if (!(Array.isArray(values) && values.length)) {
    return '';
  }
  return values.map((item) => item.label).join(', ');
}

export default function getFilterValueText(context, model, colDef) {
  if (!colDef || !model || typeof model !== 'object') {
    return '';
  }

  if (AG_FILTER_TYPES_UNARY.includes(model.type)) {
    return getUnaryTypeText(model);
  }

  if (model.filterType === 'text') {
    return getTextTypeText(model);
  }

  if (model.filterType === 'number') {
    return getNumberTypeText(model);
  }

  if (model.filterType === 'date') {
    return getDateTypeText(model);
  }

  // 结果可能是异步, 只针对 ENUM_COLUMN 配置的情况，ENUM 列的选项以前基本是固定写死在了 columnDefs 里的。
  // 新完善 set filter 过后其他类型的列通过 Toolbar 操作保存的 set filterType 一定是 { value, label } 格式
  if (
    model.filterType === 'set' &&
    (colDef.type === 'ENUM_COLUMN' || colDef.type === 'STATUS_COLUMN')
  ) {
    return getAsyncSetTypeText(model, context, colDef);
  }

  if (model.filterType === 'set') {
    return getSyncSetTypeText(model);
  }

  return '';
}
