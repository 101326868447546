import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { restApi, message as messageApi } from '@icp/settings';
import { useFormApi } from '../FormRenderCtx';

export default function useExposedApis(formApiProp) {
  const formApi = useFormApi();

  const { t, i18n } = useTranslation();
  const i18nApi = useMemo(() => {
    return { language: i18n.language, t };
  }, [i18n.language, t]);

  const navigate = useNavigate();
  const routerApi = useMemo(() => {
    return { navigate };
  }, [navigate]);

  const ref = useRef({});

  return Object.assign(ref.current, {
    // 在 FormRenderer 里调用 useFormScript 调用 useExposedApis 时，useFormApi 并不在
    // FormRenderCtxProvider 的上下里，所有需要参数传入 formApi
    formApi: formApiProp || formApi,
    i18nApi,
    restApi,
    messageApi,
    routerApi,
  });
}
