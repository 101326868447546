import dayjs from 'dayjs';
import { convertDateTimeToTimeZone } from './datetimeUtils';

export default function toDateValue(date, options) {
  if (!date) {
    return date;
  }

  const { showTime, noTimeZone, timeZone } = options || {};

  if (!showTime) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  if (noTimeZone) {
    return dayjs(date).toISOString(true).substring(0, 19);
  }

  if (timeZone) {
    return convertDateTimeToTimeZone(date, timeZone).toISOString();
  }

  return dayjs(date).toISOString();
}
