import { useMemo } from 'react';
import {
  selectFieldValue,
  setCurrentFieldId,
  setFieldTouched,
  setFieldValidatorDelegate,
  setFieldValue,
} from '@icp/form-renderer-core';
import { useDispatch, useSelector } from '../store';

export default function useField({ id }) {
  const dispatch = useDispatch();
  const value = useSelector(selectFieldValue(id));

  const bag = useMemo(() => {
    const setTouched = () => {
      dispatch(setFieldTouched({ keyPath: id, touched: true }));
    };

    const setValue = (v, language, fromUI = true) => {
      return dispatch(setFieldValue({ keyPath: id, value: v, language, fromUI }));
    };

    const setValidatorDelegate = (validator) => {
      dispatch(setFieldValidatorDelegate({ keyPath: id, validator }));
    };

    const setFocus = () => {
      dispatch(setCurrentFieldId(id));
    };

    return { setValue, setTouched, setValidatorDelegate, setFocus };
  }, [dispatch, id]);

  return { ...bag, value };
}
