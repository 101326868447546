export default function debounce(func, time = 200) {
  let timeout;

  function debounced(...args) {
    const callback = () => {
      func.apply(this, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(callback, time);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

export function debounceByExecution(func) {
  let executing = false;

  function debounced(...args) {
    if (executing) return;
    executing = true;
    Promise.resolve()
      .then(() => func.apply(this, args))
      .finally(() => {
        executing = false;
      });
  }

  return debounced;
}
