import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useEventCallback } from '@icp/hooks';
import FormService from './FormService';

const AppContext = createContext(null);

export function AppContextProvider({ children, contextFromAppProp, pbcList }) {
  const [redirecting, setRedirecting] = useState(false);
  const [pbc, setPbc] = useState(null);
  const [pageTitle, setPageTitle] = useState('');
  const [fullWidth, setFullWidth] = useState(undefined);

  const setPbcToken = useEventCallback((token) => {
    setPbc(pbcList?.find((item) => item.token === token));
  });

  const formService = useMemo(() => new FormService(), []);

  const value = useMemo(() => {
    return {
      contextFromAppProp,
      pbcList,
      pbc,
      setPbcToken,
      pageTitle,
      setPageTitle,
      fullWidth,
      setFullWidth,
      redirecting,
      setRedirecting,
      formService,
    };
  }, [
    contextFromAppProp,
    pbcList,
    pbc,
    setPbcToken,
    pageTitle,
    fullWidth,
    redirecting,
    formService,
  ]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

AppContextProvider.propTypes = {
  children: PropTypes.node,
  contextFromAppProp: PropTypes.shape({}),
  pbcList: PropTypes.arrayOf(PropTypes.shape({})),
};

export function useAppContext() {
  return useContext(AppContext);
}
