import Icon from '../../Icon';
import { AG_FILTER_TYPES_UNARY } from '../../AgTable';

export function getFilterType(colDef) {
  const { type, filter } = colDef;

  // colDef 里有指定 filter 类型
  if (filter === 'agDateColumnFilter') {
    return 'date';
  }
  if (filter === 'agNumberColumnFilter') {
    return 'number';
  }
  if (filter === 'agSetColumnFilter') {
    return 'set';
  }

  // colDef 里没有指定，自动识别下
  if (['DATE_COLUMN'].includes(type)) {
    return 'date';
  }

  if (['NUMBER_COLUMN', 'PROGRESS_COLUMN'].includes(type)) {
    return 'number';
  }

  if (['ENUM_COLUMN'].includes(type)) {
    return 'set';
  }

  return 'text';
}

export function makeNewFilterModel(colDef) {
  const filterType = getFilterType(colDef);

  return {
    colId: colDef.colId,
    filterType,
    type:
      (filterType === 'text' && 'contains') ||
      (filterType === 'date' && 'equals') ||
      (filterType === 'number' && 'equals') ||
      undefined,
  };
}

export function getFilterTypeIcon(filterType) {
  if (filterType === 'text') {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 22.7441 14.5605"
        fill="currentColor"
      >
        <path d="M17.2363 14.5508C18.7402 14.5508 20.1465 13.7305 20.752 12.4609L20.791 12.4609L20.791 13.75C20.791 14.2383 21.123 14.5605 21.582 14.5605C22.0508 14.5605 22.3828 14.2383 22.3828 13.7109L22.3828 7.19727C22.3828 5.06836 20.8203 3.69141 18.3398 3.69141C16.5039 3.69141 14.9707 4.50195 14.4141 5.76172C14.3066 6.00586 14.2285 6.24023 14.2285 6.44531C14.2285 6.875 14.5508 7.1582 14.9707 7.1582C15.2734 7.1582 15.5078 7.04102 15.6543 6.76758C16.2012 5.63477 17.0312 5.10742 18.3008 5.10742C19.8242 5.10742 20.7324 5.95703 20.7324 7.32422L20.7324 8.16406L17.5781 8.33984C15.1074 8.47656 13.7305 9.61914 13.7305 11.4355C13.7305 13.3008 15.166 14.5508 17.2363 14.5508ZM17.6367 13.1934C16.3184 13.1934 15.4199 12.4707 15.4199 11.3965C15.4199 10.3613 16.25 9.66797 17.7832 9.57031L20.7324 9.38477L20.7324 10.4199C20.7324 11.9727 19.3555 13.1934 17.6367 13.1934Z" />
        <path d="M0.820312 14.4824C1.33789 14.4824 1.5918 14.2871 1.77734 13.7402L3.03711 10.293L8.79883 10.293L10.0586 13.7402C10.2441 14.2871 10.498 14.4824 11.0059 14.4824C11.5234 14.4824 11.8555 14.1699 11.8555 13.6816C11.8555 13.5156 11.8262 13.3594 11.748 13.1543L7.16797 0.957031C6.94336 0.361328 6.54297 0.0585938 5.91797 0.0585938C5.3125 0.0585938 4.90234 0.351562 4.6875 0.947266L0.107422 13.1641C0.0292969 13.3691 0 13.5254 0 13.6914C0 14.1797 0.3125 14.4824 0.820312 14.4824ZM3.51562 8.80859L5.88867 2.23633L5.9375 2.23633L8.31055 8.80859Z" />
      </svg>
    );
  }
  if (filterType === 'number') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M11.993 2.853a.75.75 0 0 0-1.485-.212l-.337 2.36H6.686l.306-2.145a.75.75 0 1 0-1.484-.212L5.17 5H3.75a.75.75 0 0 0-.001 1.5h1.207l-.428 3H2.75a.75.75 0 1 0 0 1.5h1.565l-.306 2.144a.75.75 0 1 0 1.485.212L5.83 11h3.485l-.306 2.144a.75.75 0 1 0 1.485.212L10.831 11h1.419a.75.75 0 0 0 0-1.5h-1.206l.428-3l1.778.001a.75.75 0 0 0 0-1.5h-1.564zM9.957 6.501L9.529 9.5H6.044l.428-3z"
        />
      </svg>
    );
  }
  if (filterType === 'date') {
    return <Icon name="oct:clock" size={14} style={{ padding: 1 }} />;
  }
  if (filterType === 'set') {
    return <Icon name="ant:down-circle-outlined" size={14} style={{ padding: 1 }} />;
  }
  return null;
}

function getSetFilterFunctionValues(context, colDef) {
  const { values: valuesConfig } = colDef.filterParams || {};

  let syncResult;
  let promise;
  try {
    promise = new Promise((resolve) => {
      valuesConfig({
        context,
        colDef,
        success: (result) => {
          syncResult = result;
          resolve(result);
        },
      });
    });
  } catch (err) {
    console.error(err);
    // 错误也 resolve 个空数组
    syncResult = [];
  }

  // 直行到这里就有结果说明是在 valuesConfig 里没有异步处理直接调用了 params.success 的
  if (syncResult) {
    return syncResult;
  }

  return promise;
}

// 这个函数兼容 ag-grid 官方的 filterParams.values 的配置
export function getSetFilterOptions(context, colDef) {
  const { values: valuesConfig } = colDef.filterParams || {};

  const format = (optionsValues) => {
    const { keyCreator, valueFormatter } = colDef.filterParams || {};

    if (!Array.isArray(optionsValues)) {
      return [];
    }

    return optionsValues.map((value) => {
      if (typeof valueFormatter === 'function') {
        return {
          value: keyCreator ? keyCreator({ value }) : value,
          label: valueFormatter({
            context,
            colDef,
            value,
          }),
        };
      }
      return { value, label: value };
    });
  };

  const result =
    typeof valuesConfig === 'function' ? getSetFilterFunctionValues(context, colDef) : valuesConfig;

  if (result instanceof Promise) {
    return result.then(format);
  }

  return format(result);
}

export function filterHasValue(model) {
  if (!model || typeof model !== 'object') {
    return false;
  }

  const { filter, filterTo, dateFrom, dateTo, values } = model;

  if (AG_FILTER_TYPES_UNARY.includes(model.type)) {
    return true;
  }

  if (model.filterType === 'text') {
    return filter;
  }

  if (model.filterType === 'number') {
    return filter || filterTo;
  }

  if (model.filterType === 'date') {
    return dateFrom || dateTo;
  }

  if (model.filterType === 'set') {
    return Array.isArray(values) && values.length;
  }

  return false;
}

// TODO, 和 removeInvalidDataFilters 重复了
export function filterInvalidFilterModel(filterModel) {
  return filterModel.filter((model) => {
    return filterHasValue(model);
  });
}
