export { default as escapeHtml } from 'escape-html';

export function escapeFilename(text) {
  return text?.replace(/[~ <>:"\\/|?*]/g, '_');
}

export function escapeExcelFormulaInjection(value) {
  if (!value) return value;
  // TODO, 暂时修一下 number 的显示 -123 被显示成了 '-123。目前普通文本 -abc 也会被显示为 '-abc 看起来也不太合理。
  if (typeof value === 'number' || !Number.isNaN(Number(value))) return value;
  if (/^[=@\-+]/.test(value)) return `'${value}`;
  return value;
}

/* mermaid syntax:
 * @example A["A double quote:#quot;"] --> B["A dec char:#9829;"]
 * @explain Numbers given are base 10, so # can be encoded as #35;. It is also supported to use HTML character names.
 */
export function escapeMermaidQuotedText(text) {
  return text?.replace(/"/g, '#quot;').replace(/%/g, '#37;').replace(/\\/g, '#92;');
}

export function escapeMarkdownTableCellText(text) {
  return text?.replace(/\|/g, '|');
}
