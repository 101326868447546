import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ensureDisplayText, isValidRegex } from '@icp/utils';
import { formatDate, formatDateTime, formatNumber, formatTime } from '@icp/i18n';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import { useClassName, useDisplayValue, useVariablePattern } from '../hooks';
import { ConditionalPropertyPropType } from '../propTypes';
import { LinkWrapper } from '../wrappers';
import { withFieldWrapper } from '../fieldWrapper';

const formatterMap = {
  localeDateTime: (formatOptions) => (v) => formatDateTime(dayjs(v).toDate(), formatOptions),
  localeDate: (formatOptions) => (v) => formatDate(dayjs(v).toDate(), formatOptions),
  // TextElement 只使用标准的时间格式解析，自定义格式用TimePickerElement
  localeTime: (formatOptions) => (v) => formatTime(dayjs(v, 'HH:mm:ss').toDate(), formatOptions),
  // value can be date or idCard
  age: () => (v) => {
    if (!v) return v;
    const today = new Date();
    let birthday;

    try {
      // try id card first
      if (v.length === 18 && /^\d{17}/.test(v)) {
        const dateStr = v
          .substring(6, 14)
          .match(/(\d{4})(\d{2})(\d{2})/)
          .slice(1, 4)
          .join('-');
        birthday = new Date(dateStr);
      } else {
        birthday = new Date(v);
      }
    } catch (e) {
      console.error(e);
      return '';
    }

    if (
      today.getMonth() > birthday.getMonth() ||
      (today.getMonth() === birthday.getMonth() && today.getDate() >= birthday.getDate())
    ) {
      return today.getUTCFullYear() - birthday.getUTCFullYear();
    }
    return today.getUTCFullYear() - birthday.getUTCFullYear() - 1;
  },

  regexp: (formatProps) => (v) => {
    if (!isValidRegex(formatProps?.regexp) || !v) return v;
    const reg = new RegExp(formatProps?.regexp, formatProps?.flags);
    return String(v).replace(reg, formatProps?.newSubStr);
  },
  number: (formatOptions) => (v) => formatNumber(v, formatOptions),
};

const doNothingFormatter = (x) => x;

function resolveFormatter(config, currency) {
  if (!config) return doNothingFormatter;

  if (typeof config === 'string') {
    return formatterMap[config]?.() || doNothingFormatter;
  }

  if (typeof config === 'object') {
    const { type, props } = config;
    return formatterMap[type]?.({ ...props, currency }) || doNothingFormatter;
  }

  return doNothingFormatter;
}

const TextElement = forwardRef(function TextElement(props, ref) {
  const {
    children,
    keyPath,
    id,
    valueField,
    className: classNameProp,
    title,
    style = {},
    componentProps = {},
    fieldTitleProps,
    validation,
  } = props;

  const {
    content: contentProp,
    fontSize,
    fontWeight,
    color: colorProp,
    formatter: formatterProp,
    prefix = '',
    suffix = '',
    href,
    hrefSelector,
    hrefIsSiteBased,
    suppressBasePath,
    suppressInheritIncludeDeleted,
    target,
    style: textStyle,
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const value = useDisplayValue(id, valueField);
  const content = useVariablePattern(children || contentProp);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  // TODO, change to conditional property
  const color = typeof colorProp === 'object' ? colorProp[value] : colorProp || style.color;

  const currency = useVariablePattern(formatterProp?.props?.currency, false);
  const formatter = resolveFormatter(formatterProp, currency);
  const valueFormatted = formatter(value ?? content);

  // design 模式的时候直接显示取值的变量名 valueField 或者 id，否则组件在 design 页面会因为没有 currentData 和 formData 而看不见。
  const displayValue =
    isInDesign && (valueField || id || contentProp)
      ? valueField || id || contentProp
      : ensureDisplayText(valueFormatted);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <span
        className={clsx('text-element form-element', className)}
        style={{
          fontSize,
          fontWeight,
          color,
          ...style,
          // TODO, move to below Link and span
          ...textStyle,
        }}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        {displayValue ? prefix : null}
        {href || hrefSelector ? (
          <LinkWrapper
            className={classNameComp}
            componentProps={{
              href,
              hrefSelector,
              hrefIsSiteBased,
              suppressBasePath,
              suppressInheritIncludeDeleted,
              target,
              suppressLinkColor: false,
            }}
          >
            {displayValue}
          </LinkWrapper>
        ) : (
          <span className={classNameComp}>{displayValue}</span>
        )}
        {displayValue ? suffix : null}
      </span>
    </ElementDecorator>
  );
});

TextElement.propTypes = {
  children: PropTypes.string,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 文本内容
     */
    content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 文字大小，css 属性
     */
    fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 文字粗细，css 属性
     */
    fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 文字颜色，css 属性
     */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * 文字显示默认提供的格式
     */
    formatter: PropTypes.oneOfType([
      PropTypes.shape({ type: PropTypes.string, props: PropTypes.shape({}) }),
      PropTypes.oneOf(Object.keys(formatterMap)),
    ]),
    /**
     * 文字的前缀，如果有链接，不会被包含在链接里面
     */
    prefix: PropTypes.string,
    /**
     * 文字的后缀，如果有链接，不会被包含在链接里面
     */
    suffix: PropTypes.string,
    /**
     * 文字的链接地址
     */
    href: ConditionalPropertyPropType(PropTypes.string),
    hrefSelector: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        matched: ConditionalPropertyPropType(PropTypes.bool),
      }),
    ),
    /**
     * 如果值为 `false` 并且此链接位于 pbc 下，href 将自动拼接上 pbc 的 `token` 作为 `basename`，移动端将自动拼接上 `mobile` 的前缀
     * @default false
     */
    hrefIsSiteBased: PropTypes.bool,
    /**
     * 是否直接链接不通过 react router 的 base path
     * @default false
     */
    suppressBasePath: PropTypes.bool,
    /**
     * 是否自动继承当前页面的 `include_deleted` 属性，此属性一般在当前页面 url 的 search params 里
     * @default false
     */
    suppressInheritIncludeDeleted: PropTypes.bool,
    target: PropTypes.string,
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TextElement.displayName = 'Text';

const Wrapper = withFieldWrapper(TextElement);

Wrapper.formatterTypes = Object.keys(formatterMap);
Wrapper.resolveFormatter = resolveFormatter;

export default Wrapper;
