import './cellRenderers.css';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { message, restApi } from '@icp/settings';
import { resolveUrl, selectContext, selectValues } from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import { resolveVariablePattern } from '@icp/utils';
import { isValidElement } from 'react';
import { useConditionalProperty } from '../../../hooks';
import { useStore } from '../../../store';
import {
  ACTION_COLUMN_ACTIONS,
  ACTION_COLUMN_APPROVAL,
  ACTION_COLUMN_CALL_GLOBAL_METHOD,
  ACTION_COLUMN_DELETE,
  ACTION_COLUMN_EDIT,
  ACTION_COLUMN_TREE_DELETE,
  ACTION_COLUMN_VIEW,
} from '../constant';
import { ConditionalPropertyPropType } from '../../../propTypes';
import ButtonElement, { ButtonUI } from '../../ButtonElement';
import SwitchElement from '../../SwitchElement';
import { LinkWrapper } from '../../../wrappers';
import withCurrentData from './withCurrentData';
import { useTableContext } from '../tableContextCtx';

function ActionItemWrapper({ item, rowData, children }) {
  const hidden = useConditionalProperty(item.hidden);
  const type = useConditionalProperty(item.type);
  if (hidden) return null;
  if (rowData?.deleted && type !== ACTION_COLUMN_VIEW) return null;
  return children({ type });
}

function ActionCellRenderer(props) {
  const { node: nodeProp, api, data, displayLikeButton = false, showIcon = false, actions } = props;

  const { t } = useTranslation(['icp-form-renderer', 'icp-common']);
  const params = useParams();
  const store = useStore();
  const tableApi = useTableContext();

  const [modal, contextHolder] = Modal.useModal();

  if (nodeProp.group && !nodeProp.treeNode) {
    return null;
  }

  const handleDeleteClick = (event, deleteUrl, item) => {
    event.stopPropagation();

    const deleteRow = () => {
      restApi.delete(deleteUrl).then(() => {
        tableApi.refresh();
        message.success(t('delete-success', { ns: 'icp-common' }));
      });
    };

    const { title, modalTitle, modalContent } = item;
    const content =
      item.content &&
      resolveVariablePattern({
        pattern: item.content,
        currentData: data,
        formData: selectValues(store.getState()),
        context: selectContext(store.getState()),
        params,
      });

    modal.confirm({
      title: title || modalTitle || t('action.delete-title'),
      content: content || modalContent || t('action.delete-content'),
      onOk: deleteRow,
    });
  };

  const handleTreeDeleteClick = (event, deleteUrl, item) => {
    event.stopPropagation();

    const deleteNodeAndChildren = () => {
      const treeDataKeyPath = String(data.treeDataKeyPath);
      const toDeleteData = [];
      api.forEachNode((node) => {
        if (String(node.data.treeDataKeyPath).startsWith(treeDataKeyPath)) {
          toDeleteData.push(node.data);
        }
      });
      const promises = toDeleteData.map((d) => {
        const [url] = resolveUrl({ url: item.url, store, currentData: d, params });
        return restApi.delete(url);
      });
      Promise.all(promises).then(() => {
        tableApi.refresh();
        message.success(t('delete-success', { ns: 'icp-common' }));
      });
    };

    const { title, content, modalTitle, modalContent } = item;

    modal.confirm({
      title: title || modalTitle || t('action.delete-title'),
      content: content || modalContent || t('action.delete-content'),
      onOk: deleteNodeAndChildren,
    });
  };

  const handleCallGlobalMethod = (event, item) => {
    event.preventDefault();
    window[item.globalMethod](data);
  };

  return (
    <div className="table-action-cell">
      {(actions || []).map((item, index) => (
        <ActionItemWrapper key={`${item.type}${index}`} item={item} rowData={data}>
          {({ type }) => {
            const label = item.value || t(`action.${type}`);
            // TODO, support both /path/{id} and /path/:id
            const [url] = resolveUrl({ url: item.url, store, currentData: data, params });

            if (
              type === ACTION_COLUMN_VIEW ||
              type === ACTION_COLUMN_EDIT ||
              type === ACTION_COLUMN_APPROVAL
            ) {
              if (url) {
                console.warn(`'url' is deprecated, use 'href' instead`);
              }
              return (
                <LinkWrapper
                  componentProps={{
                    // TODO, delete use url. link use href
                    href: url || item.href,
                    hrefSelector: item.hrefSelector,
                    hrefIsSiteBased: item.hrefIsSiteBased,
                    suppressBasePath: item.suppressBasePath,
                    suppressInheritIncludeDeleted: item.suppressInheritIncludeDeleted,
                    suppressLinkColor: false,
                    target: item.target,
                  }}
                >
                  <ButtonUI
                    size="small"
                    type={displayLikeButton ? 'default' : 'link'}
                    icon={
                      showIcon
                        ? (type === ACTION_COLUMN_EDIT && 'oct:pencil') ||
                          (type === ACTION_COLUMN_VIEW && 'oct:eye') ||
                          (type === ACTION_COLUMN_APPROVAL && 'recipe-step-approval')
                        : null
                    }
                  >
                    {label}
                  </ButtonUI>
                </LinkWrapper>
              );
            }

            if (type === ACTION_COLUMN_DELETE) {
              const deleteUrl =
                url ||
                resolveUrl({
                  url: '/form/api/form-entity-data/:id',
                  store,
                  currentData: data,
                  params,
                })[0];
              return (
                <ButtonUI
                  type={displayLikeButton ? 'default' : 'link'}
                  danger={true}
                  size="small"
                  icon={showIcon ? 'oct:trash' : null}
                  // ag-grid 偶尔会发生因为触发了父元素 div 的点击事件导致 modal 无法打开的情况，这里在 capture 阶段去 stopPropagation
                  onClickCapture={(event) => handleDeleteClick(event, deleteUrl, item)}
                >
                  {label}
                </ButtonUI>
              );
            }

            if (type === ACTION_COLUMN_TREE_DELETE) {
              return (
                <ButtonUI
                  type={displayLikeButton ? 'default' : 'link'}
                  danger={true}
                  size="small"
                  icon={showIcon ? 'oct:trash' : null}
                  onClickCapture={(event) => handleTreeDeleteClick(event, url, item)}
                >
                  {label}
                </ButtonUI>
              );
            }

            // TODO，不推荐用这个 type 了，有需求配置 Button 就可以实现
            if (type === ACTION_COLUMN_CALL_GLOBAL_METHOD) {
              return (
                <ButtonUI
                  type={displayLikeButton ? 'default' : 'link'}
                  size="small"
                  onClickCapture={(event) => handleCallGlobalMethod(event, item)}
                >
                  {label}
                </ButtonUI>
              );
            }

            if (item.component === 'Button' || item.type === ButtonElement) {
              return (
                <ButtonElement
                  componentProps={{
                    // ag-grid 偶尔会发生因为触发了父元素 div 的点击事件导致 modal 无法打开的情况，这里在 capture 阶段去 stopPropagation
                    clickUseCapture:
                      item.componentProps?.action?.type === 'open' ||
                      item.componentProps?.action?.type === 'dialog' ||
                      item.componentProps?.action?.type === 'confirm',
                    size: 'small',
                    // code generator 过后 item 会直接是 <ButtonElement></ButtonElement>
                    ...(isValidElement(item) ? item.props.componentProps : item.componentProps),
                  }}
                >
                  {isValidElement(item) ? item.props.children : undefined}
                </ButtonElement>
              );
            }

            if (item.component === 'Switch' || item.type === SwitchElement) {
              return (
                <SwitchElement
                  componentProps={
                    isValidElement(item) ? item.props.componentProps : item.componentProps
                  }
                />
              );
            }

            return null;
          }}
        </ActionItemWrapper>
      ))}
      {contextHolder}
    </div>
  );
}

ActionCellRenderer.propTypes = {
  node: PropTypes.shape({
    group: PropTypes.bool,
    treeNode: PropTypes.shape({}),
  }),
  api: PropTypes.shape({
    forEachNode: PropTypes.func,
  }),
  data: PropTypes.shape({
    treeDataKeyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    deleted: PropTypes.bool,
  }),
  displayLikeButton: PropTypes.bool,
  showIcon: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: ConditionalPropertyPropType(PropTypes.oneOf(ACTION_COLUMN_ACTIONS)),
        url: ConditionalPropertyPropType(PropTypes.string),
        href: ConditionalPropertyPropType(PropTypes.string),
        hrefSelector: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            matched: ConditionalPropertyPropType(PropTypes.bool),
          }),
        ),
        hrefIsSiteBased: PropTypes.bool,
        suppressBasePath: PropTypes.bool,
        suppressInheritIncludeDeleted: PropTypes.bool,
        target: PropTypes.string,
        hidden: ConditionalPropertyPropType(PropTypes.bool),
        value: PropTypes.string,
        /**
         * @deprecated, use title instead
         */
        modalTitle: PropTypes.string,
        /**
         * @deprecated, use content instead
         */
        modalContent: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        component: PropTypes.oneOf(['Button', 'Switch']),
        componentProps: PropTypes.shape({}),
      }),
      PropTypes.element,
    ]),
  ),
};

export default withCurrentData(ActionCellRenderer);
