import PropTypes from 'prop-types';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  selectPermissionsByCategory,
  fetchPermissionList,
  selectContext,
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
} from '@icp/form-renderer-core';
import { resolveVariablePattern } from '@icp/utils';
import { useTranslation } from 'react-i18next';
import { Icon, Loading } from '@icp/components';
import { Segmented } from 'antd';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useChangeLanguage } from '@icp/i18n';
import { useEventCallback } from '@icp/hooks';
import { useDispatch, useSelector, useStore } from '../../store';
import { useComponentLibrary, useElementDecorator, useIsInDesign } from '../../FormRenderCtx';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import { TableElement } from '../index';
import PermissionsCellEditor from './PermissionsCellEditor';

const PermissionsElement = forwardRef(function PermissionsElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    style,
    disabled,
    readonly,
    componentProps = {},
    fieldTitleProps,
    validation,
    onChange,
    helpers,
  } = props;

  const { style: compStyle, dataUrl: urlPattern, dataResponseKeyPath } = componentProps;

  const { t } = useTranslation(['icp-form-renderer']);
  const ElementDecorator = useElementDecorator();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.permission.isLoading);
  const systemPermissionsByCategory = useSelector(selectPermissionsByCategory);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const permissions = value || [];
  const permissionIds = permissions.map((perm) => perm.id);

  const store = useStore();
  const context = selectContext(store.getState());
  const dataUrl = resolveVariablePattern({ context, pattern: urlPattern });
  const isInDesign = useIsInDesign();

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  useEffect(() => {
    if (isInDesign) {
      return () => {};
    }
    const p = dispatch(fetchPermissionList({ dataUrl, dataResponseKeyPath }));

    return () => {
      p.abort();
    };
  }, [dispatch, dataUrl, dataResponseKeyPath, isInDesign]);

  const [language] = useChangeLanguage();
  const componentLibrary = useComponentLibrary();
  const editable = !disabled && !readonly && !isInDesign;
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [showAll, setShowAll] = useState(editable && permissions.length === 0);
  const grantedPermissionsColumnWidth = useRef(null);

  const allRowData = useMemo(
    () =>
      systemPermissionsByCategory.map((category) => ({
        ...category,
        grantedPermissions: category.permissions.filter((perm) => permissionIds.includes(perm.id)),
      })),
    [permissionIds, systemPermissionsByCategory],
  );

  const permissionsRowData = useMemo(() => {
    return showAll
      ? allRowData
      : allRowData.filter(
          (category) => selectedCategories?.includes(category.categoryName) || false,
        );
  }, [showAll, allRowData, selectedCategories]);

  useEffect(() => {
    if (selectedCategories === null && allRowData.length > 0) {
      setSelectedCategories(
        allRowData
          .filter((category) => category.grantedPermissions.length > 0)
          .map((category) => category.categoryName),
      );
    }
  }, [selectedCategories, allRowData]);

  const handleChange = useEventCallback(({ data, values }) => {
    const newPermissions = permissions
      .filter((perm) => perm.target !== data.categoryName)
      .concat(data.permissions.filter((perm) => values.includes(perm.id)));
    const newSelectedCategories = [...new Set(newPermissions.map((perm) => perm.target))];
    if (showAll) {
      setSelectedCategories(newSelectedCategories);
    } else {
      // 在已选状态下，如果某一个权限目标下的授予权限清空，该行不会马上消失。
      const newAddedCategories = newSelectedCategories.filter(
        (category) => !selectedCategories.includes(category),
      );
      setSelectedCategories(selectedCategories.concat(newAddedCategories));
    }
    onChange?.(newPermissions);
  });

  const selectedModeToggle =
    componentLibrary === 'material-ui' ? (
      <ToggleButtonGroup
        color="primary"
        value={showAll}
        exclusive={true}
        size="small"
        onChange={(e, newValue) => setShowAll(newValue)}
      >
        <ToggleButton value={false} style={{ gap: 4 }}>
          <Icon name="material:checklist-rounded" />
          {t('permissions.selected')}
        </ToggleButton>
        <ToggleButton value={true} style={{ gap: 4 }}>
          <Icon name="material:menu-rounded" />
          {t('permissions.all')}
        </ToggleButton>
      </ToggleButtonGroup>
    ) : (
      <Segmented
        value={showAll}
        onChange={setShowAll}
        options={[
          {
            icon: <Icon name="material:checklist-rounded" />,
            label: t('permissions.selected'),
            value: false,
          },
          {
            icon: <Icon name="material:menu-rounded" />,
            label: t('permissions.all'),
            value: true,
          },
        ]}
      />
    );

  const columnDefs = useMemo(
    () => [
      {
        colId: 'categoryName',
        field: 'categoryName',
        headerName: t('permissions.target'),
        flex: 1,
        wrapText: true,
        cellStyle: { lineHeight: '1.5' },
      },
      {
        colId: 'grantedPermissions',
        field: 'grantedPermissions',
        headerName: t('permissions.granted'),
        flex: 2,
        wrapText: !editable,
        cellStyle: { lineHeight: '1.5' },
        cellRenderer: (params) =>
          editable ? (
            <PermissionsCellEditor {...params} onChange={handleChange} />
          ) : (
            params.data.grantedPermissions
              .map((perm) => `${perm.name}${perm.customScope ? `(${perm.customScope})` : ''}`)
              .join(', ')
          ),
      },
    ],
    [editable, handleChange, t],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'input-element',
          'form-element',
          'permissions-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
          classNameComp,
        )}
        style={{ ...style, ...compStyle }}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        {isLoading ? (
          <Loading />
        ) : (
          <TableElement
            noElementDecorator={true}
            keyPath={keyPath}
            componentProps={{
              suppressAddButton: true,
              suppressExcelExport: true,
              suppressColumnSelect: true,
              suppressToolbarRefresh: true,
              suppressFilterPanel: true,
              fuzzySearchPlaceholder: t('permissions.search-placeholder'),
              toolbarChildren: editable ? selectedModeToggle : null,
              rowModelType: 'clientSide',
              rowData: permissionsRowData,
              columnDefs: isInDesign ? [] : columnDefs,
              onColumnResized(params) {
                if (params.finished) {
                  grantedPermissionsColumnWidth.current =
                    params.api.getColumn('grantedPermissions')?.getActualWidth() || 800;
                  params.api.resetRowHeights();
                }
              },
              getRowHeight(params) {
                // TODO: 应该按照 权限名称实际上是什么语言 而不是按照 当前语言 来计算文字宽度
                const data = params.data;
                const items = data.grantedPermissions;
                const columnWidth = grantedPermissionsColumnWidth.current;
                if (columnWidth === null || items.length === 0) {
                  // 单行高度 54
                  return 54;
                }
                const text = items.map((perm) => perm.name).join('');
                // 字体宽度按 14 算，英文要除以 2
                const textWidth = (text.length * 14) / (language === 'en-US' ? 2 : 1);
                // 只读模式下除了文字以外，水平padding还有 14 宽度，然后每个选项之间用逗号分隔，约占 3.5 宽度
                const textRows = Math.floor(textWidth / (columnWidth - 14 - 3.5 * items.length));
                // 选择框里的每个标签除了文字以外额外有 32 的宽度需要加上
                const tagsWidth = textWidth + 32 * items.length;
                // 选择框的箭头与外面的padding等大概占宽度42，另外由于一个标签只能在一行内展示，所以额外减去标签宽度平均值的一半来估算
                const tagsRows = Math.floor(
                  tagsWidth / (columnWidth - 42 - tagsWidth / items.length / 2),
                );
                // 每增加一行增加 24 的高度，防止上面的取值有负值加上max
                return 54 + Math.max(0, editable ? tagsRows : textRows) * 24;
              },
            }}
          />
        )}
        <FormHelperText helpers={helpers} />
      </div>
    </ElementDecorator>
  );
});

PermissionsElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 获取数据的地址
     */
    dataUrl: PropTypes.string,
    /**
     * TODO, 设置 projectId 好像不合适，FormRenderer 里没有 project 的概念
     */
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
PermissionsElement.displayName = 'Permissions';

export default withFieldWrapper(PermissionsElement);
