import { useState } from 'react';
import { Divider, Select } from 'antd';
import clsx from 'clsx';
import { Icon } from '@icp/components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// PermissionsCellEditor 并非标准的 ag-grid cell editor，而是一个可修改 value 的 cell renderer 实现。
function PermissionsCellEditor(props) {
  const { value: cellValue, onChange, data } = props;

  const { t } = useTranslation(['icp-form-renderer']);

  const [selectedValues, setSelectedValues] = useState(cellValue.map((perm) => perm.id));
  const [searchText, setSearchText] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [hoverSelectAll, setHoverSelectAll] = useState(false);

  const allSelected = selectedValues.length === data.permissions.length;
  const selectAllClasses = {
    'ant-select-item-option-selected': allSelected,
    'ant-select-item-option-active': hoverSelectAll,
  };

  const selectAllOption = (
    <div
      onMouseEnter={() => setHoverSelectAll(true)}
      onMouseLeave={() => setHoverSelectAll(false)}
      className={clsx('select-all-option ant-select-item ant-select-item-option', selectAllClasses)}
      title={t('permissions.select-all')}
      onClick={() => setSelectedValues(allSelected ? [] : data.permissions.map((perm) => perm.id))}
    >
      <div className="ant-select-item-option-content">{t('permissions.select-all')}</div>
      {allSelected ? (
        <span className="ant-select-item-option-state">
          <Icon name="ant:check-outlined" size={13} />
        </span>
      ) : null}
    </div>
  );

  const handleDeselect = (deselectedItemId) => {
    const newSelectedValues = selectedValues.filter((itemId) => itemId !== deselectedItemId);
    if (isDropdownVisible) {
      setSelectedValues(newSelectedValues);
    } else {
      // 直接通过 tag 上的删除按钮操作时，需要直接调用 onChange
      onChange?.({ data, values: newSelectedValues });
    }
  };

  const hasNoChange = () => {
    if (selectedValues.length !== cellValue.length) {
      return false;
    }
    const originalValues = new Set(cellValue.map((perm) => perm.id));
    return selectedValues.filter((itemId) => !originalValues.has(itemId)).length === 0;
  };

  const handleUpdateSelection = () => {
    if (hasNoChange()) {
      // 如无变化就不调用 onChange，因为每次调用 onChange 会引起重新渲染，导致 UI 操作不流畅。
      return;
    }
    onChange?.({ data, values: selectedValues });
  };

  // 由于material的Select的UI还没完全适配ag-grid里的cellEditor，暂时先用Ant的Select统一处理。
  return (
    <Select
      placeholder={t('permissions.select-placeholder')}
      style={{ width: '100%' }}
      mode="multiple"
      variant="borderless"
      popupMatchSelectWidth={false}
      defaultActiveFirstOption={false}
      optionFilterProp="label"
      options={data.permissions?.map((perm) => ({
        label: `${perm.name}${perm.customScope ? `(${perm.customScope})` : ''}`,
        value: perm.id,
      }))}
      value={selectedValues}
      onChange={setSelectedValues}
      onDeselect={handleDeselect}
      onBlur={handleUpdateSelection}
      onSearch={setSearchText}
      onDropdownVisibleChange={setIsDropdownVisible}
      dropdownRender={(menu) =>
        searchText !== '' ? (
          menu
        ) : (
          <div
            onMouseDown={(e) => {
              // 防止点击 Select All 时，dropdown 自动关闭。
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            {selectAllOption}
          </div>
        )
      }
    />
  );
}

PermissionsCellEditor.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  data: PropTypes.shape({
    categoryName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default PermissionsCellEditor;
