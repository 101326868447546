import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { composeEvent } from '@icp/utils';
import { useElementDecorator } from '../../FormRenderCtx';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import CheckboxUI from './CheckboxUI';

const CheckboxElement = forwardRef(function CheckboxElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    componentProps = {},
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const {
    childEnum,
    options: optionsProp,
    content,
    direction = 'horizontal',
    gap,
    ...otherComponentProps
  } = componentProps;

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (childEnum) {
        console.warn(`'childEnum' is deprecated, use 'options' instead`);
      }
    }
  }, [childEnum]);

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const options = optionsProp || childEnum;

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'checkbox-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <CheckboxUI
          {...props}
          componentProps={{ ...otherComponentProps, className: classNameComp }}
          options={options}
          content={content}
          direction={direction}
          gap={gap}
          onChange={composeEvent(onChange, onTouchChanged)}
        />
      </div>
    </ElementDecorator>
  );
});

CheckboxElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  ]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    // TODO, delete childEnum, use options
    childEnum: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
    content: PropTypes.string,
    /**
     * 可选项
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
    /**
     * Checkbox 元素的方向
     */
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * Checkbox 之间的间距
     * @default 'small'
     */
    gap: PropTypes.oneOfType([PropTypes.oneOf(['small', 'middle', 'large']), PropTypes.number]),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
CheckboxElement.displayName = 'Checkbox';

export default withFieldWrapper(CheckboxElement);
