import { restApi } from '@icp/settings';

export default class Heartbeat {
  #entries = new Map();

  #timer;

  constructor() {
    this.#timer = setInterval(() => this.#execute(), 10000);
  }

  destroy() {
    if (this.#entries.size > 0) {
      console.log(`[AutoLock] destroy heartbeat`);
    }
    for (const entry of this.#entries.values()) {
      this.unregister(entry);
    }
    clearInterval(this.#timer);
  }

  register({ pbcToken, formEntityToken, formEntityDataId }) {
    if (pbcToken == null || formEntityToken == null || formEntityDataId == null) {
      return;
    }
    const key = `${pbcToken}#@#${formEntityToken}#@#${formEntityDataId}`;
    console.log(`[AutoLock] Heartbeat register: `, { pbcToken, formEntityToken, formEntityDataId });
    this.#entries.set(key, { pbcToken, formEntityToken, formEntityDataId });
  }

  unregister({ pbcToken, formEntityToken, formEntityDataId }) {
    if (pbcToken == null || formEntityToken == null || formEntityDataId == null) {
      return;
    }
    const key = `${pbcToken}#@#${formEntityToken}#@#${formEntityDataId}`;
    console.log(`[AutoLock] Heartbeat unregister: `, {
      pbcToken,
      formEntityToken,
      formEntityDataId,
    });
    this.#entries.delete(key);
  }

  async #execute() {
    const tasks = [];
    for (const [key, entry] of this.#entries) {
      const task = this.#heartbeat(key, entry);
      tasks.push(task);
    }
    await Promise.allSettled(tasks);
  }

  #heartbeat(key, entry) {
    // 如果心跳接口报错则不再继续发送
    restApi
      .post(
        `/form/api/form-entity-data-lock/lock/form-entity-data/${entry.formEntityDataId}/heartbeat`,
        {},
      )
      .catch(() => {
        this.#entries.delete(key);
      });
  }
}
