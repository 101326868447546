/* eslint-disable react/prop-types */
import { Modal, Tooltip } from 'antd';
import { message } from '@icp/settings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import SearchBox from '../../SearchBox';
import Icon from '../../Icon';
import Toolbar, {
  ColumnSelect,
  FavoriteView,
  FilterButton,
  FilterPanel,
  FullScreen,
  RefreshButton,
  TableSetting,
} from '../../Toolbar';
import Button from './Button';
import CombinedViewSwitch from '../../Toolbar/CombinedViewSwitch';

const TableToolbar = forwardRef(function TableToolbar(props, ref) {
  const {
    children,
    isInDesign,
    componentLibrary,
    gridApi,
    columnDefs,
    suppressToolbarActions,
    suppressAddButton,
    suppressDeleteButton,
    suppressFuzzySearch,
    suppressFuzzySearchSpeech,
    suppressColumnSelect,
    suppressExcelExport,
    suppressToolbarRefresh,
    suppressTableSetting,
    suppressFullscreen,
    supportShowDeleted,
    suppressFilterPanel,
    suppressFavoriteView,
    addButtonHref,
    addButtonContent,
    fuzzySearchPlaceholder,
    fuzzySearchOpen,
    rowSelection,
    hasDeletable,
    onAddRow,
    onDeleteRow,
    onExportExcel,
    searchText,
    onSearch,
    onRefresh,
    fullscreen,
    tableState,
    onTableStateChange,
    pinnedFilter,
    onResetSetting,
    settingKey,
    onSwitchFavoriteView,
    context,
    combinedView,
    ...other
  } = props;

  const { t } = useTranslation(['icp-components', 'icp-common']);
  const navigate = useNavigate();

  const [deleting, setDeleting] = useState(false);
  const [container, setContainer] = useState(null);
  const [filterPanelOpen, setFilterPanelOpen] = useState(true);

  const searchRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openSearch: searchRef.current?.open,
  }));

  const [modal, contextHolder] = Modal.useModal();

  const handleAddRow = (event) => {
    if (addButtonHref) {
      navigate(addButtonHref);
    } else if (onAddRow) {
      onAddRow(event);
    }
  };

  const handleDeleteRow = () => {
    // TODO, handle serverSide, rowModelType serverSide 不能调用 getSelectedRows
    // eslint-disable-next-line react/prop-types
    const selectedRows = gridApi.getSelectedRows();
    const selectedIds = selectedRows.filter((row) => row.deletable !== false).map((row) => row.id);

    modal.confirm({
      title: t('table.toolbar-delete-title'),
      content: t('table.toolbar-delete-content'),
      onOk: () => {
        setDeleting(true);
        onDeleteRow(selectedIds)
          .then(() => {
            message.success(t('delete-success', { ns: 'icp-common' }));
          })
          .finally(() => setDeleting(false));
      },
    });
  };

  const handleChangeColumnOrder = ({ fromId, toId }) => {
    // ag-grid 可能会在第一列显示 checkbox column，导致如果用 index 来做交换可能和 moveColumnByIndex 需要的
    // index 不匹配，所以需要根据 id 去找一下 ag-grid 里 column 真实显示的 index。
    const cols = gridApi.getAllGridColumns();
    const fromIndex = cols.findIndex((col) => col.colId === fromId);
    const toIndex = cols.findIndex((col) => col.colId === toId);
    gridApi.moveColumnByIndex(fromIndex, toIndex);
  };

  // const showFilterDirty = isGridReady ? tableState.filterModel.length > 0 : false;

  const { orderedColIds, hiddenColIds } = useMemo(() => {
    if (!tableState.columnState) {
      return {};
    }

    return {
      orderedColIds: tableState.columnState.map((cs) => cs.colId),
      hiddenColIds: tableState.columnState.filter((cs) => cs.hide).map((cs) => cs.colId),
    };
  }, [tableState.columnState]);

  const filterPanel =
    !suppressFilterPanel && filterPanelOpen ? (
      <FilterPanel
        context={context}
        columnDefs={columnDefs}
        pinnedFilter={pinnedFilter}
        filterModel={tableState.filterModel}
        onChange={(newFilterModel) => onTableStateChange({ filterModel: newFilterModel })}
      />
    ) : null;

  return (
    <Toolbar {...other} style={{ opacity: !gridApi ? 0 : 1 }} ref={setContainer}>
      <div className="icp-toolbar-main">
        {!combinedView ? filterPanel : <CombinedViewSwitch {...combinedView} />}
        {!suppressToolbarActions ? (
          <div className="icp-toolbar-actions">
            {children}
            {!suppressAddButton ? (
              <Button
                componentLibrary={componentLibrary}
                className="icp-toolbar-add-button"
                type="primary"
                icon={<Icon name="oct:plus-circle" size={16} />}
                onClick={handleAddRow}
              >
                {addButtonContent || t('add', { ns: 'icp-common' })}
              </Button>
            ) : null}
            {!suppressDeleteButton && rowSelection ? (
              <Tooltip title={t('delete', { ns: 'icp-common' })}>
                <Button
                  componentLibrary={componentLibrary}
                  className="icp-toolbar-delete-button"
                  type="text"
                  disabled={!hasDeletable}
                  icon={<Icon name="oct:trash" size={16} />}
                  loading={deleting}
                  onClick={handleDeleteRow}
                />
              </Tooltip>
            ) : null}
            {!suppressFuzzySearch ? (
              <SearchBox
                open={fuzzySearchOpen}
                placeholder={fuzzySearchPlaceholder}
                suppressSpeech={suppressFuzzySearchSpeech}
                defaultValue={searchText}
                onSearch={onSearch}
                ref={searchRef}
              />
            ) : null}
            {!suppressFilterPanel && combinedView ? (
              <FilterButton onClick={() => setFilterPanelOpen(!filterPanelOpen)} />
            ) : null}
            {!suppressColumnSelect ? (
              <ColumnSelect
                columnDefs={columnDefs}
                orderedColIds={orderedColIds}
                hiddenColIds={hiddenColIds}
                onVisibleChange={({ colId, hide }) => {
                  gridApi.setColumnsVisible([colId], !hide);
                }}
                onOrderChange={handleChangeColumnOrder}
              />
            ) : null}
            {!suppressToolbarRefresh ? (
              <RefreshButton icon={<Icon name="oct:sync" size={16} />} onClick={onRefresh} />
            ) : null}
            {!suppressFullscreen ? <FullScreen el={container?.parentNode} /> : null}
            {!suppressTableSetting ? (
              <TableSetting
                items={[
                  supportShowDeleted ? 'showDeleted' : null,
                  'size',
                  !suppressExcelExport ? 'export' : null,
                  'resetSetting',
                ].filter(Boolean)}
                size={tableState.tableSize}
                exportType="excel"
                onSizeChange={(newSize) => onTableStateChange({ tableSize: newSize })}
                onExport={onExportExcel}
                onResetSetting={onResetSetting}
                onRefresh={onRefresh}
              />
            ) : null}
            {!suppressFavoriteView && (isInDesign || settingKey) ? (
              <>
                <hr />
                <FavoriteView
                  settingKey={settingKey}
                  currentState={tableState}
                  onSwitchFavoriteView={onSwitchFavoriteView}
                />
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      {combinedView && filterPanelOpen ? (
        <div className="icp-toolbar-filter-panel-separate">{filterPanel}</div>
      ) : null}
      {contextHolder}
    </Toolbar>
  );
});

TableToolbar.propTypes = {
  children: PropTypes.node,
  isInDesign: PropTypes.bool,
  componentLibrary: PropTypes.string,
  gridApi: PropTypes.shape({}),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  suppressToolbarActions: PropTypes.bool,
  suppressAddButton: PropTypes.bool,
  suppressDeleteButton: PropTypes.bool,
  suppressFuzzySearch: PropTypes.bool,
  suppressFuzzySearchSpeech: PropTypes.bool,
  suppressColumnSelect: PropTypes.bool,
  suppressExcelExport: PropTypes.bool,
  suppressToolbarRefresh: PropTypes.bool,
  suppressTableSetting: PropTypes.bool,
  suppressFullscreen: PropTypes.bool,
  supportShowDeleted: PropTypes.bool,
  suppressFilterPanel: PropTypes.bool,
  suppressFavoriteView: PropTypes.bool,
  addButtonHref: PropTypes.string,
  addButtonContent: PropTypes.string,
  fuzzySearchPlaceholder: PropTypes.string,
  fuzzySearchOpen: PropTypes.bool,
  rowSelection: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  hasDeletable: PropTypes.bool,
  onAddRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onExportExcel: PropTypes.func,
  searchText: PropTypes.string,
  onSearch: PropTypes.func,
  onRefresh: PropTypes.func,
  fullscreen: PropTypes.bool,
  tableState: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small', 'large']),
    filterModel: PropTypes.arrayOf(PropTypes.shape({})),
    columnState: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onTableStateChange: PropTypes.func,
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  // onTableSizeChange: PropTypes.func,
  onResetSetting: PropTypes.func,
  settingKey: PropTypes.string,
  onSwitchFavoriteView: PropTypes.func,
  context: PropTypes.shape({}),
  combinedView: PropTypes.shape({}),
};

export default TableToolbar;
