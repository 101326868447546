import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Input } from 'antd';
import { selectFieldValueI18n } from '@icp/form-renderer-core';
import { composeEvent } from '@icp/utils';
import { useSelector } from '../../../store';

function I18nInputSingle(props) {
  const {
    id,
    languageOp,
    value: valueProp,
    onChange,
    onBlur,
    ...other
    //
  } = props;

  const valueI18n = useSelector(selectFieldValueI18n(id, languageOp.value));
  const [value, setValue] = useState(valueI18n);

  useEffect(() => {
    setValue(valueI18n);
  }, [valueI18n]);

  return (
    <Input
      {...other}
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      onBlur={composeEvent(() => onChange(value, languageOp.value), onBlur)}
      addonBefore={languageOp.label}
    />
  );
}

I18nInputSingle.propTypes = {
  id: PropTypes.string,
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default I18nInputSingle;
