import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { selectFieldValueI18n } from '@icp/form-renderer-core';
import { composeEventAsync } from '@icp/utils';
import { useSelector } from '../../../store';
import ValueLanguageSelect from '../../../ValueLanguageSelect';

const I18nInputMulti = forwardRef(function I18nInputMulti(props, ref) {
  const {
    id,
    value: valueProp,
    onChange,
    onBlur,
    ...other
    //
  } = props;

  const { i18n } = useTranslation();

  const [valueLanguage, setValueLanguage] = useState(i18n.language);
  const valueI18n = useSelector(selectFieldValueI18n(id, valueLanguage));
  const [value, setValue] = useState(valueI18n);

  useEffect(() => {
    setValue(valueI18n);
  }, [valueI18n]);

  return (
    <Input
      {...other}
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      onBlur={composeEventAsync(() => onChange(value, valueLanguage), onBlur)}
      addonBefore={<ValueLanguageSelect value={valueLanguage} onChange={setValueLanguage} />}
      ref={ref}
    />
  );
});

I18nInputMulti.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default I18nInputMulti;
