import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { message } from '@icp/settings';
import { useTranslation } from 'react-i18next';
import { PAGE_TYPE, postFlowFormData } from '@icp/page-renderer-core';
import { Heartbeat, unlockFormEntityData } from '@icp/form-renderer-core';
import FormRendererSelector from '../FormRendererSelector';

const FormNodeFormRenderer = forwardRef(function FormNodeFormRender(props, ref) {
  const {
    pbcToken,
    flowToken,
    flowDefinitionId,
    flowInstanceId,
    threadId,
    instanceData,
    context: contextProp,
    FormRendererProps,
  } = props;
  const { t } = useTranslation(['icp-form-renderer']);
  const [heartbeat] = useState(() => new Heartbeat());

  const {
    data: defaultData,
    formEntityDataId,
    formEntityId,
    layoutId,
    pbcToken: formPbcToken,
    formEntityToken,
    layoutToken,
    lock,
  } = instanceData;

  // context must memo, every context change will trigger FormRender make new store
  // EditableTableElement 里需要用到 formEntityId formEntityDataId
  const context = useMemo(() => {
    return {
      ...contextProp,
      pbcToken: formPbcToken,
      formEntityId,
      formEntityDataId,
      pageType: flowInstanceId ? PAGE_TYPE.flowEdit : PAGE_TYPE.flowCreate,
    };
  }, [contextProp, formPbcToken, formEntityId, formEntityDataId, flowInstanceId]);

  useEffect(() => {
    if (lock) {
      const heartBeatEntry = {
        pbcToken: formPbcToken,
        formEntityToken,
        formEntityDataId,
      };
      heartbeat.register(heartBeatEntry);
      return () => {
        heartbeat.unregister(heartBeatEntry);
        unlockFormEntityData(formEntityDataId);
      };
    }
    return () => {};
  }, [heartbeat, lock, formPbcToken, formEntityToken, formEntityDataId]);

  const handleSave = (data) => {
    return postFlowFormData({
      pbcToken,
      flowToken,
      formPbcToken,
      formEntityToken,
      layoutToken,
      flowDefinitionId,
      flowInstanceId,
      threadId,
      layoutId,
      saveOnly: true,
      data,
    }).then(
      (response) => {
        message.success(response?.successMessage || t('success.save'));
        return { ...data, preventSuccessMessage: true, ...response };
      },
      (errors) => {
        return Promise.reject(errors);
      },
    );
  };

  const handleSubmit = (data) => {
    return postFlowFormData({
      pbcToken,
      flowToken,
      formPbcToken,
      formEntityToken,
      layoutToken,
      flowDefinitionId,
      flowInstanceId,
      threadId,
      data,
      layoutId,
    }).then(
      (response) => {
        message.success(response?.successMessage || t('success.submit'));
        return { ...data, preventSuccessMessage: true, ...response };
      },
      (errors) => {
        return Promise.reject(errors);
      },
    );
  };

  return (
    <div className="flow-form-renderer">
      <FormRendererSelector
        {...FormRendererProps}
        formEntityToken={formEntityToken}
        layoutToken={layoutToken}
        layoutId={layoutId}
        defaultData={{
          ...FormRendererProps?.defaultData,
          ...defaultData,
        }}
        context={context}
        onSubmit={handleSubmit}
        onSave={handleSave}
        ref={ref}
      />
    </div>
  );
});

FormNodeFormRenderer.propTypes = {
  pbcToken: PropTypes.string,
  flowToken: PropTypes.string,
  // id from params is string
  flowDefinitionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flowInstanceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  instanceData: PropTypes.shape({
    data: PropTypes.shape({}),
    formEntityDataId: PropTypes.number,
    formEntityId: PropTypes.number,
    layoutId: PropTypes.number,
    pbcToken: PropTypes.string,
    formEntityToken: PropTypes.string,
    layoutToken: PropTypes.string,
    lock: PropTypes.shape({}),
  }),
  context: PropTypes.shape({}),
  FormRendererProps: PropTypes.shape({
    defaultData: PropTypes.shape({}),
  }),
};

export default FormNodeFormRenderer;
