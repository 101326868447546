import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Input } from 'antd';
import { composeEventAsync } from '@icp/utils';

const NoI18nInput = forwardRef(function NoI18nInput(props, ref) {
  const {
    value: valueProp,
    onChange,
    onBlur,
    ...other
    //
  } = props;

  const inputRef = useRef();
  const [value, setValue] = useState(valueProp);

  useImperativeHandle(
    ref,
    () => {
      if (inputRef.current) {
        inputRef.current[Symbol.for('icp_setValue')] = setValue;
      }
      return inputRef.current;
    },
    [],
  );

  return (
    <Input
      {...other}
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      onBlur={composeEventAsync(() => onChange(value), onBlur)}
      ref={inputRef}
    />
  );
});

NoI18nInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default NoI18nInput;
