import { useCallback, useEffect, useRef, useState } from 'react';
import { getSetFilterOptions } from './utils';

const cacheBlockSize = 50;

function useSetFilterOptionsLazy({ searchText, colDef }) {
  // getOptions 是我们组件自定义的配置，必须返回 promise
  const { getOptions } = colDef.filterParams || {};

  const skip = typeof getOptions !== 'function';

  const [options, setOptions] = useState(null);
  const [freshLoading, setFreshLoading] = useState(!skip);
  const [moreLoading, setMoreLoading] = useState(false);

  const dataLength = useRef(0);
  dataLength.current = options?.length || 0;

  const hasMore = dataLength.current % cacheBlockSize === 0;

  const fetchOptions = useCallback(
    (signal, isLoadMore) => {
      if (isLoadMore) {
        setMoreLoading(true);
      } else {
        setFreshLoading(true);
      }

      return getOptions(
        {
          searchText,
          startRow: isLoadMore ? dataLength.current : 0,
          endRow: (isLoadMore ? dataLength.current : 0) + cacheBlockSize,
        },
        signal,
      ).then((data) => {
        setFreshLoading(false);
        setMoreLoading(false);
        return data;
      });
    },
    [getOptions, searchText],
  );

  useEffect(() => {
    if (skip) {
      return () => {};
    }

    const controller = new AbortController();
    const { signal } = controller;

    fetchOptions(signal, false).then(setOptions);

    return () => {
      controller.abort();
    };
  }, [fetchOptions, setOptions, skip]);

  const loadMore = (signal) => {
    if (skip || !hasMore || moreLoading) {
      return;
    }

    fetchOptions(signal, true).then((data) => {
      setOptions((exists) => (exists || []).concat(data));
    });
  };

  if (skip) {
    return null;
  }

  return { loading: freshLoading, moreLoading, options, hasMore, loadMore };
}

function useSetFilterOptionsNotLazy(context, colDef) {
  const [options, setOptions] = useState(null);

  const { getOptions } = colDef.filterParams || {};

  const skip = typeof getOptions === 'function';

  useEffect(() => {
    // getSetFilterOptions 里是使用 ag-grid 官方的 filterParams.values 的配置
    const res = getSetFilterOptions(context, colDef);
    if (res instanceof Promise) {
      res.then(setOptions);
    } else {
      setOptions(res);
    }
  }, [colDef, context]);

  if (skip) {
    return null;
  }

  return { options };
}

export default function useSetFilterOptions({ searchText, context, colDef }) {
  const res1 = useSetFilterOptionsLazy({ searchText, colDef });
  const res2 = useSetFilterOptionsNotLazy(context, colDef);

  return res1 || res2;
}
