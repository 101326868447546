/* eslint-disable react/prop-types */
import { forwardRef, Suspense } from 'react';
import { selectSchemaMode } from '@icp/form-renderer-core';
import { isInputField } from '@icp/form-schema';
import { compose } from '@icp/utils';
import { useForkRef } from '@icp/hooks';
import { LoadI18nResource } from '@icp/components';
import FormControl from './FormControl';
import { useConditionalProperty, useTranslatedProps } from './hooks';
import { useSelector } from './store';
import ErrorBoundary from './ErrorBoundary';
import { useSetFieldApi } from './FormRenderCtx';

export function withFormControl(WrappedComponent) {
  return forwardRef(function WithFormControl(props, ref) {
    const { suppressFormControl, ...other } = props;

    if (suppressFormControl || props.id === undefined || props.id === null) {
      return <WrappedComponent {...other} ref={ref} />;
    }

    return (
      <FormControl>
        <WrappedComponent {...other} ref={ref} />
      </FormControl>
    );
  });
}

function useSelectSchemaMode() {
  return useSelector(selectSchemaMode);
}

export const withTranslatedProps =
  ({
    underFormRenderer = true,
    useSkip = underFormRenderer ? useSelectSchemaMode : () => false,
  } = {}) =>
  (WrappedComponent) => {
    return forwardRef(function WithTranslatedProps(props, ref) {
      const skip = useSkip();
      useTranslatedProps(props, skip);
      return <WrappedComponent {...props} ref={ref} />;
    });
  };

export function withConditionalHidden(WrappedComponent) {
  return forwardRef(function WithConditionalHidden(props, ref) {
    const { hidden: hiddenProp, ...other } = props;

    const hidden = useConditionalProperty(hiddenProp);

    if (hidden) {
      return null;
    }

    return <WrappedComponent {...other} ref={ref} />;
  });
}

export const withErrorBoundary = (errorBoundaryProps) => (Comp) => {
  const canForwardRef = typeof Comp !== 'function';
  return forwardRef(function WithErrorBoundary(props, ref) {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Comp {...props} {...(canForwardRef && { ref })} />
      </ErrorBoundary>
    );
  });
};

export const withSuspense =
  ({ fallback }) =>
  (Comp) => {
    const canForwardRef = typeof Comp !== 'function';
    const Inner = forwardRef(function WithSuspense(props, ref) {
      return (
        <ErrorBoundary showActualError={true}>
          <Suspense fallback={fallback}>
            <Comp {...props} {...(canForwardRef && { ref })} />
          </Suspense>
        </ErrorBoundary>
      );
    });

    Object.entries(Comp).forEach(([k, v]) => {
      Inner[k] = v;
    });

    return Inner;
  };

export const withCommonWrapper = (WrappedComponent) => {
  return forwardRef(function WithCommonWrapper(props, ref) {
    // set field ref to form render
    const setFieldApi = useSetFieldApi();
    const handleRef = useForkRef(ref, (api) => {
      setFieldApi(props.id, api);
    });

    // conditional hidden
    const { hidden: hiddenProp, ...other } = props;
    const hidden = useConditionalProperty(hiddenProp);

    // translate props
    const schemaMode = useSelectSchemaMode();
    useTranslatedProps(props, schemaMode);

    if (hidden) {
      return null;
    }

    return <WrappedComponent {...other} ref={props.id ? handleRef : ref} />;
  });
};

export function withResourceBundle(ns) {
  return (WrappedComponent) => {
    return forwardRef(function WithResourceBundle(props, ref) {
      return (
        <LoadI18nResource ns={ns}>
          <WrappedComponent {...props} ref={ref} />
        </LoadI18nResource>
      );
    });
  };
}

export const withFieldWrapper = (WrappedComponent, options = {}) => {
  const component = WrappedComponent.displayName;

  const wrappers = [withErrorBoundary(), withCommonWrapper];
  if (options.ns) {
    wrappers.push(withResourceBundle(options.ns));
  }
  if (isInputField({ component })) {
    wrappers.push(withFormControl);
  }

  // for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
  const Wrapper = compose(...wrappers)(WrappedComponent);

  Wrapper.displayName = WrappedComponent.displayName;

  return Wrapper;
};
