import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { restApi } from '@icp/settings';
import { isThisWeek, isThisYear, isToday } from '@icp/utils';
import { createMessage, MessageType } from '../../utils';
import { SESSION_DATE_CATEGORY, VIEW_ENUM } from '../../consts';

const Convertor = {
  Agent: {
    fromDTO: (agent) => {
      const { formEntityData } = agent;
      if (!formEntityData) {
        return null;
      }
      const { agentName, avatar, description } = formEntityData;
      return {
        id: formEntityData.id,
        name: agentName,
        avatar: avatar?.resources?.[0]?.url,
        description: description?.longText,
        data: formEntityData,
      };
    },
  },
  Session: {
    fromDTO: (session) => {
      const date = session.last_active_time;
      const dateCategory = (() => {
        if (isToday(date)) return SESSION_DATE_CATEGORY.TODAY;
        if (isThisWeek(date)) return SESSION_DATE_CATEGORY.THIS_WEEK;
        if (isThisYear(date)) return SESSION_DATE_CATEGORY.THIS_YEAR;
        return SESSION_DATE_CATEGORY.OTHER;
      })();

      return {
        id: session.thread_id,
        title: session.title || session.last_message || session.thread_id,
        date,
        assistantId: session.last_assistant_id || session.assistant_id,
        dateCategory,
      };
    },
  },
};

const selectThis = (state) => state[slice.name];
export const selectActiveView = createSelector(selectThis, (state) => state.activeView);
export const selectAgentListLoaded = createSelector(selectThis, (state) => state.agentListLoaded);
export const selectAgentList = createSelector(selectThis, (state) => state.agentList);
export const selectCurrentAgent = createSelector(selectThis, (state) => state.currentAgent);
export const selectSessionListLoaded = createSelector(selectThis, (s) => s.sessionListLoaded);
export const selectSessionList = createSelector(selectThis, (state) => state.sessionList);
export const selectCurrentSession = createSelector(selectThis, (state) => state.currentSession);
export const selectCurrentSessionId = createSelector(selectCurrentSession, (cs) => cs?.id);
export const selectModelListLoaded = createSelector(selectThis, (state) => state.modelListLoaded);
export const selectModelList = createSelector(selectThis, (state) => state.modelList);
export const selectCurrentModel = createSelector(selectThis, (state) => state.currentModel);
export const selectPending = createSelector(selectThis, (state) => state.pending);
export const selectProgressInfo = createSelector(selectThis, (state) => state.progressInfo);
export const selectMessagesLoaded = createSelector(selectThis, (state) => state.messagesLoaded);
export const selectMessages = createSelector(selectThis, (state) => state.messages);
export const selectMessagesCount = createSelector(selectMessages, (messages) => messages.length);

const LoaderCache = {
  getLoaderOrElse(cacheKey, createLoader) {
    const loader = this[cacheKey];
    if (loader) {
      return loader;
    }
    const loaderPromise = Promise.resolve(createLoader());
    this[cacheKey] = loaderPromise;
    return loaderPromise.catch((error) => {
      this[cacheKey] = null;
      throw error;
    });
  },
  remove(cacheKey) {
    this[cacheKey] = null;
  },
};

export const fetchAgentListOneTime = createAsyncThunk(
  'agent/fetchAgentListOneTime',
  async (payload, { signal, getState, extra }) => {
    const agentListLoaded = selectAgentListLoaded(getState());
    if (agentListLoaded) {
      return selectAgentList(getState());
    }
    const loader = LoaderCache.getLoaderOrElse('agent-list', () =>
      restApi
        .get(extra.apiUrls.getAgentList, {
          signal,
          params: Object.fromEntries(new URLSearchParams(window.location.search).entries()),
        })
        .then((res) => res.agents || []),
    );
    const agentDTOList = await loader;
    return agentDTOList.map(Convertor.Agent.fromDTO).filter(Boolean);
  },
);

export const fetchAgentActions = createAsyncThunk(
  'agent/fetchAgentActions',
  async (payload, { signal, extra }) => {
    const url = extra.apiUrls.getActionList.replace('{agent-id}', payload.id);
    if (!payload?.id || !url) {
      return Promise.resolve({ actions: [] });
    }
    return restApi
      .get(url, {
        signal,
        params: Object.fromEntries(new URLSearchParams(window.location.search).entries()),
      })
      .then((res) => {
        const { results } = res;
        const actions = results.map((item) => ({
          name: item.name,
          request: item.request,
          args: item.args,
        }));
        return { agentId: payload.id, actions };
      });
  },
);

export const fetchSessionList = createAsyncThunk(
  'agent/fetchSessionList',
  async (payload, { signal, getState, extra }) => {
    const sessionListLoaded = selectSessionListLoaded(getState());
    if (sessionListLoaded) {
      return selectSessionList(getState());
    }
    const loader = LoaderCache.getLoaderOrElse('session-list', () =>
      restApi
        .get(extra.apiUrls.getThreadList, {
          signal,
          params: Object.fromEntries(new URLSearchParams(window.location.search).entries()),
        })
        .then((res) => res?.data || [])
        .finally(() => LoaderCache.remove('session-list')),
    );
    const sessionList = await loader;
    return sessionList.map(Convertor.Session.fromDTO);
  },
);

export const deleteSession = createAsyncThunk('agent/deleteSession', async (payload) => {
  const session = payload;
  await restApi.delete(`/gpt-wrapper-api/api/v1/agent/delete-thread`, {
    params: {
      ...Object.fromEntries(new URLSearchParams(window.location.search).entries()),
      threadId: session.id,
    },
  });
});

export const fetchModelListOneTime = createAsyncThunk(
  'agent/fetchModelListOneTime',
  async (payload, { signal, getState }) => {
    const modelListLoaded = selectModelListLoaded(getState());
    if (modelListLoaded) {
      return selectModelList(getState());
    }
    const loader = LoaderCache.getLoaderOrElse('model-list', () =>
      restApi
        .get('/aip/api/ai/supported-models', {
          signal,
          params: Object.fromEntries(new URLSearchParams(window.location.search).entries()),
        })
        .then((res) => res || []),
    );
    const modelList = await loader;
    return modelList;
  },
);

export const fetchMessageList = createAsyncThunk(
  'agent/fetchMessageList',
  async (payload, { dispatch, getState, signal, extra }) => {
    const currentSessionId = selectCurrentSessionId(getState());

    const messageList$ = restApi
      .get(extra.apiUrls.getMessageList, {
        signal,
        params: {
          ...Object.fromEntries(new URLSearchParams(window.location.search).entries()),
          threadId: currentSessionId,
        },
      })
      .then((res) => res.data || [])
      .then((list) => list.sort((a, b) => a.created_at - b.created_at));

    const [messageList] = await Promise.all([
      messageList$,
      dispatch(fetchAgentListOneTime()),
      //
    ]);

    const agentList = selectAgentList(getState());

    const messages = messageList.map((msg) => {
      const msgContent = msg.content?.[0]?.text ?? '';
      return msg.role === 'user'
        ? createMessage(MessageType.Prompt, { prompt: msgContent }, true)
        : createMessage(MessageType.Answer, { text: msgContent }, false);
    });

    // 从后往前倒查匹配 assistantId -> agent, 查不到就置空让用户再选一个agent
    let inferredAgent = messageList
      .map((rawMsg) => rawMsg.assistant_id)
      .filter(Boolean)
      .reverse()
      .reduce((found, assistantId) => {
        if (found) return found;
        return agentList.find((agent) => agent.data.assistantId === assistantId);
      }, null);

    // 只有一个agent时，自动选中
    if (agentList.length === 1 && !inferredAgent) {
      inferredAgent = agentList[0];
    }

    if (inferredAgent) {
      await dispatch(chooseAgent({ agent: inferredAgent }));
    } else {
      payload.onAgentMissing?.();
    }

    return { messages, agent: inferredAgent };
  },
);

export const initActiveView = createAsyncThunk(
  'agent/initActiveView',
  async (payload, { dispatch, getState }) => {
    const { useAgents } = payload;
    if (!useAgents) {
      // 关闭agent功能的直接显示消息列表
      dispatch(setActiveView({ view: VIEW_ENUM.MESSAGE }));
      return;
    }

    await dispatch(fetchSessionList());
    const sessionList = selectSessionList(getState());

    if (sessionList.length === 0) {
      // 没历史会话的，自动新会话
      dispatch(startNewChat());
      dispatch(setActiveView({ view: VIEW_ENUM.AGENT }));
      return;
    }

    // 有历史会话的选会话
    dispatch(setActiveView({ view: VIEW_ENUM.SESSION, skipAutoReload: true }));
  },
  {
    condition: (payload, { getState }) => {
      const activeView = selectActiveView(getState());
      return activeView == null;
    },
  },
);

export const chooseAgent = (payload) => (dispatch) => {
  const { agent } = payload;
  if (!agent?.data?.functions?.length) {
    return dispatch(fetchAgentActions({ id: agent.id }))
      .unwrap()
      .then(({ actions }) => {
        const currentAgent = {
          ...agent,
          data: {
            ...agent.data,
            functions: actions ?? [],
          },
        };
        return dispatch(setCurrentAgent(currentAgent));
      });
  }
  return dispatch(setCurrentAgent(agent));
};

const slice = createSlice({
  name: 'agent',
  initialState: {
    activeView: null, // keyof VIEW_ENUM

    sessionListLoaded: false,
    sessionList: [],
    currentSession: null,

    agentListLoaded: false,
    agentList: [],
    currentAgent: null,

    modelListLoaded: false,
    modelList: [],
    currentModel: null,

    messagesLoaded: false,
    messages: [],

    // pending单指AI问答请求中
    pending: false,
    // 由type为PROGRESS的SSE消息得来，用于显示当前进度状态信息。在收到下一条其它消息后重置为空。
    progressInfo: null,
  },
  reducers: {
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setProgressInfo: (state, action) => {
      state.progressInfo = action.payload;
    },
    setActiveView: (state, action) => {
      const { view: activeView, skipAutoReload = false } = action.payload;
      state.activeView = activeView;
      if (activeView === VIEW_ENUM.SESSION) {
        state.currentSession = null;
        state.currentAgent = null;

        state.currentModel = null;

        state.pending = false;
        state.messages = [];
        state.messagesLoaded = false;

        if (!skipAutoReload) {
          // 切session view触发重载session list
          state.sessionListLoaded = false;
          state.sessionList = [];
        }
      }
    },
    setCurrentSession: (state, action) => {
      state.currentSession = action.payload;
      state.activeView = VIEW_ENUM.MESSAGE;
    },
    setCurrentModel: (state, action) => {
      state.currentModel = action.payload;
    },
    setCurrentAgent: (state, action) => {
      state.currentAgent = action.payload;
      state.activeView = VIEW_ENUM.MESSAGE;
    },
    setAgentAvatarBlob: (state, action) => {
      [...state.agentList, state.currentAgent]
        .filter(Boolean)
        .filter((agent) => agent.avatar === action.payload.avatar)
        .forEach((agent) => {
          agent.avatarBlob = action.payload.avatarBlob;
        });
    },
    appendMessages: (state, action) => {
      state.messages = [...state.messages, ...action.payload];
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    clearMessagesByType: (state, action) => {
      state.messages = state.messages.filter((msg) => msg.type !== action.payload);
    },
    replaceMessageById: (state, action) => {
      const index = state.messages.findIndex((msg) => msg.id === action.payload.id);
      if (index >= 0) {
        state.messages[index] = action.payload;
      }
    },
    startNewChat: (state) => {
      state.currentSession = null;
      state.currentAgent = null;
      state.currentModel = null;
      state.pending = false;
      state.messages = [];
      state.messagesLoaded = true;
    },
    setMessageFeedback: (state, action) => {
      const { messageId, feedback } = action.payload;
      const index = state.messages.findIndex((msg) => msg.id === messageId);
      if (index >= 0) {
        state.messages[index] = {
          ...state.messages[index],
          content: {
            ...state.messages[index].content,
            feedback,
          },
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initActiveView.rejected, (state) => {
        state.activeView = VIEW_ENUM.AGENT;
      })
      .addCase(fetchSessionList.pending, (state) => {
        state.sessionListLoaded = false;
      })
      .addCase(fetchSessionList.fulfilled, (state, action) => {
        state.sessionList = action.payload;
        state.sessionListLoaded = true;
      })
      .addCase(fetchSessionList.rejected, (state) => {
        state.sessionList = [];
        state.sessionListLoaded = true;
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        const sessionDeleted = action.meta.arg;
        state.sessionList = state.sessionList.filter((session) => session.id !== sessionDeleted.id);
      })
      .addCase(fetchModelListOneTime.pending, (state) => {
        state.modelListLoaded = false;
      })
      .addCase(fetchModelListOneTime.fulfilled, (state, action) => {
        state.modelList = action.payload;
        state.modelListLoaded = true;
      })
      .addCase(fetchModelListOneTime.rejected, () => {
        // do nothing
      })
      .addCase(fetchAgentListOneTime.pending, (state) => {
        state.agentListLoaded = false;
      })
      .addCase(fetchAgentListOneTime.fulfilled, (state, action) => {
        state.agentList = action.payload;
        state.agentListLoaded = true;
      })
      .addCase(fetchAgentListOneTime.rejected, () => {
        // do nothing
      })
      .addCase(fetchMessageList.pending, (state) => {
        state.messagesLoaded = false;
      })
      .addCase(fetchMessageList.fulfilled, (state, action) => {
        const { messages, agent } = action.payload;
        state.messagesLoaded = true;
        state.messages = messages;
        state.currentAgent = agent;
        if (!agent) {
          state.activeView = VIEW_ENUM.AGENT;
        }
      })
      .addCase(fetchMessageList.rejected, (state) => {
        state.messagesLoaded = true;
        state.messages = [];
      })
      .addCase(fetchAgentActions.fulfilled, (state, action) => {
        const { agentId, actions } = action.payload;
        const index = state.agentList.findIndex((agent) => {
          return agent.id === agentId;
        });
        if (index >= 0) {
          state.agentList[index] = {
            ...state.agentList[index],
            data: {
              ...state.agentList[index].data,
              functions: actions,
            },
          };
        }
      })
      .addCase(fetchAgentActions.rejected, () => {
        // do nothing
      });
  },
});

export const {
  setPending,
  setProgressInfo,
  setActiveView,
  setCurrentSession,
  setCurrentModel,
  setCurrentAgent,
  setAgentAvatarBlob,
  appendMessages,
  clearMessages,
  clearMessagesByType,
  replaceMessageById,
  startNewChat,
  setMessageFeedback,
  setAgentFunctions,
  //
} = slice.actions;

export default slice;
