/* eslint-disable no-underscore-dangle */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Popover } from '@icp/components';
import { resolveNestedValue, selectContext, selectValues } from '@icp/form-renderer-core';
import dayjs from 'dayjs';
import { resolveVariablePattern, toDateValue } from '@icp/utils';
import PropTypes from 'prop-types';
import ButtonUI from '../ButtonElement/ButtonUI';
import SimpleButtonElement from './SimpleButtonElement';

function GanttActionCellRenderer(props) {
  const {
    // FormRenderer 的上下文属性
    store,
    params,
    //
    task,
    ganttApiRef,
    deletePromisesRef,
    mapping: topMapping,
    isTreeDataSource,
    dateOptions,
  } = props;

  const { t } = useTranslation(['icp-form-renderer']);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const canAddChild = Boolean(!isTreeDataSource ? topMapping.parent : task._dataSourceConfig.child);

  const addButtonProps = task._dataSourceConfig?.addButtonProps || {};
  const editButtonProps = task._dataSourceConfig?.editButtonProps || {};
  const deleteButtonProps = task._dataSourceConfig?.deleteButtonProps || {};

  const handleAddSubTask = (event) => {
    event.stopPropagation();
    const subDataSourceConfig = !isTreeDataSource
      ? task._dataSourceConfig
      : task._dataSourceConfig.child;
    const subMapping = { ...topMapping, ...subDataSourceConfig.mapping };

    const parentFieldValue = resolveNestedValue({
      obj: subDataSourceConfig.parentFieldValue || ':id',
      currentData: task,
      // 暂时不提供 formData, context 等，新建子任务的时候不应该有这种需求
    });

    ganttApiRef.current.openDialog({
      _original: {
        [subMapping.parent]: parentFieldValue,
        [subMapping.start_date]: task[subMapping.start_date],
        [subMapping.end_date]: task[subMapping.start_date]
          ? toDateValue(dayjs(task[subMapping.start_date]).add(1, 'day'), dateOptions)
          : undefined,
        [subMapping.duration]: 1,
      },
      // _dataSourceConfig: !isTreeDataSource ? task._dataSourceConfig : task._dataSourceConfig.child,
      _dataSourceConfig: task._dataSourceConfig,
      _isAddSubTask: true,
    });
    setAnchorEl(null);
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    ganttApiRef.current.openDialog(task);
    setAnchorEl(null);
  };

  const handleDelete = (event) => {
    const { action: deleteAction = {} } = deleteButtonProps.componentProps || {};
    event.stopPropagation();
    const content =
      deleteAction.content &&
      resolveVariablePattern({
        pattern: deleteAction.content,
        currentData: task._original,
        formData: selectValues(store.getState()),
        context: selectContext(store.getState()),
        params,
      });
    ganttApiRef.current.gantt.confirm({
      title: deleteAction.title || t('action.delete-title'),
      content: content || t('action.delete-confirm', { name: task.text }),
      callback: () => {
        // 删除 task 会自动级联删除所有的子 task 和所有相关的 link
        ganttApiRef.current.setLoading(true);
        deletePromisesRef.current = [];
        ganttApiRef.current.gantt.deleteTask(task.id);
        setTimeout(() => {
          Promise.all([...deletePromisesRef.current]).then(() => {
            ganttApiRef.current.setLoading(false);
          });
        }, 16);
      },
    });
    setAnchorEl(null);
  };

  return (
    <div className="gantt-action-cell">
      <ButtonUI
        type="text"
        size="small"
        icon="oct:kebab-horizontal"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.target);
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        className="gantt-action-popover"
        onClose={() => setAnchorEl(null)}
      >
        {canAddChild ? (
          <SimpleButtonElement
            {...addButtonProps}
            store={store}
            params={params}
            currentData={task._original}
            componentProps={{
              // 改了这里 Button 的默认 icon content 记得去 TaskDataSourceSetting.js 里同时修改
              icon: 'oct:plus',
              content: t('gantt.new-sub-task', { ns: 'icp-form-renderer' }),
              ...addButtonProps.componentProps,
              onClick: handleAddSubTask,
            }}
          />
        ) : null}
        <SimpleButtonElement
          {...editButtonProps}
          store={store}
          params={params}
          currentData={task._original}
          componentProps={{
            icon: 'solar:pen-bold-duotone',
            content: t('edit', { ns: 'icp-common' }),
            ...editButtonProps.componentProps,
            onClick: handleEdit,
          }}
        />
        <SimpleButtonElement
          {...deleteButtonProps}
          store={store}
          params={params}
          currentData={task._original}
          componentProps={{
            icon: 'solar:trash-bin-minimalistic-bold-duotone',
            content: t('delete', { ns: 'icp-common' }),
            danger: true,
            ...deleteButtonProps.componentProps,
            onClick: handleDelete,
          }}
        />
      </Popover>
    </div>
  );
}

GanttActionCellRenderer.propTypes = {
  store: PropTypes.shape({
    getState: PropTypes.func,
  }),
  params: PropTypes.shape({}),
  task: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    text: PropTypes.string,
    _original: PropTypes.shape({}),
    _dataSourceConfig: PropTypes.shape({
      addButtonProps: PropTypes.shape({
        // ButtonElement 的配置
      }),
      editButtonProps: PropTypes.shape({
        // ButtonElement 的配置
      }),
      deleteButtonProps: PropTypes.shape({
        componentProps: PropTypes.shape({
          action: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
          }),
        }),
      }),
      child: PropTypes.shape({}),
    }),
  }),
  ganttApiRef: PropTypes.shape({
    current: PropTypes.shape({
      gantt: PropTypes.shape({
        confirm: PropTypes.func,
        deleteTask: PropTypes.func,
      }),
      openDialog: PropTypes.func,
      setLoading: PropTypes.func,
    }),
  }),
  deletePromisesRef: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.instanceOf(Promise)),
  }),
  mapping: PropTypes.shape({
    parent: PropTypes.string,
  }),
  isTreeDataSource: PropTypes.bool,
  dateOptions: PropTypes.shape({}),
};

export default GanttActionCellRenderer;
