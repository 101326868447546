import { useEffect, useRef } from 'react';
import { execFormScripts } from '@icp/form-renderer-core';
import useExposedApis from './useExposedApis';

export default function useFormScript(script, formApi, isInDesign, isFetching) {
  const exposedApis = useExposedApis(formApi);

  const done = useRef(false);

  useEffect(() => {
    if (done.current || isInDesign || !script || isFetching) {
      return;
    }

    // 表单拿到数据渲染过后再执行脚本
    // js 代码只执行一次
    done.current = true;

    execFormScripts(script, exposedApis);
  }, [isInDesign, isFetching, script, exposedApis]);
}
