import { differenceBy } from 'lodash-es';
import { restApi } from '@icp/settings';

export function isFormEntityAutoLockable(formEntity /* , currentFormData */) {
  if (!formEntity) return false;
  if (!formEntity.isAutoLock) return false;

  /// 目前无上锁条件
  // 判断是否满足表单上锁条件 目前只支持 Input/Number/单选ACL/单选Select
  // if (formEntity.autoLock?.length) {
  //   const matchAutoLock = formEntity.autoLock.every((condition) => {
  //     const field = formEntity.fields?.find((f) => f.token === condition.colId);
  //     if (!field || field.multiple) {
  //       console.log(`[Auto Lock] Invalid auto lock condition:`, condition);
  //       return false;
  //     }
  //     let currentVal = get(currentFormData, condition.colId);
  //     if (Array.isArray(currentVal)) {
  //       // 需要与后端行为一致：按label匹配
  //       currentVal = currentVal[0]?.label;
  //     }
  //     return condition.values.some((v) => String(v) === String(currentVal));
  //   });
  //   if (!matchAutoLock) return false;
  // }

  return true;
}

export async function handleLockOnFieldValueChange({ oldValue, newValue, primaryDataId, field }) {
  if (!field.referencePbc || !field.referenceEntity || !field.referenceField) {
    return newValue;
  }

  if (
    ['ACL', 'SELECT'].includes(field.type) &&
    field.referencePbc &&
    field.referenceEntity &&
    field.referenceField
  ) {
    if ([...(oldValue || []), ...(newValue || [])].some((x) => x.id == null)) {
      console.log(`[Auto Lock] id is null`, oldValue, newValue);
      return newValue;
    }

    const deletedValues = differenceBy(oldValue || [], newValue || [], 'id');
    const addedValues = differenceBy(newValue || [], oldValue || [], 'id');

    const [unlockFails, lockFails] = await Promise.all([
      Promise.allSettled(
        deletedValues.map((x) =>
          restApi
            .post(`/form/api/form-entity-data-lock/unlock/form-entity-data/${x.id}`)
            .catch(() => Promise.reject(x)),
        ),
      ),
      Promise.allSettled(
        addedValues.map((x) =>
          restApi
            .post(
              `/form/api/form-entity-data-lock/lock/form-entity-data/${x.id}`,
              {},
              { params: { primaryDataId } },
            )
            .catch(() => Promise.reject(x)),
        ),
      ),
    ]).then(([task1Results, task2Results]) => {
      return [
        task1Results.filter((x) => x.status === 'rejected').map((x) => x.reason),
        task2Results.filter((x) => x.status === 'rejected').map((x) => x.reason),
      ];
    });

    const lockFailsIds = new Set(lockFails.map((x) => x.id));

    // 反选的解锁失败则仍然选中
    // 选中的上锁失败则改为未选中
    const finalValue = [...(newValue || []).filter((x) => !lockFailsIds.has(x.id)), ...unlockFails];

    return finalValue;
  }

  if (field.type === 'TEXT_BOX') {
    // 先上锁新值再解锁旧值

    let formEntityDataId = null;
    try {
      formEntityDataId = newValue;
      if (field.referenceField !== 'id') {
        formEntityDataId = await convertUniqueFieldToId({
          pbcToken: field.referencePbc,
          formEntityToken: field.referenceEntity,
          fieldToken: field.referenceField,
          value: newValue,
        });
      }
      // 转换失败或不存在则跳过
      if (formEntityDataId == null) {
        console.log(`[Auto Lock] Failed to convert unique field to id`, field, newValue);
        return newValue;
      }
      await restApi.post(
        `/form/api/form-entity-data-lock/lock/form-entity-data/${formEntityDataId}`,
        {},
        { params: { primaryDataId } },
      );
    } catch {
      console.log(`[Auto Lock] Failed to lock formEntityDataId: ${formEntityDataId}`);
      return oldValue;
    }

    try {
      formEntityDataId = oldValue;
      if (field.referenceField !== 'id') {
        formEntityDataId = await convertUniqueFieldToId({
          pbcToken: field.referencePbc,
          formEntityToken: field.referenceEntity,
          fieldToken: field.referenceField,
          value: oldValue,
        });
      }
      // 转换失败或不存在则跳过
      if (formEntityDataId == null) {
        console.log(`[Auto Lock] Failed to convert unique field to id`, field, newValue);
        return newValue;
      }
      await restApi.post(
        `/form/api/form-entity-data-lock/unlock/form-entity-data/${formEntityDataId}`,
      );
    } catch {
      console.log(`[Auto Lock] Failed to unlock formEntityDataId: ${formEntityDataId}`);
    }
    return newValue;
  }

  console.log(`[Auto Lock] Unknown value type:`, field.type);
  return newValue;
}

function convertUniqueFieldToId({ pbcToken, formEntityToken, fieldToken, value }) {
  if (!value) return null;
  return restApi
    .get(
      `/form/api/form-entity-data/${pbcToken}/${formEntityToken}/${fieldToken}/find-data-id-by-unique-field`,
      { params: { value } },
    )
    .then((x) => {
      if (x === '') return null;
      return x;
    })
    .catch(() => null);
}

export async function unlockFormEntityData(formEntityDataId) {
  if (formEntityDataId == null) return;

  console.log(`[AutoLock] Unlock formEntityId: ${formEntityDataId}`);
  // 静默解锁不报错
  await restApi
    .post(
      `/form/api/form-entity-data-lock/unlock/form-entity-data/${formEntityDataId}`,
      {},
      { skipResponseInterceptors: true },
    )
    .catch(() => {});
}
