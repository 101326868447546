export default function execFormScripts(
  script,
  {
    formApi,
    i18nApi,
    restApi,
    messageApi,
    routerApi,
    //
  },
) {
  (function bindThis() {
    // eslint-disable-next-line no-eval
    eval(script);
  }).call({
    formApi,
    i18nApi,
    restApi,
    messageApi,
    routerApi,
  });
}
