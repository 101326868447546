import './DataFilters.css';
import PropTypes from 'prop-types';
import { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input, Radio, Select, Badge, Switch } from 'antd';
import { immutablePush, immutableSet, immutableSplice, parseJSON } from '@icp/utils';

import CodeEditor from '../CodeEditor';

import { AG_FILTER_FILTER_TYPES, AG_FILTER_TYPES_UNARY } from '../AgTable/constants';

import Dialog from '../Dialog';
import Icon from '../Icon';
import { useLoadTreeData } from './utils';

export const DATA_SECURITY_OPERATOR_AND = 'AND';
export const DATA_SECURITY_OPERATOR_OR = 'OR';
export const DATA_SECURITY_OPERATOR_EQUALS = 'equals';
export const DATA_SECURITY_OPERATOR_NOT_EQUALS = 'does_not_equal';
export const DATA_SECURITY_OPERATOR_CONTAINS = 'contains';
export const DATA_SECURITY_OPERATOR_IN = 'in';
export const DATA_SECURITY_OPERATOR_TREE_SELF_OR_DESCENDANT = 'tree_self_or_descendant';

function SettingButton(props) {
  const { title, content, showDirty, badgeCount, onClick, disabled = false } = props;
  return (
    <div className="fd-setting-item">
      {title ? (
        <div className="fd-setting-label field-title">
          {title}
          {/* <span className="field-title-required">*</span> */}
        </div>
      ) : null}
      <div className="fd-setting-input">
        <Badge dot={showDirty && badgeCount == null} count={badgeCount} color="blue">
          <Button disabled={disabled} onClick={onClick}>
            {content}
          </Button>
        </Badge>
      </div>
    </div>
  );
}

SettingButton.propTypes = {
  title: PropTypes.node,
  content: PropTypes.string,
  showDirty: PropTypes.bool,
  disabled: PropTypes.bool,
  badgeCount: PropTypes.number,
  onClick: PropTypes.func,
};

const getOperatorOptions = (t) => [
  { value: 'AND', label: t('andCondition', { ns: 'icp-vendor-aggrid' }) },
  { value: 'OR', label: t('orCondition', { ns: 'icp-vendor-aggrid' }) },
];

function calcConditionCount(conditions) {
  return (
    conditions?.reduce(
      (total, x) => total + (x.conditions ? calcConditionCount(x.conditions) : 1),
      0,
    ) ?? 0
  );
}

function DataFilters(props) {
  const { value, onChange, projectToken, pbcToken, formEntityToken, formEntityId } = props;

  const { t } = useTranslation(['icp-form-designer']);

  const [open, setOpen] = useState(false);
  const [draft, setDraft] = useState(null);
  const [jsonMode, setJsonMode] = useState(false);

  const editorRef = useRef();

  const handleOpen = () => {
    setDraft({
      conditions: parseJSON(JSON.stringify(value)) || [],
    });
    setOpen(true);
  };

  const handleOk = () => {
    onChange(draft.conditions);
    setOpen(false);
  };

  return (
    <>
      <SettingButton
        disabled={!formEntityToken}
        title={t('data.dataFilters')}
        showDirty={!!value}
        badgeCount={calcConditionCount(value)}
        content={t('config')}
        onClick={handleOpen}
      />
      <Dialog
        rootProps={{
          style: { zIndex: 15 },
        }}
        className="fd-data-filters"
        open={open}
        style={{ width: '80vw', height: '80vh' }}
        disableEscapeKeyDown={true}
        disableBackdropClose={true}
        onOk={handleOk}
        onClose={() => setOpen(false)}
        title={
          <span>
            <span>{t('data.dataFilters')}</span>
            <Switch
              style={{ marginLeft: 20 }}
              checked={!jsonMode}
              checkedChildren={t('mode.visual')}
              unCheckedChildren={t('mode.json')}
              onChange={(checked) => {
                if (checked) {
                  const valueInEditor = parseJSON(editorRef.current?.getValue()) || [];
                  setDraft({ conditions: valueInEditor });
                }
                setJsonMode(!checked);
              }}
            />
          </span>
        }
      >
        {jsonMode ? (
          <CodeEditor
            id="data-filter-json-editor"
            language="json"
            ref={editorRef}
            readOnly={false}
            autoFocus={true}
          >
            {JSON.stringify(draft.conditions, null, 2)}
          </CodeEditor>
        ) : (
          <div className="fd-data-filter-row">
            <ConditionList
              value={draft}
              onChange={setDraft}
              noOperator={true}
              projectToken={projectToken}
              pbcToken={pbcToken}
              formEntityToken={formEntityToken}
              formEntityId={formEntityId}
            />
          </div>
        )}
      </Dialog>
    </>
  );
}

DataFilters.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  projectToken: PropTypes.string,
  pbcToken: PropTypes.string,
  formEntityToken: PropTypes.string,
  formEntityId: PropTypes.number,
};

export default DataFilters;

function ConditionList(props) {
  const {
    value,
    onChange,
    onDelete,
    noOperator,
    projectToken,
    pbcToken,
    formEntityToken,
    formEntityId,
    //
  } = props;

  const [fold, setFold] = useState(false);

  const { t } = useTranslation(['icp-form-designer', 'icp-vendor-aggrid']);

  const typeOptions = useMemo(() => {
    return [
      {
        value: DATA_SECURITY_OPERATOR_EQUALS,
        label: t('data-security.condition.equals'),
      },
      {
        value: DATA_SECURITY_OPERATOR_NOT_EQUALS,
        label: t('data-security.condition.does_not_equal'),
      },
      {
        value: DATA_SECURITY_OPERATOR_CONTAINS,
        label: t('data-security.condition.contains'),
      },
      {
        value: DATA_SECURITY_OPERATOR_IN,
        label: t('data-security.condition.in'),
      },
    ].filter(Boolean);
  }, [t]);
  const operatorOptions = useMemo(() => getOperatorOptions(t), [t]);

  const [data1] = useLoadTreeData({
    initialValue: [],
    projectToken,
    pbcToken,
    formEntityToken,
    formEntityId,
  });
  const onAddLine = () => {
    setFold(false);
    onChange(
      immutablePush(value, ['conditions'], {
        filterType: AG_FILTER_FILTER_TYPES[0],
      }),
    );
  };

  const onDeleteLine = (index) => {
    onChange(immutableSplice(value, ['conditions', index], 1));
  };

  const onAddGroup = () => {
    setFold(false);
    onChange(
      immutablePush(value, ['conditions'], {
        conditions: [],
        operator: 'AND',
      }),
    );
  };

  const handleChange = (keyPath, newValue) => {
    const tempNewVal = immutableSet(value, keyPath, newValue);
    onChange(tempNewVal);
  };
  return (
    <div className="df-data-filter-group">
      {!fold && value?.conditions?.length > 0 && <div className="df-data-filter-group-line" />}
      <div className="fd-data-filter-row">
        {!noOperator && (
          <Radio.Group
            optionType="button"
            options={operatorOptions}
            value={value.operator}
            onChange={(e) => handleChange(['operator'], e.target.value)}
          />
        )}
        <Button
          icon={<Icon name="oct:plus-circle" size={16} />}
          type="text"
          style={{
            color: '#1677ff',
            boxShadow: 'none',
          }}
          className="fd-data-filter-btn"
          onClick={onAddLine}
        >
          {t('data-filter.add-condition')}
        </Button>
        <Button
          icon={<Icon name="oct:plus-circle" size={16} />}
          type="text"
          style={{
            color: '#1677ff',
            boxShadow: 'none',
          }}
          className="fd-data-filter-btn"
          onClick={onAddGroup}
        >
          {t('data-filter.add-group')}
        </Button>
        <button className="icp-action-button" onClick={() => setFold((prev) => !prev)}>
          {fold ? <Icon name="oct:unfold" size={16} /> : <Icon name="oct:fold" size={16} />}
        </button>
        {onDelete && (
          <button className="icp-action-button" onClick={() => onDelete()}>
            <Icon name="oct:x" size={16} />
          </button>
        )}
      </div>
      {!fold &&
        value?.conditions?.filter(Boolean).map((item, index) => (
          <div className="fd-data-filter-row" key={index}>
            {item.conditions && (
              <ConditionList
                value={item}
                onChange={(newVal) => handleChange(['conditions', index], newVal)}
                onDelete={() => onDeleteLine(index)}
                projectToken={projectToken}
                pbcToken={pbcToken}
                formEntityToken={formEntityToken}
                formEntityId={formEntityId}
              />
            )}
            {!item.conditions && (
              <>
                <Select
                  options={data1?.treeData}
                  style={{ width: '300px' }}
                  value={item.dataField}
                  onChange={(newVal) => {
                    handleChange(['conditions', index, 'dataField'], newVal);
                  }}
                  placeholder={t('data-filter.field-placeholder')}
                />

                {item.filterType !== 'set' && (
                  <>
                    <Select
                      popupMatchSelectWidth={false}
                      options={typeOptions}
                      value={item.condition}
                      onChange={(newVal) =>
                        handleChange(['conditions', index, 'condition'], newVal)
                      }
                      placeholder={t('data-filter.operator-placeholder')}
                    />
                    {!AG_FILTER_TYPES_UNARY.includes(item.condition) && (
                      <Input
                        placeholder={t('value', { ns: 'icp-common' })}
                        value={item.value}
                        onChange={(e) =>
                          handleChange(['conditions', index, 'value'], e.target.value)
                        }
                      />
                    )}
                    {item.condition === 'inRange' && (
                      <Input
                        placeholder={t('data-filter.to-value-placeholder')}
                        value={item[item.filterType === 'date' ? 'dateTo' : 'filterTo']}
                        onChange={(e) =>
                          handleChange(
                            [
                              'conditions',
                              index,
                              item.filterType === 'date' ? 'dateTo' : 'filterTo',
                            ],
                            e.target.value,
                          )
                        }
                      />
                    )}
                  </>
                )}
                {item.filterType === 'set' && (
                  <Input
                    placeholder={t('data-filter.values-placeholder')}
                    value={item.values}
                    onChange={(e) => handleChange(['conditions', index, 'values'], e.target.value)}
                  />
                )}
                <button className="icp-action-button" onClick={() => onDeleteLine(index)}>
                  <Icon name="oct:x" size={16} />
                </button>
              </>
            )}
          </div>
        ))}
    </div>
  );
}

ConditionList.propTypes = {
  value: PropTypes.shape({
    operator: PropTypes.oneOf(['AND', 'OR']),
    conditions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  noOperator: PropTypes.bool,
  projectToken: PropTypes.string,
  pbcToken: PropTypes.string,
  formEntityToken: PropTypes.string,
  formEntityId: PropTypes.number,
};
