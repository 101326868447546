export default function composeEvent(...handlers) {
  return (...args) => {
    for (const handler of handlers) {
      handler?.(...args);
    }
  };
}

export function composeEventAsync(...handlers) {
  return (...args) => {
    return handlers.reduce(
      (task, handler) => task.finally(() => handler?.(...args)),
      Promise.resolve(),
    );
  };
}
