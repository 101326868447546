import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useMemo, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { getExtensionComponents } from '@icp/settings';
import { useParams } from 'react-router-dom';
import { resolveNestedValue } from '@icp/form-renderer-core';
import { useClassName, useExposedApis } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useFormApi, useIsInDesign } from '../FormRenderCtx';
import { useCurrentData } from '../currentDataCtx';

function useExtensionParams(extensionParams) {
  const formApi = useFormApi();
  const params = useParams();
  const currentData = useCurrentData();

  return useMemo(() => {
    const formData = formApi.getData();
    return resolveNestedValue({
      obj: extensionParams,
      currentData: currentData || formData,
      formData,
      context: formApi.getContext(),
      params,
    });
  }, [extensionParams, formApi, params, currentData]);
}

function ImportCss(props) {
  const { url, children } = props;

  return (
    <>
      {url ? <style>{`@import url("${url}");`}</style> : null}
      {children}
    </>
  );
}

ImportCss.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
};

function RemoteCommon({ jsUrl, mountParams }) {
  const ref = useRef(null);
  const exposedApis = useExposedApis();
  const [error, setError] = useState(null);

  useEffect(() => {
    let unmount = null;

    import(/* webpackIgnore: true */ jsUrl)
      .then((module) => {
        return module.default(ref.current, {
          params: mountParams,
          ...exposedApis,
        });
      })
      .then((x) => {
        unmount = x;
      })
      .catch(setError);

    return () => {
      unmount?.();
    };
  }, [jsUrl, mountParams, exposedApis]);

  return <div ref={ref}>{error ? error.message : null}</div>;
}

RemoteCommon.propTypes = {
  jsUrl: PropTypes.string,
  mountParams: PropTypes.shape({}),
};

const ExtensionWrapper = forwardRef(function ExtensionWrapper(props, ref) {
  const { keyPath, className: classNameProp, style: styleProp, componentProps = {} } = props;

  const {
    className: componentClassName,
    style: componentStyle,
    extensionId,
    // 兼容老项目只配了extensionId
    extensionType = extensionId ? 'local-react' : undefined,
    extensionParams,
    extensionJsUrl,
    extensionCssUrl,
    suppressShadow: suppressShadowProp,
  } = componentProps;

  const isInDesign = useIsInDesign();

  // 本地扩展不使用shadow方便css引用和本地打包
  // 设计模式只渲染配置因此也不使用shadow
  const suppressShadow = suppressShadowProp || !!extensionId || isInDesign;

  const ElementDecorator = useElementDecorator();

  const className = clsx(useClassName(classNameProp), useClassName(componentClassName));
  const style = { ...styleProp, ...componentStyle };

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const [shadowRoot, setShadowRoot] = useState(null);

  useEffect(() => {
    if (!suppressShadow) {
      setShadowRoot(nodeRef.current.attachShadow({ mode: 'open' }));
    }
  }, [suppressShadow]);

  const extParams = useExtensionParams(extensionParams);

  const portal = useMemo(() => {
    if (isInDesign) {
      return (
        <pre>
          <code>
            {JSON.stringify(
              {
                extensionId,
                extensionType,
                extensionParams: extParams,
                extensionJsUrl,
                extensionCssUrl,
              },
              null,
              2,
            )}
          </code>
        </pre>
      );
    }
    if (extensionType === 'local-react' && extensionId) {
      const Comp = getExtensionComponents()?.[extensionId];
      return (
        <ImportCss url={extensionCssUrl}>
          <Comp {...extParams} />
        </ImportCss>
      );
    }
    // 暂无此需求暂不支持
    // if (extensionType === 'remote-react' && extensionJsUrl) {
    //   const Comp = loadable(() => import(/* webpackIgnore: true */ extensionJsUrl));
    //   return (
    //     <ImportCss url={extensionCssUrl}>
    //       <Comp {...extParams} />
    //     </ImportCss>
    //   );
    // }
    if (extensionType === 'remote-common' && extensionJsUrl) {
      return (
        <ImportCss url={extensionCssUrl}>
          <RemoteCommon jsUrl={extensionJsUrl} mountParams={extParams} />
        </ImportCss>
      );
    }
    return null;
  }, [isInDesign, extensionType, extensionId, extensionJsUrl, extensionCssUrl, extParams]);

  return (
    <ElementDecorator keyPath={keyPath}>
      <div className={className} style={style} ref={nodeRef}>
        {suppressShadow ? portal : null}
        {!suppressShadow && portal && shadowRoot ? createPortal(portal, shadowRoot) : null}
      </div>
    </ElementDecorator>
  );
});

ExtensionWrapper.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  componentProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.shape({}),
    extensionType: PropTypes.oneOf(['local-react', 'remote-common']),
    extensionId: PropTypes.string,
    extensionParams: PropTypes.shape({}),
    extensionJsUrl: PropTypes.string,
    extensionCssUrl: PropTypes.string,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ExtensionWrapper.displayName = 'Extension';

export default withFieldWrapper(ExtensionWrapper);
