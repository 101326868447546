import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { selectFieldValueI18n } from '@icp/form-renderer-core';
import { composeEventAsync } from '@icp/utils';
import { useSelector } from '../../../store';
import { ValueLanguageSelectMaterial } from '../../../ValueLanguageSelect';
import InputClearButton from './InputClearButton';

function I18nInputSingle(props) {
  const {
    id,
    languageOp,
    value: valueProp,
    onChange,
    onBlur,
    readonly,
    allowClear,
    onClear,
    ...other
    //
  } = props;

  const valueI18n = useSelector(selectFieldValueI18n(id, languageOp.value));
  const [value, setValue] = useState(valueI18n);

  useEffect(() => {
    setValue(valueI18n);
  }, [valueI18n]);

  return (
    <TextField
      {...other}
      fullWidth={true}
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      onBlur={composeEventAsync(() => onChange(value, languageOp.value), onBlur)}
      InputProps={{
        readOnly: readonly,
        startAdornment: (
          <InputAdornment position="start">
            <ValueLanguageSelectMaterial value={languageOp.value} readonly={true} />
          </InputAdornment>
        ),
        endAdornment:
          !readonly && valueI18n && allowClear ? <InputClearButton onClear={onClear} /> : null,
      }}
    />
  );
}

I18nInputSingle.propTypes = {
  id: PropTypes.string,
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  readonly: PropTypes.bool,
  allowClear: PropTypes.bool,
  onClear: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default I18nInputSingle;
