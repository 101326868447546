import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import Popover from '../../Popover';
import SearchBox from '../../SearchBox';
import { getFilterType, getFilterTypeIcon } from './utils';

function AddFilter(props) {
  const { columnDefs, pinnedFilter, filterModel, onChange } = props;

  const { t } = useTranslation(['icp-components']);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const anchorEl = useRef(null);

  const otherColumns = useMemo(() => {
    return columnDefs
      .filter((colDef) => {
        return (
          colDef.colId && // 统一要求必须要有 colId 更好统一处理代码，没有 colId 的不允许过滤
          colDef.headerName && // 没有 headerName 显示也没意义，也不允许过滤
          colDef.filter &&
          !filterModel.find((model) => model.colId === colDef.colId) &&
          !(Array.isArray(pinnedFilter) && pinnedFilter.includes(colDef.colId))
        );
      })
      .sort((a, b) => `${a.headerName}`.localeCompare(`${b.headerName}`));
  }, [columnDefs, filterModel, pinnedFilter]);

  if (!otherColumns.length) {
    return null;
  }

  return (
    <>
      <button
        className="icp-table-filter-add-button icp-action-button"
        onClick={() => setOpen(true)}
        ref={anchorEl}
      >
        <Icon name="oct:plus" size={16} />
        {t('toolbar.filter.add-filter')}
      </button>
      <Popover
        className="icp-table-column-select"
        anchorEl={anchorEl.current}
        open={open}
        onClose={() => {
          setOpen(false);
          setSearchText('');
        }}
      >
        <SearchBox
          open={true}
          suppressSpeech={true}
          autoFocus={true}
          debounce={false}
          placeholder={t('toolbar.filter.filter-by')}
          onSearch={setSearchText}
        />
        <div className="icp-table-menu icp-thin-scrollbar">
          {open &&
            otherColumns
              .filter((colDef) => {
                return !searchText || colDef.headerName?.toLowerCase().includes(searchText);
              })
              .map((colDef) => {
                const filterType = getFilterType(colDef);
                const icon = getFilterTypeIcon(filterType);

                return (
                  <div
                    key={colDef.colId}
                    className="item icp-clickable"
                    onClick={() => {
                      setOpen(false);
                      setSearchText('');
                      onChange(colDef.colId);
                    }}
                  >
                    {icon}
                    {colDef.headerName}
                  </div>
                );
              })}
        </div>
      </Popover>
    </>
  );
}

AddFilter.propTypes = {
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  filterModel: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

export default AddFilter;
