import { Select } from 'antd';
import { selectContext, selectInitialValues } from '@icp/form-renderer-core';
import { restApi } from '@icp/settings';
import { getTFunc } from '@icp/i18n';

export async function chooseChangeRequest({ modal, store, schema }) {
  const isMasterData = schema[Symbol.for('formEntity')]?.isMasterData;
  if (!isMasterData) throw Error('Not master data');
  const hasChangeRequestPbc = selectContext(store.getState()).hasChangeRequestPbc;
  if (!hasChangeRequestPbc) throw Error('No change request pbc');
  const formEntityDataReferenceId = selectInitialValues(store.getState())?.id;

  const t = getTFunc(['icp-form-renderer', 'icp-common']);

  const crList = await restApi
    .get(`/flow/api/flow-rest/system-get-all-cr-package-flow`, {
      params: { formEntityDataReferenceId },
      skipResponseInterceptors: true,
    })
    .then((res) => res.changeRequest || []);

  if (!crList.length) throw Error('No change request');

  return new Promise((resolve, reject) => {
    let chosenCR = null;

    const handle = modal.confirm({
      title: t('change-request.dialog-title', { ns: 'icp-form-renderer' }),
      content: (
        <div>
          <Select
            allowClear={true}
            style={{ width: '100%' }}
            placeholder={t('change-request.placeholder', { ns: 'icp-form-renderer' })}
            popupMatchSelectWidth={false}
            fieldNames={{ label: 'name', value: 'id' }}
            options={crList}
            onChange={(v) => {
              chosenCR = v;
              handle.update({ okButtonProps: { disabled: !chosenCR } });
            }}
          />
        </div>
      ),
      cancelText: t('skip', { ns: 'icp-common' }),
      okButtonProps: { disabled: !chosenCR },
      onOk: () => {
        resolve(chosenCR);
      },
      onCancel: () => {
        reject(Error('cancel'));
      },
    });
  });
}

export function submitChosenChangeRequest({ changeRequestId, formEntityDataReferenceId }) {
  if (changeRequestId == null || formEntityDataReferenceId == null) return;

  restApi.post(
    `/flow/api/flow-rest/save-change-request-detail`,
    {
      changeRequestId,
      formEntityDataReferenceId,
    },
    {
      skipResponseInterceptors: true,
    },
  );
}
