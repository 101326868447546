import PropTypes from 'prop-types';
import { I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT } from '@icp/form-renderer-core';
import { forwardRef } from 'react';
import I18nInputSingle from './I18nInputSingle';
import I18nInputMulti from './I18nInputMulti';
import NoI18nInput from './NoI18nInput';
import { useValueLanguageOptions } from '../../../hooks';

const InputSupportI18n = forwardRef(function InputSupportI18n(props, ref) {
  const { allowI18nValue, i18nInputLayout, ...other } = props;

  const languageOptions = useValueLanguageOptions();

  if (!allowI18nValue) {
    return <NoI18nInput {...other} ref={ref} />;
  }

  if (i18nInputLayout === I18N_VALUE_COLLAPSED) {
    return <I18nInputMulti {...other} ref={ref} />;
  }

  if (i18nInputLayout === I18N_VALUE_FLAT) {
    return languageOptions.map((op) => (
      <I18nInputSingle {...other} key={op.value} languageOp={op} />
    ));
  }

  return 'Invalid i18nInputLayout config';
});

InputSupportI18n.propTypes = {
  allowI18nValue: PropTypes.bool,
  i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
};

export default InputSupportI18n;
