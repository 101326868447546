export const contextInitialState = {
  pbcToken: null, // pbc router basename
  projectToken: null,
  userProfile: null,
  userPermissionMap: null,
  schemaMode: null, // true: schema mode, false: code generation mode
  enableAutoLock: null, // layout级的自动锁定开关，标识是否是编辑页
  formEntity: null,
  dataSource: {}, // data source 配置了 alias 会放到这里，例如 DataWrapper 组件
  formEntityCache: null, // Map<pbcToken, Map<formEntityToken, formEntity>> 可编辑表格数据源formEntity缓存
};

export const fileStorageState = {
  permissionLoading: false,
  sessionId: null,
  baseUrl: null,
  authentication: null,
};

export const permissionInitialState = {
  isLoading: false,
  isError: false,
  error: null,

  permissions: [],
  permissionsByCategory: [],
};

export const formDataInitialState = {
  disableDirty: false,
  isFetching: false,
  isValidating: false,
  isSubmitting: false,
  isError: false,
  error: null, // 表单级错误
  // 是否通过 ui 组件人为修改过数据，作为判断 dirty 的前置条件。dirty 目前用作阻止用户离开当前页面，所以不是通过 ui 修改的数据不计算 dirty。
  uiChangedData: false,

  initialErrors: {},
  initialWarnings: {},
  initialTouched: {},
  initialValues: {},

  errors: {}, // 字段级错误
  warnings: {},
  touched: {},
  values: {},

  validatorDelegates: {},

  currentFieldId: null,
};
