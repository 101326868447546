import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { message } from '@icp/settings';
import { resolveVariablePattern, composeEvent } from '@icp/utils';
import { useEventCallback } from '@icp/hooks';
import { HELPER_TEXT_TYPES, helpersIsEmpty, submitRequest } from '@icp/form-renderer-core';
import { useElementDecorator, useFormApi } from '../../FormRenderCtx';
import { useDispatch } from '../../store';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import { useCurrentData } from '../../currentDataCtx';
import SwitchUI from './SwitchUI';

const SwitchElement = forwardRef(function SwitchElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    value: valueProp,
    style,
    componentProps = {},
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const { size, action = {}, style: switchStyle, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();
  const params = useParams();
  const dispatch = useDispatch();
  const formApi = useFormApi();
  const currentData = useCurrentData();

  const [switchLoading, setSwitchLoading] = useState(false);

  // 仅兼容显示后端返回的字符串型true false, 编辑时仍使用布尔型
  const value = /^true$/i.test(`${valueProp}`);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const doRequest = useEventCallback(() => {
    setSwitchLoading(true);

    const { method, url: urlPattern } = action;
    const formData = formApi.getData();
    const url = resolveVariablePattern({
      currentData: currentData || formData,
      formData,
      params,
      pattern: urlPattern,
    });

    dispatch(submitRequest({ method, url, data: currentData })).then(
      () => {
        setSwitchLoading(false);
        if (value && action.openMessage) {
          message.success(action.openMessage);
        }

        if (!value && action.closeMessage) {
          message.success(action.closeMessage);
        }
        formApi.refresh();
      },
      () => {
        setSwitchLoading(false);
      },
    );
  });

  const handleChange = composeEvent(
    (newValue) => onChange(newValue),
    onTouchChanged,
    () => {
      if (action.type === 'request') {
        setTimeout(() => {
          // wait react mount to update formData
          doRequest();
        }, 16);
      }
    },
    //
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'switch-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <SwitchUI
          {...props}
          componentProps={{ ...otherComponentProps, className: classNameComp }}
          loading={switchLoading}
          value={value}
          onChange={handleChange}
        />
      </div>
    </ElementDecorator>
  );
});

SwitchElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * Switch 的尺寸
     */
    size: PropTypes.oneOf(['default', 'small']),
    /**
     * Switch 点击的时候发生的动作
     */
    action: PropTypes.shape({
      /**
       * 动作的类型
       */
      type: PropTypes.oneOf(['request']),
      /**
       * 当 type 等于 `request` 的时候，发送请求的方法
       */
      method: PropTypes.oneOf(['get', 'post', 'put', 'delete']),
      /**
       * 当 type 等于 `request` 的时候，发送请求的地址
       */
      url: PropTypes.string,
      /**
       * 当 type 等于 `request` 的时候，并且 Switch 操作是 "打开" 发送请求成功的提示信息
       */
      openMessage: PropTypes.string,
      /**
       * 当 type 等于 `request` 的时候，并且 Switch 操作是 "关闭" 发送请求成功的提示信息
       */
      closeMessage: PropTypes.string,
    }),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  value: PropTypes.oneOfType([
    PropTypes.bool,
    // 仅兼容显示后端返回的字符串型true false, 编辑时仍使用布尔型
    PropTypes.oneOf(['true', 'false']),
  ]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
SwitchElement.displayName = 'Switch';

export default withFieldWrapper(SwitchElement);
