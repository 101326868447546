import PropTypes from 'prop-types';
import { forwardRef, isValidElement, useEffect, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Empty } from 'antd';
import { FIELD_ENUMS, isField } from '@icp/form-schema';
import { Loading } from '@icp/components';
import { shouldTranslateByDefault } from '@icp/settings';
import RecursionRenderer from '../RecursionRenderer';
import { MultiDataSourceTypes, SingleDataSourceTypes } from '../propTypes';
import {
  useClassName,
  useDataSourceSupportLazy,
  useDisplayValue,
  useVariablePattern,
} from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { CurrentDataProvider } from '../currentDataCtx';

const ListElement = forwardRef(function ListElement(props, ref) {
  const {
    children,
    keyPath,
    id,
    valueField,
    className: classNameProp,
    style,
    fields = [],
    componentProps = {},
  } = props;

  const {
    itemField, // deprecated
    direction = 'column',
    wrap = false,
    empty = false,
    gap,
    style: wrapperStyle,
    itemStyle, // deprecated
    itemProps,
    dataSource,
    dataUrl,
    dataFilters,
    sortModel,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
    multiDataSource,
    multiDataSourceFlat = false,
    lazyLoading = false,
    cacheBlockSize = 30,
    searchText: searchTextProp,
    defaultValues, // deprecated
  } = componentProps;

  const searchText = useVariablePattern(searchTextProp) || undefined;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      // refresh: () => {}
    }),
    [],
  );

  const valueProp = useDisplayValue(id, valueField);
  const { loading, dataFetched, moreLoading, hasMore, loadMore } = useDataSourceSupportLazy({
    skip: isInDesign || valueProp,
    // lazyLoading 只支持但数据源
    lazy: lazyLoading && !multiDataSource,
    searchText,
    debounceLeading: !searchText,
    cacheBlockSize,
    defaultValues, // deprecated
    // single data source config
    dataSource,
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
    // multi config
    multiDataSource,
    multiDataSourceFlat,
  });

  const value = isInDesign ? [{}] : valueProp || dataFetched;

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (itemField) {
        console.warn(`'itemField' is deprecated, use 'fields' instead`);
      }
      if (itemStyle) {
        console.warn(`'itemStyle' is deprecated, use 'itemProps' instead`);
      }
    }
  }, [itemField, itemStyle]);

  const noChildren = !itemField && !children && !fields?.length;

  const handleScroll = (event) => {
    if (!lazyLoading) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop - clientHeight < 32 * 2;
    if (isBottom && hasMore && !moreLoading) {
      loadMore();
    }
  };

  return (
    <ElementDecorator keyPath={keyPath}>
      <div
        className={clsx(
          'list-element form-element',
          `direction-${direction}`,
          {
            'no-children': noChildren,
            wrap,
          },
          className,
          classNameComp,
        )}
        style={{ gap, ...style, ...wrapperStyle }}
        onScroll={lazyLoading ? handleScroll : undefined}
        ref={nodeRef}
      >
        {loading ? <Loading /> : null}
        {!loading && empty && value?.length === 0 && <Empty {...empty} />}
        {!loading
          ? (value || []).map((itemValue, itemIndex) => {
              return (
                <CurrentDataProvider key={itemValue.id || itemIndex} value={itemValue}>
                  <div
                    className="list-item"
                    {...itemProps}
                    style={{ ...itemStyle, ...itemProps?.style }}
                  >
                    {children || (
                      <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />
                    )}
                    {/* 支持老项目 deprecated 的 itemField。没有在上面统一 merge 到 fields 渲染，因为在 designer 里需要保持真实 schema 里的 keyPath 进行操作 */}
                    {/* 当移除对 itemField 的支持的时候可以删掉如下代码。 */}
                    {isValidElement(itemField)
                      ? itemField
                      : itemField &&
                        isField(itemField) && (
                          <RecursionRenderer
                            fields={[itemField]}
                            keyPath={keyPath.concat(['componentProps', 'itemField'])}
                          />
                        )}
                  </div>
                </CurrentDataProvider>
              );
            })
          : null}
        {lazyLoading && dataFetched && moreLoading ? <Loading size={16} delayed={false} /> : null}
      </div>
    </ElementDecorator>
  );
});

ListElement.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueField: PropTypes.string,
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * @deprecated
     * List 里每个元素的 json schema，支持使用所有 form 的元素
     */
    itemField: PropTypes.shape({
      component: PropTypes.oneOf(FIELD_ENUMS),
      componentProps: PropTypes.shape({}),
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    /**
     * 元素的排版方向，等同 css `flex` 的 flex-direction 属性
     */
    direction: PropTypes.oneOf(['row', 'column']),
    /**
     * 内容超过宽度或者高度的时候是否换行，等同 css `flex` 的 flex-wrap 属性
     */
    wrap: PropTypes.bool,
    /**
     * 等同 css `flex` 的 gap 属性
     */
    gap: PropTypes.number,
    /**
     * 当无数据的时候显示的占位符，antd 的 Empty 的属性
     * @default false
     */
    empty: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        // `Empty` props, see https://ant.design/components/empty-cn/#API
      }),
    ]),
    /**
     * @deprecated
     */
    itemStyle: PropTypes.shape({}),
    /**
     * Props apply to every list item
     */
    itemProps: PropTypes.shape({}),
    /**
     * @deprecated
     */
    defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
    ...SingleDataSourceTypes,
    ...MultiDataSourceTypes,
    /**
     * 是否开启懒加载
     */
    lazyLoading: PropTypes.bool,
    /**
     * 懒加载每次请求的数据量
     */
    cacheBlockSize: PropTypes.number,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ListElement.displayName = 'List';

export default withFieldWrapper(ListElement);
