import { joinPaths, toMap } from '@icp/utils';
import flattenRoutes from './flattenRoutes';
import { generalRoutes } from './generalRoutes';

// !important，以下函数里不能对 context 进行任何的访问以及解构操作，只能保持原样传递给子组件，否则会丢失 context 的属性变化。
export default function composeAllRoutesConfig({
  context,
  appConfig,
  appRoutes,
  otherRoutes,
  pbcList,
}) {
  const allRoutes = flattenRoutes(appRoutes, pbcList);

  const { otherRoutesNoNeedAuth = [], otherRoutesNeedAuth = [] } = toMap(
    (x) => (x.noNeedAuth === true ? 'otherRoutesNoNeedAuth' : 'otherRoutesNeedAuth'),
    ({ noNeedAuth, ...rest }) => [rest],
    (_, __, a, b) => [...a, ...b],
  )(otherRoutes || []);

  const icpRoutes = (routes, basename, templateName) => {
    return Object.entries(routes).map(([path, routeConfig]) => {
      const {
        isHomePage,
        accessPermissionPredicate,
        redirect,
        forwardSubPath,
        pbcToken: pbcTokenInRouteConfig,
        ...otherConfig
      } = routeConfig;

      path = basename ? joinPaths([basename, path]) : path;

      if (redirect) {
        const { to, replace } = redirect;
        return {
          path,
          component: 'Redirect',
          props: { to, replace, context },
        };
      }

      if (isHomePage) {
        return {
          path,
          component: 'HomePage',
          props: {
            name: templateName,
            appConfig,
            pageTitle: routeConfig.pageTitle,
          },
        };
      }

      let pbcToken;
      if (basename) {
        pbcToken = basename.startsWith('/mobile/') ? basename.split('/mobile/')[1] : basename;
      } else if (pbcTokenInRouteConfig) {
        // 支持在整个网站全局的 routers.json 里指定 pbcToken
        pbcToken = pbcTokenInRouteConfig;
      }

      return {
        path: forwardSubPath ? `${path}/*` : path,
        component: 'PageRenderer',
        props: {
          // set key to force use new component every page
          key: path,
          ...otherConfig,
          path,
          forwardSubPath,
          // 不能使用 { ...context, pbcToken }，因为 IcpApp 里没有响应 context 对象来重新调用 makeIcpRouter，
          // 解构的话会导致 context 的属性不会刷新。等到 PageRenderer 真正运行的时候再解构就没有任何问题了。
          context,
          pbcToken,
          isMainPageRenderer: true,
        },
        accessPermissionPredicate,
      };
    });
  };

  return [
    { path: '*', component: 'Page404', props: { name: appConfig.template, appConfig } },
    { path: '/login', component: 'Login', props: { name: appConfig.template, appConfig } },
    {
      path: '/login/basic',
      component: 'BasicLogin',
      props: { name: appConfig.template, appConfig },
    },
    {
      path: '/reset-password',
      component: 'ResetPassword',
      props: { name: appConfig.template, appConfig },
    },
    ...otherRoutesNoNeedAuth,
    {
      path: '/flow-flame',
      component: 'FlowFlame',
      needAuth: true,
      accessPermissionPredicate: {
        hasAllOf: ['!!CANNOT_ACCESS_UNLESS_SUPER_ADMIN!!'],
      },
    },
    {
      // Desktop
      path: '/',
      component: 'TemplateRender',
      props: {
        name: appConfig.template,
        appConfig,
        routes: allRoutes,
      },
      children: [
        // 不能判断有没有 processMiningPbcList 再生成路由，否则直接刷新页面会导致 404 页面不走 AuthWrapper 永远无权限 404
        {
          path: '/mining/process-mining',
          component: 'ProcessMining',
          props: { pbcList, context },
        },
        {
          path: '/mining/data-mining',
          component: 'DataMining',
          props: { pbcList, context },
        },
        {
          path: '/connector',
          component: 'PageConnector',
          props: { pbcList, context },
        },
        ...icpRoutes(appRoutes.desktop, null, appConfig.template),
        ...pbcList
          .flatMap((pbc) => {
            if (!pbc.routes) return null;

            return icpRoutes(
              { ...generalRoutes.desktop, ...pbc.routes.desktop },
              pbc.token,
              appConfig.template,
            );
          })
          .filter(Boolean),
        ...otherRoutesNeedAuth,
      ].filter(Boolean),
    },
    {
      // Mobile
      path: '/mobile',
      component: 'TemplateRender',
      props: {
        name: appConfig.mobileTemplate,
        appConfig,
        routes: allRoutes,
      },
      children: [
        ...icpRoutes(appRoutes.mobile, null, appConfig.mobileTemplate),
        ...pbcList
          .flatMap((pbc) => {
            if (!pbc.routes) return null;

            const basename = joinPaths(['/mobile', pbc.token]);

            return icpRoutes(
              { ...generalRoutes.mobile, ...pbc.routes.mobile },
              basename,
              appConfig.mobileTemplate,
            );
          })
          .filter(Boolean),
      ],
    },
  ];
}
