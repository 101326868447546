import { useEffect, useMemo, useState } from 'react';
import { restApi } from '@icp/settings';
import { EvalWorker, randomNumber } from '@icp/utils';
import {
  asyncFieldSetInitialValue,
  selectIsFetching,
  selectValues,
  selectEnableAutoLock,
} from '@icp/form-renderer-core';
import { useDataFilters, useVariablePattern } from '../../hooks';
import { useDispatch, useSelector, useStore } from '../../store';
import { resolveDataUrl } from './utils';
import { useIsInDesign } from '../../FormRenderCtx';

export default function useEditableTableAsyncValue({
  refresh,
  id,
  dataUrlProp,
  defaultValueDataUrlProp,
  dataFilters,
  transformDataResponse,
  transformDefaultValueResponse,
  orderField,
}) {
  const isInDesign = useIsInDesign();
  const store = useStore();
  const dispatch = useDispatch();
  const isFormFetching = useSelector(selectIsFetching);

  const skip = isInDesign;

  // TODO, change to conditional
  const dataUrlResolved = useVariablePattern(dataUrlProp, true);
  const dataUrl = useMemo(() => {
    return resolveDataUrl({ store, dataUrlResolved, id });
  }, [dataUrlResolved, id, store]);

  const defaultValueDataUrl = useVariablePattern(defaultValueDataUrlProp, true);
  const outerFilterModel = useDataFilters(dataFilters);

  // fetchedRowData 包含 defaultRowData
  const [data, setData] = useState({
    fetching: !skip && (!!dataUrl || !!defaultValueDataUrl),
    fetchedRowData: null,
    fetchedDefaultRowData: null,
  });

  useEffect(() => {
    if (skip || (!dataUrl && !defaultValueDataUrl)) {
      return () => {};
    }

    const controller = new AbortController();
    const { signal } = controller;
    const enableAutoLock = selectEnableAutoLock(store.getState());
    const config = {
      signal,
      params: {
        enable_auto_lock: enableAutoLock ? true : undefined,
        payload: JSON.stringify({ filterModel: outerFilterModel }),
      },
    };

    Promise.all([
      dataUrl ? restApi.get(dataUrl, config) : { results: [] },
      defaultValueDataUrl ? restApi.get(defaultValueDataUrl, config) : { results: [] },
    ])
      .then(([res1, res2]) => {
        return [
          res1?.results || [],
          (res2?.results || []).map((item) => {
            // 删除 id，没有 id 的行会在提交的时候被放到 changedData.inserted 中
            item.id = `inserted-${Date.now()}-${randomNumber()}`;
            return item;
          }),
        ];
      })
      .then(([results1, results2]) => {
        return Promise.all([
          !transformDataResponse
            ? results1
            : EvalWorker.shared().execEval(results1, transformDataResponse, {
                params: {
                  context: store.getState().context,
                  formData: selectValues(store.getState()),
                },
              }),
          !transformDefaultValueResponse
            ? results2
            : EvalWorker.shared().execEval(results2, transformDefaultValueResponse, {
                params: {
                  context: store.getState().context,
                  formData: selectValues(store.getState()),
                },
              }),
        ]);
      })
      .then(([results1, results2]) => {
        const finalResults = [...results1, ...results2];
        if (orderField) {
          finalResults.sort((a, b) => a[orderField] - b[orderField]);
        }
        setData({
          fetching: false,
          fetchedRowData: finalResults,
          fetchedDefaultRowData: results2,
        });
      });

    return () => {
      controller.abort();
    };
  }, [
    skip,
    refresh,
    dataUrl,
    defaultValueDataUrl,
    orderField,
    outerFilterModel,
    store,
    transformDataResponse,
    transformDefaultValueResponse,
  ]);

  // 通过 dataUrl 获取到的 value，放回 store
  useEffect(() => {
    // 等 form fetching 结束过后再存进 store，保证通过 dataUrl 获取到的值优先级更高
    if (!isFormFetching && data.fetchedRowData) {
      dispatch(asyncFieldSetInitialValue({ keyPath: id, value: data.fetchedRowData }));
    }
  }, [dispatch, data, id, isFormFetching]);

  return { fetching: data.fetching };
}
