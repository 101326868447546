import { selectInitialValues } from './formDataSlice';
import { unlockFormEntityData } from '../../utils/autoLockUtils';

export async function unlock(state) {
  const formEntityData = selectInitialValues(state);
  // eslint-disable-next-line no-underscore-dangle
  if (formEntityData._lock?.locked) {
    await unlockFormEntityData(formEntityData.id);
  }
}
