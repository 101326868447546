import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { restApi } from '@icp/settings';
import { withTranslatedProps } from '@icp/form-renderer';
import { useAppContext } from '@icp/app';
import {
  FORM_TYPE_ENTITY,
  FORM_TYPE_ENTITY_VIEW,
  FORM_TYPE_EXTERNAL,
  FORM_TYPE_FLOW_APPROVAL,
  FORM_TYPE_FLOW_FORM,
  FORM_TYPE_IFRAME,
  FORM_TYPES,
} from '@icp/page-renderer-core';
import { useForkRef, useLeaveConfirm } from '@icp/hooks';
import ExternalFormRenderer from './ExternalFormRenderer';
import FlowFormRenderer from './FlowFormRenderer';
import FormEntityRenderer from './FormEntityRenderer';
import FormEntityDefaultViewRenderer from './FormEntityDefaultViewRenderer';
import DefaultFormRenderer from './DefaultFormRenderer';

const PageRenderer = forwardRef(function PageRender(props, ref) {
  const {
    pageTitle: routeConfigPageTitle,
    formType,
    iframeSrc,
    path,
    forwardSubPath,
    context: contextProp,
    pbcToken,
    isMainPageRenderer,
  } = props;

  const { t } = useTranslation(['icp-common']);
  const location = useLocation();
  const appContextO = useAppContext();
  // 是否有Change Request PBC
  const hasChangeRequestPbc = appContextO.pbcList.some(
    (pbc) => pbc.token === 'system-change-request',
  );
  // 用 ref 为了 useEffect 的 hook 不响应 appContext 的变化
  const appContext = useRef();
  appContext.current = appContextO;

  const timeout = useRef(null);
  const currentFormApi = useRef(null);

  const isDirty = useCallback(() => {
    if (typeof currentFormApi.current?.isDirty !== 'function') {
      return false;
    }
    return currentFormApi.current.isDirty();
  }, []);

  useLeaveConfirm({ block: isDirty });

  useEffect(() => {
    if (!isMainPageRenderer) {
      return;
    }

    appContext.current.setRedirecting(true);
  }, [isMainPageRenderer, location.pathname]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      // PageRenderer 卸载，跨 pbc 跳转、页面跳转到用户 App.js 里自定义的路由等，
      // 目前只有在 general route 匹配到同一个规则，只是 formEntityToken layoutToken 等发生变化的时候才不会卸载。
      appContext.current.setRedirecting(false);
    };
  }, []);

  useEffect(() => {
    if (!isMainPageRenderer) {
      return () => {};
    }

    appContext.current.setPbcToken(pbcToken);
    appContext.current.setPageTitle(routeConfigPageTitle);

    return () => {
      // 清空一个面包屑等待 FormRenderer 渲染过后更新新的面包屑
      appContext.current.setPbcToken(null);
      appContext.current.setPageTitle(null);
    };
  }, [isMainPageRenderer, location.pathname, pbcToken, routeConfigPageTitle]);

  const handleFormApiChanged = useCallback(
    (formApi) => {
      currentFormApi.current = formApi;

      if (!isMainPageRenderer) {
        return;
      }

      if (!formApi || !formApi.stage) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          appContext.current.setRedirecting(false);
        }, 16);
        return;
      }

      clearTimeout(timeout.current);

      if (formApi.error) {
        appContext.current.setRedirecting(false);
        return;
      }

      if (!formApi.on) {
        return;
      }

      formApi.on('firstRendered', () => {
        const formTitle = formApi.getTitle();
        // 如果没 title 则不设置，否则会清空掉 routeConfigPageTitle
        if (formTitle) {
          appContext.current.setPageTitle(formApi.getTitle());
        }

        const { fullWidth } = formApi.getProps();
        if (typeof fullWidth === 'boolean') {
          appContext.current.setFullWidth(fullWidth);
        } else {
          appContext.current.setFullWidth(undefined);
        }

        appContext.current.setRedirecting(false);
      });
    },
    [isMainPageRenderer],
  );

  const handleRef = useForkRef(ref, handleFormApiChanged);

  const context = useMemo(
    () => ({
      ...contextProp,
      pbcToken,
      hasChangeRequestPbc,
      isMainFormRenderer: isMainPageRenderer,
    }),
    [contextProp, pbcToken, hasChangeRequestPbc, isMainPageRenderer],
  );

  const childProps = { ...props, key: location.pathname, context, ref: handleRef };

  if (formType && !FORM_TYPES.includes(formType)) {
    console.error(`Error: Unknown formType: ${formType}`);
    return (
      <Result
        title={t('error-occurred')}
        subTitle={`Unknown formType: ${formType}`}
        status="error"
      />
    );
  }

  if (formType === FORM_TYPE_FLOW_FORM || formType === FORM_TYPE_FLOW_APPROVAL) {
    return <FlowFormRenderer {...childProps} />;
  }

  if (formType === FORM_TYPE_ENTITY) {
    return <FormEntityRenderer {...childProps} />;
  }

  if (formType === FORM_TYPE_ENTITY_VIEW) {
    return <FormEntityDefaultViewRenderer {...childProps} />;
  }

  // TODO, 改名成 COMPONENT
  if (formType === FORM_TYPE_EXTERNAL) {
    return <ExternalFormRenderer {...childProps} />;
  }

  if (formType === FORM_TYPE_IFRAME) {
    let src = iframeSrc;
    if (forwardSubPath && location.pathname !== path && location.pathname !== `${path}/`) {
      src = location.pathname.substring(path.length) + location.search + location.hash;
    }
    return (
      <iframe
        className="iframe-renderer"
        title={routeConfigPageTitle}
        src={src}
        onLoad={restApi.auth.restartRefreshTokenTask}
        height="100%"
      />
    );
  }

  return <DefaultFormRenderer {...childProps} />;
});

PageRenderer.propTypes = {
  pageTitle: PropTypes.string,
  formType: PropTypes.oneOf(FORM_TYPES),
  iframeSrc: PropTypes.string,
  path: PropTypes.string,
  forwardSubPath: PropTypes.bool,
  context: PropTypes.shape({}),
  pbcToken: PropTypes.string,
  // 1. 决定下面调用的 FormRenderer 是否是 isMainFormRenderer
  // 2. 决定是否设置 App 整体重定向状态、pageTitle、fullWidth 等状态
  isMainPageRenderer: PropTypes.bool,
};

export default withTranslatedProps({ underFormRenderer: false })(PageRenderer);
