export const FORM_DESIGNER_WORKSPACE_MODE = {
  design: 'design',
  split: 'split',
  code: 'code',
  preview: 'preview',
};

export const FORM_DESIGNER_DEVICE_MODE = {
  desktop: 'desktop',
  mobile: 'mobile',
};

export const FIELD_TYPE_FORM = 'FIELD_TYPE_FORM';
// export const FIELD_TYPE_ELEMENT = 'FIELD_TYPE_ELEMENT';
// export const FIELD_TYPE_LAYOUT = 'FIELD_TYPE_LAYOUT';
export const FIELD_TYPE_TABLE_COLUMN = 'FIELD_TYPE_TABLE_COLUMN';
export const FIELD_TYPE_STEP = 'FIELD_TYPE_STEP';
export const FIELD_TYPE_COLLAPSE_ITEM = 'FIELD_TYPE_COLLAPSE_ITEM';
export const FIELD_TYPE_TAB = 'FIELD_TYPE_TAB';

// consistent with field length restriction in database
export const FIELD_VALUE_MAX_LENGTH = 200;

export const DATA_SECURITY_SCOPE_SELF = 1;
export const DATA_SECURITY_SCOPE_DEPT = 2;
export const DATA_SECURITY_SCOPE_DEPT_AND_BELOW = 3;
export const DATA_SECURITY_SCOPE_ORG = 4;
export const DATA_SECURITY_OPERATOR_AND = 'AND';
export const DATA_SECURITY_OPERATOR_OR = 'OR';
export const DATA_SECURITY_OPERATOR_EQUALS = 'equals';
export const DATA_SECURITY_OPERATOR_NOT_EQUALS = 'does_not_equal';
export const DATA_SECURITY_OPERATOR_CONTAINS = 'contains';
export const DATA_SECURITY_OPERATOR_IN = 'in';
export const DATA_SECURITY_OPERATOR_TREE_SELF_OR_DESCENDANT = 'tree_self_or_descendant';

export const SPLIT_LAYOUT_SETTING_KEY = 'form-designer.workspace-split-view-layout';

export const URL_QUERY_PARAM_SELECT_KEY_PATH = 'selectKeyPath';
export const URL_QUERY_PARAM_SELECT_LAYOUT = 'selectLayout';

export const ICP_DOC_DOMAIN = 'https://duxing.cn';
