import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useClassName, useDisplayValue, useVariablePattern } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

const IFrameElement = forwardRef(function IFrameElement(props, ref) {
  const { keyPath, id, valueField, className: classNameProp, style, componentProps = {} } = props;

  const { src: srcProp, style: componentStyle, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const value = useDisplayValue(undefined, valueField);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const src = useVariablePattern(srcProp) || value;

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src={isInDesign ? null : src}
        className={clsx('iframe-element form-element', className, classNameComp)}
        style={{ ...style, ...componentStyle }}
        ref={nodeRef}
        {...otherComponentProps}
      />
    </ElementDecorator>
  );
});

IFrameElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 图片的宽度
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 图片的高度
     */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 图片的地址
     */
    src: PropTypes.string,
    /**
     * 图片的 alt
     */
    alt: PropTypes.string,
    /**
     * @deprecated
     */
    tooltipByValue: PropTypes.shape({}),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
IFrameElement.displayName = 'Image';

export default withFieldWrapper(IFrameElement);
