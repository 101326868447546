import { resolveDataFilters } from '@icp/form-renderer-core';
import { parseJSON } from '@icp/utils';
import {
  isACLCellEditor,
  isSelectCellEditor,
  isTextareaCellEditor,
  isNumberCellEditor,
} from './utils';
import { AutoCellRenderer } from '../TableElement';

// params的value 为string类型
export function processCellForClipboard(params, store, routerParams, isCopyToPaste) {
  const { value } = params;
  const { colDef } = params.column;

  if (!isCopyToPaste) {
    const { cellRenderer, cellRendererParams } = params.column.colDef;

    if (cellRenderer?.toDisplayText) {
      return cellRenderer.toDisplayText(params.value, cellRendererParams, params.context);
    }
    return AutoCellRenderer.toDisplayText(params.value, cellRendererParams, params.context);
  }

  if (isSelectCellEditor(colDef)) {
    return JSON.stringify({ value });
  }

  if (isACLCellEditor(colDef)) {
    const { idListUrl, dataUrl, dataFilters } = colDef?.cellEditorParams?.componentProps || {};
    const { data } = params.node || {};
    const outerFilterModel = JSON.stringify(
      resolveDataFilters({ dataFilters, store, currentData: data, params: routerParams })[0],
    );
    // clipboard only support string value.
    return `ACLCellEditor${JSON.stringify({ idListUrl, dataUrl, outerFilterModel, value })}`;
  }

  if (isTextareaCellEditor(colDef)) {
    return value?.longText;
  }

  return value;
}

const getSelectValue = (value) => {
  if (Array.isArray(value)) {
    // Match SelectCellRender valueType is `itemAlwaysArray` or `valueAlwaysArray`
    return value.map((item) => (typeof item === 'object' ? item.value : item)).join('#@#');
  }

  if (typeof value === 'string' || typeof value === 'number') {
    // Match SelectCellRender valueType is `value`
    return value;
  }

  if (typeof value === 'object' && value.value) {
    // Match SelectCellRender valueType is `item`
    return value.value;
  }
  return '';
};

export function processCellFromClipboard(params, store, routerParams) {
  const { value } = params;
  const { colDef } = params.column;

  if (!value) {
    return undefined;
  }

  if (isSelectCellEditor(colDef)) {
    const {
      mode: legacyMode, // @deprecated
      multiple: multipleProp = false,
      valueType = 'itemAlwaysArray',
      childEnum, // TODO, delete childEnum, use options
      options: optionsProp,
      dataUrl,
      mapping = { value: 'value', label: 'label' },
    } = colDef.cellEditorParams.componentProps;

    const multiple = multipleProp || legacyMode === 'multiple' || legacyMode === 'tags';

    const parsed = parseJSON(value);

    if (!parsed) {
      return 'CellEditor:invalid';
    }
    const { value: newValue } = parsed;

    if (dataUrl) {
      return newValue;
    }

    // Take first value when mode not match
    let keys = getSelectValue(newValue).split('#@#');
    if (keys.length > 1 && !multiple) {
      // eslint-disable-next-line prefer-destructuring
      keys = keys[0];
    }

    const options = optionsProp || childEnum || [];

    const selected = options.filter((item) => {
      return keys.find((key) => String(key) === String(item[mapping.value]));
    });

    // TODO, 如果是同一列的粘贴，大部分情况可以不验证直接粘贴值过去，同一列的 options 应该是相同的
    if (!selected) {
      return 'CellEditor:invalid';
    }

    if (valueType === 'itemAlwaysArray') {
      return selected;
    }

    if (valueType === 'valueAlwaysArray') {
      return selected.map((item) => item[mapping.value]);
    }

    if (valueType === 'item') {
      return { value: selected[0][mapping.value], label: selected[0][mapping.label] };
    }

    if (valueType === 'value') {
      return selected[0]?.[mapping.value];
    }

    return 'CellEditor:invalid';
  }

  if (isACLCellEditor(colDef)) {
    if (!value.startsWith('ACLCellEditor')) {
      // only can paste value copied from ACLCellEditor column
      return 'CellEditor:invalid';
    }

    const { idListUrl, dataUrl, dataFilters } = colDef?.cellEditorParams?.componentProps || {};
    const { data } = params.node || {};
    const outerFilterModel = JSON.stringify(
      resolveDataFilters({ dataFilters, store, currentData: data, params: routerParams })[0],
    );

    // parse stringify value from clipboard
    const sourceValue = JSON.parse(value.split('ACLCellEditor')[1]);

    // on can paste value copied from ACLCellEditor column which datasource is same
    if (
      sourceValue.idListUrl === idListUrl &&
      sourceValue.dataUrl === dataUrl &&
      sourceValue.outerFilterModel === outerFilterModel
    ) {
      return sourceValue.value;
    }

    return 'CellEditor:invalid';
  }

  if (isTextareaCellEditor(colDef)) {
    return { longText: value };
  }

  if (isNumberCellEditor(colDef)) {
    const parsed = Number(value);
    return Number.isNaN(parsed) ? 'CellEditor:invalid' : parsed;
  }

  return value;
}
