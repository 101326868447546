import PropTypes from 'prop-types';
import { DatePicker, Input, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { debounce, toDateValue } from '@icp/utils';
import { useTranslation } from 'react-i18next';
import { AG_FILTER_TYPES } from '../../AgTable/constants';
import SetFilterValues from './SetFilterValues';

function FilterItemValue(props) {
  const { context, colDef, model, onChange } = props;
  const { filterType, type, filter, filterTo, dateFrom, dateTo, values } = model;

  const { t } = useTranslation(['icp-components']);

  const debounceMS = 400;

  if (filterType === 'text') {
    return (
      <div className="icp-table-filter-item-value icp-center">
        <Input
          autoFocus={true}
          placeholder={t('toolbar.filter.type-a-value')}
          defaultValue={filter}
          onChange={debounce((event) => onChange('filter', event.target.value), debounceMS)}
        />
      </div>
    );
  }

  if (filterType === 'number') {
    if (type === 'inRange') {
      return (
        <div className="icp-table-filter-item-value icp-center">
          <InputNumber
            autoFocus={true}
            placeholder={t('toolbar.filter.type-a-value')}
            defaultValue={filter}
            onChange={debounce((v) => onChange('filter', v), debounceMS)}
          />
          <span>→</span>
          <InputNumber
            placeholder={t('toolbar.filter.type-a-value')}
            defaultValue={filterTo}
            onChange={debounce((v) => onChange('filterTo', v), debounceMS)}
          />
        </div>
      );
    }
    return (
      <div className="icp-table-filter-item-value icp-center">
        <InputNumber
          autoFocus={true}
          placeholder={t('toolbar.filter.type-a-value')}
          defaultValue={filter}
          onChange={debounce((v) => onChange('filter', v), debounceMS)}
        />
      </div>
    );
  }

  if (filterType === 'date') {
    // TODO，暂时不支持 showTime。
    // TODO，看文档和测试下来 ag-grid 要求 dateFrom 和 dateTo 必须是 'YYYY-MM-DD HH:mm:ss' 这种格式的，否则会有时区问题。
    // TODO，等项目需求真实做 showTime 的时候，处理的和后端表单 api 的接口一致。
    if (type === 'inRange') {
      return (
        <div className="icp-table-filter-item-value icp-center">
          <DatePicker
            autoFocus={true}
            placeholder={t('toolbar.filter.starting')}
            defaultValue={dateFrom ? dayjs(dateFrom) : undefined}
            onChange={(v) => onChange('dateFrom', toDateValue(v))}
          />
          <span>→</span>
          <DatePicker
            placeholder={t('toolbar.filter.ending')}
            defaultValue={dateFrom ? dayjs(dateTo) : undefined}
            onChange={(v) => onChange('dateTo', toDateValue(v))}
          />
        </div>
      );
    }
    return (
      <div className="icp-table-filter-item-value icp-center">
        <DatePicker
          autoFocus={true}
          defaultValue={dateFrom ? dayjs(dateFrom) : undefined}
          onChange={(v) => onChange('dateFrom', toDateValue(v))}
        />
      </div>
    );
  }

  if (filterType === 'set') {
    return (
      <SetFilterValues
        context={context}
        colDef={colDef}
        values={values}
        onChange={(v) => onChange('values', v)}
      />
    );
  }

  return null;
}

FilterItemValue.propTypes = {
  context: PropTypes.shape({}),
  colDef: PropTypes.shape({}),
  model: PropTypes.shape({
    filterType: PropTypes.string,
    type: PropTypes.oneOf(AG_FILTER_TYPES),
    filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterTo: PropTypes.number,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
      }),
    ),
  }),
  onChange: PropTypes.func,
};

export default FilterItemValue;
