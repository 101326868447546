/* eslint-disable react/prop-types */
import {
  ColumnSelect,
  FullScreen,
  Icon,
  Toolbar,
  SearchBox,
  FilterButton,
  FilterPanel,
  CombinedViewSwitch,
  RefreshButton,
  ToolbarSelect,
  FavoriteView,
  TableSetting,
} from '@icp/components';
import { useState } from 'react';
// import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { immutableMove } from '@icp/utils';
import { useCombinedView } from '../../combinedViewCtx';
import ScaleNavigate from './ScaleNavigate';
import { clearSetting } from './settingHandler';

const EMPTY_OBJECT = {};

function GanttToolbar(props) {
  const {
    children,
    isInDesign,
    gantt,
    ganttConfig,
    columnDefs,
    pinnedFilter,
    defaultGanttState,
    ganttState,
    searchText,
    onRefresh,
    onGanttStateChange,
    onSearch,
    // suppressExpandAll,
    calendarData,
    settingKey,
    showTime,
  } = props;

  const { columnOrder, columnVisibility, filter, zoomLevel, currentCalendar } = ganttState;

  const { t } = useTranslation();
  const combinedView = useCombinedView();

  const [container, setContainer] = useState(null);
  const [filterPanelOpen, setFilterPanelOpen] = useState(true);

  const node = container?.parentNode;

  const handleRoomChange = (newZoomLevel) => {
    gantt.ext.zoom.setLevel(newZoomLevel);
    onGanttStateChange({ zoomLevel: newZoomLevel });
  };

  const navigateTo = (to) => {
    const ganttTask = node.querySelector('.gantt_task');
    const scrollState = gantt.getScrollState();
    const half = ganttTask.clientWidth / 2;

    if (to === 'today') {
      const todayLine = node.querySelector('.icp-gantt-today-line');
      if (!todayLine) {
        return;
      }
      const shouldInX = ganttTask.getBoundingClientRect().left + half;
      const currentX = todayLine.getBoundingClientRect().left;
      const gap = currentX - shouldInX;
      gantt.scrollTo(scrollState.x + gap, null);
    } else if (to === 'prev') {
      gantt.scrollTo(scrollState.x - half, null);
    } else if (to === 'next') {
      gantt.scrollTo(scrollState.x + half, null);
    }
  };

  /* const toggleExpandAll = () => {
    let hasOpen = false;
    gantt.eachTask((task) => {
      if (task.$open) {
        hasOpen = true;
      }
    });
    if (hasOpen) {
      gantt.eachTask((task) => {
        task.$open = false;
      });
    } else {
      gantt.eachTask((task) => {
        task.$open = true;
      });
    }
    gantt.refreshData();
  }; */

  const handleChangeColumnHidden = ({ colId, hide, newHiddenColIds }) => {
    const column = gantt.getGridColumn(colId);
    column.hide = hide;
    gantt.render();
    onGanttStateChange({
      columnVisibility: { ...columnVisibility, hiddenColIds: newHiddenColIds },
    });
  };

  const handleChangeColumnOrder = ({ fromId, toId, newOrderedColIds }) => {
    const fromIndex = gantt.config.columns.findIndex((col) => col.colId === fromId);
    const toIndex = gantt.config.columns.findIndex((col) => col.colId === toId);
    gantt.config.columns = immutableMove(gantt.config.columns, fromIndex, toIndex);
    gantt.render();
    onGanttStateChange({
      columnOrder: { ...columnOrder, orderedColIds: newOrderedColIds },
    });
  };

  const handleExport = () => {
    gantt.exportToPNG();
  };

  const handleResetSetting = () => {
    clearSetting(settingKey);
    onGanttStateChange(defaultGanttState, true);
  };

  const handleSwitchView = (view) => {
    if (!view) {
      handleResetSetting();
    }
    onGanttStateChange(view);
  };

  const filterPanel = filterPanelOpen ? (
    <FilterPanel
      context={EMPTY_OBJECT}
      columnDefs={columnDefs}
      pinnedFilter={pinnedFilter}
      filterModel={filter.filterModel}
      onChange={(newFilterModel) =>
        onGanttStateChange({
          filter: {
            ...filter,
            filterModel: newFilterModel,
          },
        })
      }
    />
  ) : null;

  return (
    <Toolbar className="icp-gantt-toolbar" style={{ opacity: !gantt ? 0 : 1 }} ref={setContainer}>
      <div className="icp-toolbar-main">
        {!combinedView ? filterPanel : <CombinedViewSwitch {...combinedView} />}
        <div className="icp-toolbar-actions">
          {children}
          <SearchBox
            // open={fuzzySearchOpen}
            // placeholder={fuzzySearchPlaceholder}
            // suppressSpeech={suppressFuzzySearchSpeech}
            defaultValue={searchText}
            onSearch={onSearch}
            // ref={searchRef}
          />
          {combinedView ? (
            <FilterButton onClick={() => setFilterPanelOpen(!filterPanelOpen)} />
          ) : null}
          <ColumnSelect
            columnDefs={columnDefs}
            orderedColIds={columnOrder.orderedColIds}
            hiddenColIds={columnVisibility.hiddenColIds}
            onOrderChange={handleChangeColumnOrder}
            onVisibleChange={handleChangeColumnHidden}
          />
          <RefreshButton onClick={onRefresh} />
          {/* {!suppressExpandAll ? (
            <Tooltip title={t('gantt.collapse-expand-all', { ns: 'icp-form-renderer' })}>
              <Button
                type="text"
                icon={<Icon name="material:unfold-less-rounded" size={20} />}
                onClick={toggleExpandAll}
              />
            </Tooltip>
          ) : null} */}
          <FullScreen el={node} />
          <TableSetting
            items={[
              // supportShowDeleted ? 'showDeleted' : null,
              'export',
              'resetSetting',
            ].filter(Boolean)}
            exportType="png"
            onExport={handleExport}
            onResetSetting={handleResetSetting}
          />
          <hr />
          <ScaleNavigate navigateTo={navigateTo} />
          <ToolbarSelect
            options={[
              showTime
                ? {
                    value: 'day',
                    label: t('day', { ns: 'icp-common' }),
                  }
                : null,
              {
                value: 'week',
                label: t('week', { ns: 'icp-common' }),
              },
              {
                value: 'month',
                label: t('month', { ns: 'icp-common' }),
              },
              {
                value: 'quarter',
                label: t('quarter', { ns: 'icp-common' }),
              },
              {
                value: 'year',
                label: t('year', { ns: 'icp-common' }),
              },
            ].filter(Boolean)}
            value={zoomLevel}
            onChange={handleRoomChange}
          />
          {ganttConfig.work_time !== false &&
          currentCalendar &&
          Array.isArray(calendarData) &&
          calendarData.length > 1 ? (
            <ToolbarSelect
              value={currentCalendar}
              onChange={(newCalendar) => onGanttStateChange({ currentCalendar: newCalendar })}
              options={calendarData.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
            >
              <Icon name="material:calendar-month-rounded" size={16} />
              {calendarData.find((item) => item.id === currentCalendar)?.name || currentCalendar}
            </ToolbarSelect>
          ) : null}
          {isInDesign || settingKey ? (
            <>
              <hr />
              <FavoriteView
                settingKey={settingKey}
                currentState={ganttState}
                onSwitchFavoriteView={handleSwitchView}
              />
            </>
          ) : null}
        </div>
      </div>
      {combinedView && filterPanelOpen ? (
        <div className="icp-toolbar-filter-panel-separate">{filterPanel}</div>
      ) : null}
    </Toolbar>
  );
}

export default GanttToolbar;
