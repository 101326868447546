import PropTypes from 'prop-types';
import { ConditionalPropertyPropType, PropTypeLoadRemoteContext } from './PredicateTypes';

// JSON schema 可以写的属性
const schemaFormProps = {
  accessPermissionPredicate: PropTypes.shape({}),
  loadRemoteContext: PropTypeLoadRemoteContext,
  /**
   * 如果放到 schema.form 里，将支持 eval 表达式, 表达式返回对象将 merge 进表单数据
   */
  onFieldValueChange: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * Root div 的 id
   */
  id: PropTypes.string,
  /**
   * Root div 的 className
   */
  className: PropTypes.string,
  /**
   * 页面的标题
   */
  title: PropTypes.string,
  /**
   *  Root div 的 style
   */
  style: PropTypes.shape({}),
  /**
   * 是否显示默认提交按钮
   */
  defaultSubmitButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      // all Button props
    }),
  ]),
  /**
   * 是否显示默认取消按钮
   */
  defaultCancelButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      // all Button props
    }),
  ]),
  /**
   * 默认按钮的显示位置
   * @default bottom
   */
  defaultButtonPosition: PropTypes.oneOf(['top', 'bottom']),
  /**
   * 表单所有 input 元素的 label 布局
   */
  labelLayout: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * 表单 input 元素的 label 是否显示冒号
   */
  showColon: PropTypes.bool,
  /**
   * 转换从 `retrieveUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
   */
  transformRetrievedData: PropTypes.string,
  /**
   * 是否需要翻译请求结果
   * @default false
   */
  translateRetrievedData: PropTypes.bool,
  /**
   * layout层的auto lock标记，决定get-by-id接口是否加参数enable_auto_lock=true
   */
  enableAutoLock: PropTypes.bool,
  /**
   * 使用 `retrieveUrl` 去获取数据
   */
  retrieveUrl: PropTypes.string,
  /**
   * 当 `submitMethod` 是 `post` 的时候，表单通过 `createUrl` 提交数据
   */
  createUrl: PropTypes.string,
  /**
   * 当 `submitMethod` 是 `put` 的时候，表单通过 `updateUrl` 提交数据
   */
  updateUrl: PropTypes.string,
  /**
   * 表单提交的方式，`post` 配合 `createUrl` 使用，`put` 配合 `updateUrl` 使用
   */
  submitMethod: PropTypes.oneOf(['post', 'put']),
  /**
   * 提交前的数据转换，使用 eval 表达式，this 指向提交数据
   */
  transformDataSubmit: PropTypes.string,
  /**
   * 是否禁用
   */
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  /**
   * 是否只读
   */
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  /**
   * 是否禁止表单数据验证
   */
  suppressValidation: ConditionalPropertyPropType(PropTypes.bool),
  /**
   * If true，当表单通过 retrieveUrl 获取数据的时候不渲染内容
   */
  blockRenderWhenFetching: PropTypes.bool,
  /**
   * If true，不在 Button 打开的弹窗里自动隐藏默认按钮
   */
  disableHideDefaultButtonsInDialog: PropTypes.bool,
  /**
   * JS 代码，可以在表单过后执行
   */
  script: PropTypes.string,
};

// Wrapper.js 独有的 props
export const WrapperProps = {
  /**
   * The decorator for form fields, normally it is Designable component in FormDesigner.
   */
  ElementDecorator: PropTypes.func,
  /**
   * 表单的上下文
   */
  context: PropTypes.shape({
    /**
     * pbc router pbcToken
     */
    pbcToken: PropTypes.string,
    /**
     * 用户权限
     */
    userPermissionMap: PropTypes.shape({}),
    /**
     * 用户信息
     */
    userProfile: PropTypes.shape({}),
  }),
  /**
   * 表单的默认 `data`，FormRenderer 只提供 `uncontrolled` 的使用方式
   */
  defaultData: PropTypes.shape({}),
  /**
   * @deprecated
   * 自定义表单验证的 `yup schema`
   */
  yupSchema: PropTypes.shape({}),
  /**
   * 额外的需要从 `schema` 当中过滤掉的元素
   */
  notAllowedFields: PropTypes.arrayOf(PropTypes.string),
};

// FormRenderer.js 的 props
export const FormRendererProps = {
  /**
   * The decorator for form fields, normally it is Designable component in FormDesigner.
   */
  ElementDecorator: PropTypes.func,
  /**
   * JSX children，如果使用了 `children` 属性，`schema` 属性将会被忽略
   */
  children: PropTypes.node,
  /**
   * 表单元素的 json schema
   */
  schema: PropTypes.shape({
    form: PropTypes.shape(schemaFormProps),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  ...schemaFormProps,
  /**
   * 给 designer 里设置项目 appConfig.i18n.allowedLanguages 使用的，代码里不要使用。
   */
  allowedLanguages: PropTypes.arrayOf(PropTypes.string),
  /**
   * 内部属性，Wrapper 传给 FormRenderer 以做 inject 的。Wrapper 同时还会传到 StoreProvider 里使用
   */
  formApi: PropTypes.shape({}),
  /**
   * 当点击 `type=submit` 的 `Button`，或者手动调用 `formApi.submit` 的时候调用此函数，如果提供了 `onSubmit` 函数，`createUrl`、`updateUrl` 将会失效
   * @param {object} dataWithPreprocessor: The form data with.
   */
  onSubmit: PropTypes.func,
  /**
   * 当表单提交数据成功过后调用此函数
   * @param {object} responseData: 提交方法的返回值
   */
  onSubmitted: PropTypes.func,
  /**
   * 当点击 `type=save` 的 `Button` 的时候调用此函数
   * @param {object} dataWithPreprocessor: 表单 data
   */
  onSave: PropTypes.func,
  /**
   * 当点击 `type=cancel` 的 `Button`，或者手动调用 `formApi.cancel` 的时候调用此函数
   */
  onCancel: PropTypes.func,
  /**
   * 当点击 `type=refresh` 的 `Button`，或者手动调用 `formApi.refresh` 的时候调用此函数, 当有 `retrieveUrl` 的时候这个函数会失效
   */
  onRefreshData: PropTypes.func,
  /**
   * 当表单第一次渲染的时候调用此函数
   * @param {object} formApi: 表单的 API
   */
  onFirstRendered: PropTypes.func,
  /**
   * 当表单 API 以及所有异步组件 API 可用
   * @param {object} formApi: 表单的 API
   */
  onReady: PropTypes.func,
  /**
   * 当表单卸载的时候调用
   */
  onUnmount: PropTypes.func,
  /**
   * 当表单 schema 发生改变的时候调用此函数
   * @param {object}
   */
  onSchemaChanged: PropTypes.func,
  /**
   * 当表单 input 元素值发生改变的时候调用此函数
   * 如果放到 schema.form 里，将支持 eval 表达式, 表达式返回对象将 merge 进表单数据
   * @param {string} keyPath: input 元素的 id
   * @param {any} value: input 元素新的 value
   */
  onFieldValueChange: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * @deprecated, use `onChange` instead
   */
  onDataChange: PropTypes.func,
  /**
   * 当表单 input 元素的 value 发生改变的时候调用此函数
   * @param {object} data: 表单新的 data
   */
  onChange: PropTypes.func,
  /**
   * 当表单验证失败的时候调用此函数
   */
  onValidationFail: PropTypes.func,
};
