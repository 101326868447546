/* eslint-disable react/prop-types */
import { resolveConditionalProperty, selectContext, selectValues } from '@icp/form-renderer-core';
import { resolveVariablePattern } from '@icp/utils';
import clsx from 'clsx';
import ButtonUI from '../ButtonElement/ButtonUI';

// 由于 dhtmlx gantt 的列里面无法调用 ButtonElement，无法调用 IcpApp 上下文的 useParams 和 FormRenderer 的 store，
// 所以这里模仿 ButtonElement 的配置简单支持一些属性
function SimpleButtonElement(props) {
  const {
    store,
    params,
    currentData,
    //
    hidden: hiddenProp,
    className: classNameProp,
    disabled: disabledProp,
    style,
    componentProps = {},
  } = props;

  const {
    size,
    content: contentProp,
    className: classNameComp,
    style: buttonStyle,
    icon,
    onClick,
    ...otherComponentProps
  } = componentProps;

  const [hidden] = resolveConditionalProperty({
    value: hiddenProp,
    currentData,
    store,
    params,
  });

  const [disabled] = resolveConditionalProperty({
    value: disabledProp,
    currentData,
    store,
    params,
  });

  const content = resolveVariablePattern({
    pattern: contentProp,
    currentData: currentData || selectValues(store.getState()),
    formData: selectValues(store.getState()),
    context: selectContext(store.getState()),
    params,
  });

  if (hidden) {
    return null;
  }

  return (
    <ButtonUI
      {...otherComponentProps}
      className={clsx(classNameProp, classNameComp)}
      style={{ ...buttonStyle, ...style }}
      type="text"
      size={size}
      icon={icon}
      disabled={disabled}
      onClick={onClick}
    >
      {content}
    </ButtonUI>
  );
}

export default SimpleButtonElement;
