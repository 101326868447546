import {
  ACL,
  Audit,
  Button,
  Carousel,
  Cascader,
  Checkbox,
  CodeEditor,
  Collapse,
  DatePicker,
  DateRangePicker,
  Divider,
  DropDown,
  EChart,
  EditableTable,
  EventCalendar,
  FormDesigner,
  Gantt,
  Icon,
  IconUpload,
  IFrame,
  Image,
  Input,
  List,
  Mining,
  NavTabs,
  NumberPicker,
  OrgTree,
  Ocr,
  PageHeader,
  Password,
  Permissions,
  Progress,
  QRCode,
  Radio,
  Rate,
  RichText,
  Select,
  Slider,
  Status,
  Steps,
  Success,
  Switch,
  Swiper,
  Table,
  Tabs,
  Textarea,
  Text,
  TimePicker,
  TimeRangePicker,
  TodoList,
  Tooltip,
  Tree,
  TreeSelect,
  Upload,
  Video,
} from '../elements';
import { Box, Card, Grid, Mobile, Stack } from '../layouts';
import { CombinedView, Data, Link, Timer, Extension } from '../wrappers';

const inputElementMap = {
  ACL,
  Checkbox,
  DatePicker,
  DateRangePicker,
  EditableTable,
  FormDesigner,
  Input,
  IconUpload,
  NumberPicker,
  OrgTree,
  Password,
  Permissions,
  Radio,
  Rate,
  RichText,
  Select,
  Slider,
  Switch,
  Textarea,
  TimePicker,
  TimeRangePicker,
  Upload,
  Cascader,
  TreeSelect,
  CodeEditor,
  Ocr,
};
const displayElementMap = {
  // Flow: Flow,
  Audit,
  Button,
  Carousel,
  Collapse,
  Divider,
  DropDown,
  EChart,
  Gantt,
  EventCalendar,
  Icon,
  IconString: IconUpload,
  IFrame,
  Image,
  List,
  Mining,
  NavTab: NavTabs, // legacy support, NavTab already renamed to NavTabs
  NavTabs,
  PageHeader,
  Progress,
  QRCode,
  Status,
  Steps,
  Success,
  Swiper,
  Tab: Tabs, // legacy support, Tab already renamed to Tabs
  Table,
  Tabs,
  Text,
  TodoList,
  Tooltip,
  Tree,
  Video,
};
const layoutMap = {
  // compatible with old usage，老的 json 里有直接写 "component": "CardLayout" 的，elements 则没有这个老的 json 用法
  CardLayout: Card,
  FlowLayout: Stack,
  GridLayout: Grid,
  MobileLayout: Mobile,
  NormalLayout: Box,
  //
  Box,
  Stack,
  Grid,
  Card,
  Mobile,
};
const wrapperMap = {
  // compatible with old usage
  LinkWrapper: Link,
  //
  CombinedView,
  Data,
  Link,
  Timer,
  Extension,
};

const componentMap = { ...inputElementMap, ...displayElementMap, ...layoutMap, ...wrapperMap };

export default componentMap;
