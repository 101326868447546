import {
  getFormEntityByToken,
  handleLockOnFieldValueChange,
  selectInitialValues,
  selectEnableAutoLock,
} from '@icp/form-renderer-core';

const AUTO_LOCK_EVENT_SOURCE = 'autoLock';

export async function processAutoLock(event, fieldToken, fieldConfig, store) {
  if (event.source === AUTO_LOCK_EVENT_SOURCE) {
    return;
  }

  const oldValue = event.oldValue;
  const newValue = event.value;
  if (oldValue === newValue) return;

  /*

    判断可编辑表格里某一列是否需要上锁
    Layout启用自动锁 + 列对应的子表字段启用自动锁

  */

  // layout未启用自动锁定，跳过
  if (!selectEnableAutoLock(store.getState())) {
    return;
  }

  // // 列未启用自动锁定，跳过
  // if (!event.colDef.cellEditorParams?.enableAutoLock) {
  //   return;
  // }

  const subFormEntity = await store
    .dispatch(
      getFormEntityByToken({
        pbcToken: fieldConfig.pbcToken,
        formEntityToken: fieldConfig.formEntityToken,
      }),
    )
    .unwrap();

  const thisColumnFieldToken = event.column.colId;
  const thisColumnField = subFormEntity.fields?.find((f) => f.token === thisColumnFieldToken);

  // 列对应的子表字段未启用自动锁，跳过
  if (!thisColumnField?.isAutoLock) {
    return;
  }

  const formEntityDataId = selectInitialValues(store.getState())?.id;

  const finalValue = await handleLockOnFieldValueChange({
    oldValue,
    newValue,
    primaryDataId: formEntityDataId,
    field: thisColumnField,
  });

  if (finalValue !== newValue) {
    event.node.setDataValue(thisColumnFieldToken, finalValue, AUTO_LOCK_EVENT_SOURCE);
  }
}
