const allPbcCode = {};

// code after code generator
const tokenRegexp = /^[a-zA-Z][a-zA-Z0-9-]+$/;
if (!process.env.SAAS_MODE) {
  (function importS() {
    const r = require.context('@site/src/pbcCode', true, /\.js$/);
    r.keys()
      // filter outer duplicate key caused by webpack.config.js resolve.context
      .filter((key) => key.startsWith('./'))
      .forEach((key) => {
        const content = r(key).default;
        const paths = key.split('/');
        const [, pbcToken] = paths;
        const filename = paths[paths.length - 1];

        if (!allPbcCode[pbcToken]) {
          allPbcCode[pbcToken] = {};
        }
        if (!allPbcCode[pbcToken].forms) {
          allPbcCode[pbcToken].forms = {};
        }
        if (!allPbcCode[pbcToken].pages) {
          allPbcCode[pbcToken].pages = {};
        }

        // pbc form entities layouts
        if (paths[4] === 'layouts' && paths[2] === 'forms' && paths.length === 6) {
          const formEntityToken = paths[paths.length - 3];
          const layoutToken = filename.substring(0, filename.length - '.js'.length);
          if (!tokenRegexp.test(layoutToken)) {
            throw new Error(`form layout 名称 ${key} 不匹配正则：${tokenRegexp}`);
          }
          if (!allPbcCode[pbcToken].forms[formEntityToken]) {
            allPbcCode[pbcToken].forms[formEntityToken] = {};
          }
          allPbcCode[pbcToken].forms[formEntityToken][layoutToken] = content;
        }

        // pbc pages
        if (paths[2] === 'pages' && paths.length === 4) {
          const schemaId = filename.substring(0, filename.length - '.js'.length);
          if (!tokenRegexp.test(schemaId)) {
            throw new Error(`page 名称 ${key} 不匹配正则：${tokenRegexp}`);
          }
          allPbcCode[pbcToken].pages[schemaId] = content;
        }
      });
  })();
}

export default allPbcCode;
