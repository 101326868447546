/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { restApi } from '@icp/settings';
import { mergeWithDefaultColDef } from '@icp/components';
import { getTFunc } from '@icp/i18n';
import { cloneDeep } from 'lodash-es';
import { toDateValue } from '@icp/utils';
import dayjs from 'dayjs';
import GanttActionCellRenderer from './GanttActionCellRenderer';
import { ACTION_COLUMN, DEFAULT_COL_DEF, PROGRESS_COLUMN } from '../TableElement';
import COLUMN_TYPES from '../TableElement/columnTypes';
import { loadSetting } from './settingHandler';
import { handleCustomSetFilters } from '../TableElement/utils';

export function formatGanttColumns({
  store,
  params,
  columnDefsProp,
  ganttApiRef,
  deletePromisesRef,
  mapping,
  readonly,
  isTreeDataSource,
  dateOptions,
  dataSource,
  currentDataRef,
}) {
  const treeEnabled = isTreeDataSource || !!mapping.parent;

  let columnDefs = columnDefsProp || [];

  let treeIndex = columnDefs.findIndex((colDef) => colDef.tree);
  // 默认第一列显示树状结构
  treeIndex = treeIndex >= 0 ? treeIndex : columnDefs.findIndex((colDef) => !colDef.hide);

  columnDefs = columnDefs
    .map((colDef, index) => {
      const formattedColDef = {
        ...colDef,
        type: colDef.type,
        name: colDef.field,
        label: colDef.headerName,
        resize: true,
        hide: colDef.hide,
        width: colDef.width,
        max_width: colDef.maxWidth,
        min_width: colDef.minWidth,
        tree: treeEnabled && index === treeIndex ? true : undefined,
      };

      // gantt 组件要求是 0 - 1 之间的小数，toGanttDataItem 会进行转换，colDef 里的设置无意义
      if (
        colDef.type === PROGRESS_COLUMN &&
        formattedColDef.name === mapping.progress &&
        colDef.cellRendererParams?.inHundred
      ) {
        delete formattedColDef.cellRendererParams.inHundred;
      }

      formattedColDef.template = (item) => {
        // TODO, 重构 getDisplayTextFunc 函数复用
        const value = item[formattedColDef.field];
        const formatFunc = formattedColDef.cellRenderer?.toDisplayText;
        if (formatFunc) {
          return formatFunc(value, formattedColDef.cellRendererParams);
        }
        return value;
      };

      return formattedColDef;
    })
    .concat(
      !readonly
        ? {
            type: ACTION_COLUMN,
            name: ACTION_COLUMN,
            label: '',
            resize: false,
            width: 41,
            min_width: 41,
            max_width: 41,
            onrender: (task) => {
              return (
                <GanttActionCellRenderer
                  store={store}
                  params={params}
                  task={task}
                  ganttApiRef={ganttApiRef}
                  deletePromisesRef={deletePromisesRef}
                  mapping={mapping}
                  isTreeDataSource={isTreeDataSource}
                  dateOptions={dateOptions}
                />
              );
            },
          }
        : null,
    )
    .filter(Boolean);

  // return columnDefs;
  columnDefs = handleCustomSetFilters({
    columnDefs,
    dataSource,
    store,
    params,
    currentDataRef,
  });

  // 为了方便 Toolbar 等组件使用直接统一使用 AgTable 的方法，多与属性 Gantt 不会读取
  columnDefs = mergeWithDefaultColDef(columnDefs, DEFAULT_COL_DEF, COLUMN_TYPES);

  return columnDefs;
}

function getLabeledValue(value) {
  const getValue = (item) => {
    if (typeof item === 'object' && item !== null) {
      return item.value;
    }

    return item;
  };

  if (Array.isArray(value)) {
    return getValue(value[0]);
  }

  return getValue(value);
}

function toGanttDataItem(item, dataSourceConfig, dateOptions) {
  const { mapping } = dataSourceConfig;

  let progress = item[mapping.progress];
  // gantt 组件要求 0 - 1 之间的小数
  if (typeof progress === 'number' && mapping.progress_inHundred) {
    progress /= 100;
  }

  return {
    // 必须 ...item 出来，否则 columns 里无法配置需要显示除了 text 等多余的字段
    // 由于 gantt 必须要求 data 里有下面这些属性，如果直接和 ...item 揉杂在一起可能会有冲突也比较难找问题，所以用
    // _original 备份一下，方便在 edit 的时候修改数据。
    _original: item,
    _dataSourceConfig: dataSourceConfig,
    ...item,
    id: item.id, // id 暂时不 map，很多地方都需要用到 id
    text: item[mapping.text],
    start_date: item[mapping.start_date],
    // end_date: item[mapping.end_date],
    end_date: dateOptions.endDateIncludeSelf
      ? toDateValue(dayjs(item[mapping.end_date]).add(1, 'day'), dateOptions)
      : item[mapping.end_date],
    duration: item[mapping.duration],
    progress,
    parent: getLabeledValue(item[mapping.parent]),
    type: getLabeledValue(item[mapping.type]),
    unscheduled: item.unscheduled ?? !item[mapping.start_date],
  };
}

export function toDataSourceItem(item, mapping, dateOptions) {
  const { _original, start_date, end_date } = item;

  let { progress } = item;
  if (typeof progress === 'number' && mapping.progress_inHundred) {
    progress *= 100;
  }

  // gantt 只会拖拽修改 start_date 和 end_date 和 progress，其余属性都是通过 Button open 的 dialog 编辑的。
  const newItem = {
    ..._original,
  };
  if (mapping.duration) {
    newItem[mapping.duration] = item.duration;
  }
  if (mapping.start_date) {
    newItem[mapping.start_date] = toDateValue(start_date, dateOptions);
  }
  if (mapping.end_date) {
    let endDate = end_date;
    if (dateOptions.endDateIncludeSelf) {
      endDate = dayjs(end_date).add(-1, 'day');
      if (dayjs(endDate).isBefore(dayjs(start_date))) {
        endDate = start_date;
        newItem.duration = 1; // 开始日期等于结束日期 gantt 会 duration 等于 0. 但是在开启 endDateIncludeSelf 的时候应该是 1
      }
    }
    newItem[mapping.end_date] = toDateValue(endDate, dateOptions);
  }
  if (mapping.progress) {
    newItem[mapping.progress] = progress;
  }

  return newItem;
}

export function transformTaskData({ items, dataSourceConfig, dateOptions }) {
  return (items || []).map((item) => {
    return toGanttDataItem(item, dataSourceConfig, dateOptions);
  });
}

export function createDataProcessor({
  gantt,
  dataSources,
  context,
  relation,
  dateOptions,
  deletePromisesRef,
}) {
  return {
    task: {
      create() {
        // 没用 gantt 自带的 add 列创建 task，走不到这个逻辑
        // console.log('task create', item);
      },
      update(item, taskId) {
        const { dataSource, mapping } = item._dataSourceConfig;
        const dataItem = toDataSourceItem(item, mapping, dateOptions);
        const pbcToken = dataSource.pbcToken || context.pbcToken;
        const formEntityToken = dataSource.token;
        const formEntityLayoutToken = dataSource.layoutToken;
        const formEntityDataId = item.id;
        const url = `/form/api/v2/form-entity-data/${formEntityDataId}/${pbcToken}/${formEntityToken}/${formEntityLayoutToken}`;
        return restApi.put(url, dataItem).then(() => {
          const task = gantt.getTask(taskId);
          const newItem = toGanttDataItem(dataItem, item._dataSourceConfig, dateOptions);
          // 只有拖拽 gantt timeline 修改 start_date 和 end_date 才会走到这个逻辑，所以只更新这两个属性
          task[mapping.start_date] = newItem[mapping.start_date];
          task[mapping.end_date] = newItem[mapping.end_date];
          task.start_date = dayjs(newItem.start_date).toDate();
          task.end_date = dayjs(newItem.end_date).toDate();
          task.duration = newItem.duration; // duration 可能会因为 toGanttDataItem 里的 -1 天逻辑而改变
          gantt.refreshTask(taskId);
        });
      },
      delete(taskId) {
        // console.log('task delete', taskId);
        const promise = restApi.delete(`/form/api/form-entity-data/${taskId}`);
        // 给 GanttActionCellRenderer 里处理 loading 使用
        deletePromisesRef.current.push(promise);
        return promise;
      },
    },
    link: {
      create(item) {
        // console.log('link create', item);
        const pbcToken = dataSources.link.dataSource.pbcToken || context.pbcToken;
        const formEntityToken = dataSources.link.dataSource.token;
        const formEntityLayoutToken = dataSources.link.dataSource.layoutToken;
        const url = `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/${formEntityLayoutToken}`;
        return restApi.post(url, { ...item, [relation.colId]: relation.value }).then((response) => {
          gantt.changeLinkId(item.id, response.id);
        });
      },
      update(item) {
        // console.log('link update', item);
        const pbcToken = dataSources.link.dataSource.pbcToken || context.pbcToken;
        const formEntityToken = dataSources.link.dataSource.token;
        const formEntityLayoutToken = dataSources.link.dataSource.layoutToken;
        const formEntityDataId = item.id;
        const url = `/form/api/v2/form-entity-data/${formEntityDataId}/${pbcToken}/${formEntityToken}/${formEntityLayoutToken}`;
        return restApi.put(url, item);
      },
      delete(linkId) {
        // console.log('link delete', linkId);
        const promise = restApi.delete(`/form/api/form-entity-data/${linkId}`);
        // 给 GanttActionCellRenderer 里处理 loading 使用
        deletePromisesRef.current.push(promise);
        return promise;
      },
    },
  };
}

export function addTodayMarker(gantt) {
  const t = getTFunc();

  const todayMarkerId = gantt.addMarker({
    start_date: new Date(),
    css: 'icp-gantt-today-line',
    text: t('today', { ns: 'icp-common' }),
    title: t('today', { ns: 'icp-common' }),
  });

  const refresh = () => {
    const today = gantt.getMarker(todayMarkerId);
    // 如果 marker 已经被删除（调用 deleteAll 会重新 add 新的 today marker），则停止旧的刷新任务
    if (today) {
      today.start_date = new Date();
      if (gantt.getScale().unit === 'hour') {
        const dateToStr = gantt.date.date_to_str('%H:%i');
        today.text = dateToStr(today.start_date);
      } else {
        today.text = t('today', { ns: 'icp-common' });
      }
      gantt.updateMarker(todayMarkerId);

      setTimeout(() => {
        refresh();
      }, 1000 * 60);
    }
  };

  refresh();

  return refresh;
}

function formatGanttState(state, defaultState) {
  const {
    // table, gantt
    columnOrder,
    columnPinning,
    columnSizing,
    columnVisibility,
    filter,
    rowGroup,
    sort,
    pagination,
    tableSize,
    // gantt
    zoomLevel,
    scrollLeft,
    currentCalendar,
    grid_width,
  } = state;

  const validateArray = (item) => {
    if (!(Array.isArray(item) && item.length)) {
      return undefined;
    }
    return item;
  };

  // columnPinning
  const leftColIds = validateArray(columnPinning?.leftColIds);
  const rightColIds = validateArray(columnPinning?.rightColIds);

  // columnOrder
  const orderedColIds = validateArray(columnOrder?.orderedColIds);

  // columnSizing
  const columnSizingModel = validateArray(columnSizing?.columnSizingModel);

  // columnVisibility
  const hiddenColIds = validateArray(columnVisibility?.hiddenColIds);

  // filter
  const filterModel = validateArray(filter?.filterModel || defaultState.filter?.filterModel);

  // groupColIds
  const groupColIds = validateArray(rowGroup?.groupColIds);

  // sort
  const sortModel = validateArray(sort?.sortModel);

  return {
    // 延用 ag-grid 的接口属性 https://www.ag-grid.com/react-data-grid/grid-state/
    columnPinning: {
      leftColIds,
      rightColIds,
    },
    columnOrder: {
      orderedColIds,
    },
    columnSizing: {
      columnSizingModel,
    },
    columnVisibility: {
      hiddenColIds,
    },
    filter: {
      filterModel,
    },
    rowGroup: {
      groupColIds,
    },
    sort: {
      sortModel,
    },
    pagination: {
      pageSize: pagination?.pageSize || defaultState.pagination?.pageSize,
    },

    // 我们自己定义的属性
    // table 特殊属性
    tableSize: tableSize || defaultState.tableSize,

    // gantt 特殊属性
    zoomLevel: zoomLevel || defaultState.zoomLevel,
    scrollLeft,
    currentCalendar: currentCalendar || defaultState.currentCalendar,
    grid_width: grid_width || defaultState.grid_width,
  };
}

export function loadGanttDefaultState(defaultValues) {
  return formatGanttState({}, defaultValues);
}

export function loadGanttInitState(settingKey, defaultState = {}) {
  const saved = loadSetting(settingKey);
  return formatGanttState(saved, defaultState);
}

function sortByOrder(columnDefs, orderedColIds) {
  const existColDefs = orderedColIds.map((colId) => {
    return columnDefs.find((colDef) => colDef.colId === colId);
  });
  const restColDefs = columnDefs.filter(
    (colDef) => !orderedColIds.find((colId) => colId === colDef.colId),
  );
  return existColDefs.concat(restColDefs).filter(Boolean);
}

export function setAllGanttColumnState({ gantt, columnDefs, ganttState }) {
  gantt.config.columns = cloneDeep(columnDefs);

  const { columnOrder, columnSizing, columnVisibility } = ganttState;
  const { orderedColIds } = columnOrder;
  const { columnSizingModel } = columnSizing;
  const { hiddenColIds } = columnVisibility;

  let newColumns = gantt.config.columns;
  if (Array.isArray(orderedColIds)) {
    newColumns = sortByOrder(newColumns, orderedColIds);
  }

  if (Array.isArray(columnSizingModel)) {
    columnSizingModel.forEach((item) => {
      const col = newColumns.find((colDef) => colDef.colId === item.colId);
      if (col) {
        col.width = item.width;
      }
    });
  }

  if (Array.isArray(hiddenColIds)) {
    newColumns.forEach((colDef) => {
      colDef.hide = hiddenColIds.includes(colDef.colId);
    });
  }

  gantt.config.columns = newColumns;

  gantt.ext.zoom.setLevel(ganttState.zoomLevel);
}
