import PropTypes from 'prop-types';
import { forwardRef, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  createFormEntityData,
  entityDataUrl,
  PAGE_TYPE,
  updateFormEntityData,
} from '@icp/page-renderer-core';
import { useForkRef } from '@icp/hooks';
import FormRendererSelector from './FormRendererSelector';
// import { getDataId } from './utils';

const FormEntityRenderer = forwardRef(function FormEntityRenderer(props, ref) {
  const params = useParams();
  const formApi = useRef();
  const formRef = useForkRef(ref, formApi);

  const {
    context: contextProp,
    // Button dialog props 属性会传 null 来禁止从 params 读取这些属性
    formEntityToken = params.formEntityToken,
    formEntityLayoutToken = params.layoutToken,
    formEntityId = params.formEntityId,
    formEntityLayoutId = params.formEntityLayoutId,
    formEntityDataId = params.formEntityDataId,
    FormRendererProps,
  } = props;

  /* const formEntityDataId = getDataId(
    formEntityDataIdProp,
    FormRendererProps?.defaultData?.id,
    params.formEntityDataId,
  ); */

  // context must memo, every context change will trigger FormRender make new store
  const context = useMemo(() => {
    return {
      ...contextProp,
      formEntityDataId,
      pageType: formEntityDataId ? PAGE_TYPE.formEdit : PAGE_TYPE.formCreate,
    };
  }, [contextProp, formEntityDataId]);

  const { pbcToken } = context;
  const isCreate = !formEntityDataId;

  const handleSubmit = (newFormData) => {
    const { enableAutoLock } = formApi.current.getContext();
    if (isCreate) {
      return createFormEntityData({
        enableAutoLock,
        pbcToken,
        formEntityToken,
        formEntityLayoutToken,
        formEntityId,
        formEntityLayoutId,
        data: newFormData,
      });
    }

    return updateFormEntityData({
      enableAutoLock,
      pbcToken,
      formEntityToken,
      formEntityLayoutToken,
      formEntityLayoutId,
      formEntityDataId,
      data: newFormData,
    });
  };

  return (
    <FormRendererSelector
      // 当 data id 改变的时候重新渲染整个 form
      key={formEntityDataId}
      {...FormRendererProps}
      context={context}
      formEntityToken={formEntityToken}
      layoutToken={formEntityLayoutToken}
      layoutId={formEntityLayoutId}
      retrieveUrl={isCreate ? undefined : entityDataUrl.retrieve({ formEntityDataId })}
      onSubmit={handleSubmit}
      ref={formRef}
    />
  );
});

FormEntityRenderer.propTypes = {
  context: PropTypes.shape({}),
  formEntityToken: PropTypes.string,
  formEntityLayoutToken: PropTypes.string,
  formEntityId: PropTypes.number,
  formEntityLayoutId: PropTypes.number,
  formEntityDataId: PropTypes.number,
  FormRendererProps: PropTypes.shape({
    defaultData: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
};

export default FormEntityRenderer;
