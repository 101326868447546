import { get, set } from 'lodash-es';
import { parseJSON } from '@icp/utils';

const FIELD_API_TYPE_MAP = {
  ACL: 'ACL',
  Cascader: 'CASCADER',
  Checkbox: 'CHECKBOX',
  DatePicker: 'DATE',
  EditableTable: 'EDITABLE_GRID',
  FormDesigner: 'FORM_DESIGNER',
  Formula: 'FORMULA',
  Ocr: 'TEXT_BOX', // TODO 必须有单独数据字段类型，否则FIELD_API_TYPE_REVERSE_MAP会丢失layout字段类型映射
  Input: 'TEXT_BOX',
  NumberPicker: 'NUMBER',
  Radio: 'RADIO',
  Rate: 'RATE',
  RichText: 'RICH_TEXT',
  Select: 'SELECT',
  Switch: 'SWITCH',
  Textarea: 'TEXTAREA',
  TimePicker: 'TIME',
  TreeSelect: 'TREE_SELECT',
  Upload: 'UPLOAD',
  Password: 'PASSWORD',
};

export const FIELD_API_TYPE_REVERSE_MAP = Object.fromEntries(
  Object.entries(FIELD_API_TYPE_MAP).map(([k, v]) => [v, k]),
);

const hasReferenceComponents = ['Select', 'ACL', 'Cascader', 'TreeSelect', 'Input'];

const noConvert = (x) => x;

// array element: tuple(keyPath, keyPath, converter, reverseConverter, components)
const FIELDS_TO_LAYOUTS = [
  ['token', 'id', noConvert, noConvert],
  ['domain', 'domain', noConvert, noConvert],
  ['module', 'module', noConvert, noConvert],
  ['userField', 'userField', noConvert, noConvert],
  ['orgField', 'orgField', noConvert, noConvert],
  ['type', 'component', (x) => FIELD_API_TYPE_REVERSE_MAP[x], (x) => FIELD_API_TYPE_MAP[x]],
  ['name', 'title', noConvert, noConvert],
  ['nameI18nKey', 'title_i18n_key', noConvert, noConvert],
  ['nameI18nNs', 'title_i18n_ns', noConvert, noConvert],
  ['helpInformation', ['fieldTitleProps', 'tooltip'], noConvert, noConvert],
  [
    'fieldConfigJson',
    'fieldConfig',
    (x) => parseJSON(x),
    (x) => (typeof x === 'object' && x !== null ? JSON.stringify(x) : undefined),
  ],
  ['maxLength', ['validation', 'maxLength'], noConvert, noConvert],
  ['maxValue', ['validation', 'maxValue'], noConvert, noConvert],
  ['minValue', ['validation', 'minValue'], noConvert, noConvert],
  ['regex', ['validation', 'regex'], noConvert, noConvert],
  ['regexErrorMessage', ['validation', 'regexErrorMessage'], noConvert, noConvert],
  ['precision', ['componentProps', 'precision'], noConvert, noConvert],
  ['uniqueField', 'uniqueField', noConvert, noConvert],
  ['multiple', ['componentProps', 'multiple'], noConvert, noConvert],
  [
    'referencePbc',
    ['componentProps', 'dataSource', 'pbcToken'],
    noConvert,
    noConvert,
    hasReferenceComponents,
  ],
  [
    'referenceEntity',
    ['componentProps', 'dataSource', 'token'],
    noConvert,
    noConvert,
    hasReferenceComponents,
  ],
  [
    'referenceField',
    ['componentProps', 'mapping', 'value'],
    noConvert,
    noConvert,
    hasReferenceComponents,
  ],
  [
    'referenceDisplayField',
    ['componentProps', 'mapping', 'label'],
    noConvert,
    noConvert,
    hasReferenceComponents,
  ],
  [
    'referenceMappingFields',
    ['componentProps', 'mapping'],
    (x) => parseJSON(x),
    (x) => (typeof x === 'object' && x !== null ? JSON.stringify(x) : undefined),
    hasReferenceComponents,
  ],
  ['isAutoLock', 'enableAutoLock', noConvert, noConvert],
  [
    'autoLockJson',
    'autoLockCondition',
    (x) => parseJSON(x),
    (x) => (typeof x === 'object' && x !== null ? JSON.stringify(x) : undefined),
  ],
];

export const CROSS_LAYOUT_PROPERTIES_MAPPING = {
  FIELDS_TO_LAYOUTS,
  LAYOUTS_TO_FIELDS: FIELDS_TO_LAYOUTS.map(([p1, p2, p3, p4]) => [p2, p1, p4, p3]),
  KEY_PATHS: {
    OF_FIELDS: FIELDS_TO_LAYOUTS.map((tuple) => tuple[0]),
    OF_LAYOUTS: FIELDS_TO_LAYOUTS.map((tuple) => tuple[1]),
  },
};

function undefinedToNull(obj) {
  const newObj = { ...obj };

  for (const [k, v] of Object.entries(newObj)) {
    if (v === undefined) {
      newObj[k] = null;
    }
  }

  return newObj;
}

export function schemaFieldToDataField(schemaField, currentPbcToken) {
  const dataField = {};

  for (const [dataKey, schemaKeyPath, , converter, components] of FIELDS_TO_LAYOUTS) {
    if (!components || components.includes(schemaField.component)) {
      const value = converter(get(schemaField, schemaKeyPath));
      set(dataField, dataKey, value);
      if (
        dataKey === 'referencePbc' &&
        !value &&
        schemaField.componentProps?.dataSource?.token &&
        typeof currentPbcToken === 'string'
      ) {
        set(dataField, dataKey, currentPbcToken);
      }
    }
  }

  // 后端 api 对不存在值的字段会返回 null。undefined 转成 null 避免无意义的 dirty
  return undefinedToNull(dataField);
}
