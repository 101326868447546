import './FieldTree.css';
import PropTypes from 'prop-types';
import { Badge, Tree } from 'antd';
import { useLoadFieldTreeData } from '../FieldTreeSelect/utils';
import Loading from '../Loading';

export function FieldTree({
  projectToken,
  pbcToken,
  formEntityToken,
  multiple,
  value,
  onChange,
  ...other
}) {
  if (!value) {
    value = [];
  }

  const [data, loading] = useLoadFieldTreeData({
    initialValue: value,
    projectToken,
    pbcToken,
    formEntityToken,
  });

  if (projectToken == null || pbcToken == null || formEntityToken == null) {
    return null;
  }

  const handleChange = (newValue) => {
    if (!newValue) {
      onChange([]);
      return;
    }
    if (multiple) {
      onChange(newValue);
      return;
    }
    onChange([newValue]);
  };

  if (loading) {
    return <Loading delayed={false} />;
  }

  return (
    <Tree
      height={360}
      multiple={multiple}
      autoExpandParent={true}
      defaultExpandAll={false}
      checkable={multiple}
      checkStrictly={multiple}
      fieldNames={{
        title: 'label',
        key: 'value',
        children: 'children',
      }}
      treeData={data?.treeData || []}
      loadData={data?.loadData}
      loadedKeys={data?.loadedKeys}
      checkedKeys={value}
      selectedKeys={value}
      onCheck={(e) => handleChange(e.checked)}
      onSelect={handleChange}
      titleRender={(node) => {
        const countOfChildrenChecked = value.filter(
          (v) => v !== node.value && v.startsWith(node.value),
        ).length;

        return (
          <div className="icp-field-tree-node-title-wrapper">
            <span>{node.label}</span>
            {countOfChildrenChecked > 0 && (
              <div className="icp-field-tree-node-title-count">
                <Badge
                  size="small"
                  count={countOfChildrenChecked}
                  offset={[8, 8]}
                  styles={{
                    indicator: {
                      color: 'lightGray',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <span>✓</span>
                </Badge>
              </div>
            )}
          </div>
        );
      }}
      {...other}
    />
  );
}

FieldTree.propTypes = {
  projectToken: PropTypes.string,
  pbcToken: PropTypes.string,
  formEntityToken: PropTypes.string,
  multiple: PropTypes.bool,
  // 无论多选单选统一传入value数组
  value: PropTypes.arrayOf(PropTypes.string),
  // 无论多选单选统一返回value数组
  onChange: PropTypes.func,
};

export default FieldTree;
